/* eslint-disable */
import React from 'react';
import CreateTradePlatform from '../../presentation/pages/tradePlatform/createTradePlatform';
import TradePlatformPage from '../../presentation/pages/tradePlatform';
import TradePlatformCategoryList from '../../presentation/pages/tradePlatform/tradePlatformCategoryList';

const tradePlatformRoutes = [
  {
    path: '/trade-platform/:categoryId/:name/create',
    exact: true,
    view: () => <CreateTradePlatform />,
  },
  {
    path: '/trade-platform/:categoryId/:name/:tradePlatformId/edit',
    exact: true,
    view: () => <CreateTradePlatform />,
  },
  {
    path: '/trade-platform',
    exact: true,
    view: () => <TradePlatformPage />,
  },
  {
    path: '/trade-platform/:id/:name',
    exact: true,
    view: () => <TradePlatformCategoryList />,
  }
];

export default tradePlatformRoutes;