import {
  getRequest,
  putRequest,
} from '../api';
import { errorHandle } from '../api/errorHandle.js';

export async function requestGetPlatformPreview(config) {
  const { platformCode, mode, type } = config;
  const url = `/api/menu/preview?platformCode=${ platformCode }&mode=${ mode  }&type=${ type }`;
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestGetPlatformData(platformCode) {
  const url = `/api/menu/platform/${ platformCode }`;
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestUpdatePlatformMenu(id, items) {
  const url = '/api/menu/platformMenu';
  const result = {
    status: false,
    data: null
  };
  console.log(items);
  try {
    result.data = await putRequest(url, {
      platformId: id,
      items
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
