/* eslint-disable */
import React, { useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";


/* 
Note: this is a working example, but more can be done to improve it.

In particular, on drag, the table cells in the dragged row may collapse and shrink the overall row.

If you wish to preserve their size mid-drag, you can create a custom component that wraps
the material TableCell and saves the pre-drag dimensions (e.g. in a ref or in state).
The component can be passed an 'isDragging' prop (via snapshot.isDragging) and can conditionally
apply pre-drag width/height via styles.

Pre-drag dimensions can be obtained via the new-ish ResizeObserver API. If you are using class 
components, the getSnapshotBeforeUpdate() lifecycle method can work with getBoundingClientRect(), 
*/

function MaterialTable({ items }) {
  const tableData = [
    { uuid: "1", description: "Item #1", unitPrice: 11.11, quantity: 1 },
    { uuid: "2", description: "Item #2", unitPrice: 22.22, quantity: 2 },
    { uuid: "3", description: "Item #3", unitPrice: 33.33, quantity: 3 },
    { uuid: "4", description: "Item #4", unitPrice: 44.44, quantity: 4 },
    { uuid: "5", description: "Item #5", unitPrice: 55.55, quantity: 5 }
  ];
  // cache the items provided via props in state for purposes of this demo
  const [localItems, setLocalItems] = useState(tableData);

  // normally one would commit/save any order changes via an api call here...
  const handleDragEnd = (result, provided) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setLocalItems((prev) => {
      const temp = [...prev];
      const d = temp[result.destination.index];
      temp[result.destination.index] = temp[result.source.index];
      temp[result.source.index] = d;

      return temp;
    });
  };

  return (
      <div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided) => (
              <div
                style={ {background: 'lightblue', margin: 10, border: '1px solid #333'} }
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {localItems.map((item, index) => (
                  <Draggable
                    key={item.uuid}
                    draggableId={item.uuid}
                    index={index}
                  >
                    {(draggableProvided, snapshot) => {
                      return (
                        <main
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          /*style={{
                            ...draggableProvided.draggableProps.style,
                            border: '1px solid #333',
                            width: 'auto',
                            height: 'auto',
                            background: snapshot.isDragging
                              ? "rgba(245,245,245, 0.75)"
                              : "none"
                          }}*/
                        >
                          {/* note: `snapshot.isDragging` is useful to style or modify behaviour of dragged cells */}
                          <div align="left">
                            <div {...draggableProvided.dragHandleProps}>
                              Hello
                            </div>
                          </div>
                          <div>{item.description}</div>
                          <div align="right">{item.unitPrice}</div>
                          <div align="right">{item.quantity}</div>
                          <div align="right">
                            {(item.unitPrice * item.quantity).toFixed(2)}
                          </div>
                        </main>
                      );
                    }}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
    </div>
  );
};

export default MaterialTable;

