import { combineReducers } from 'redux';
import helloReducer from './hello';
import messageReducer from './message';
import breadcrumbReducer from './breadcrumb';

const RootReducers = {
  hello: helloReducer,
  message: messageReducer,
  breadcrumb: breadcrumbReducer
};

export default combineReducers(RootReducers);
