import React, { useState, useEffect } from 'react';
import cookies from 'js-cookie';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Layout, Form, Space, Upload, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CustomerButton from '../../layouts/CustomerButton';
import CustomerInput from '../../layouts/CustomerInput';
import CustomerSwitch from '../../layouts/CustomerSwitch';
// import CustomerRadio from '../../layouts/CustomerRadio';
import ProductPreviewModal from '../../components/product/ProductPreviewModal';
import MultipleEditor from '../../components/product/MultipleEditor';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import useRequestFinancialProduct from '../../../application/product/useRequestProduct';
import { 
  sendSuccessMessage, 
  sendErrorMessage } from '../../layouts/Message';
import { inputValidator } from '../../../application/validator';
// import CustomerSearchInput from '../../layouts/CustomerSearchInput';

import '../../../styles/components/CreateProduct.css';

function CreateProduct() {
  const [isEdit, setIsEdit] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  // const [isSearchLoading, setIsSearchLoading] = useState(false);
  // const [enterBtnText, setEnterBtnText] = useState('網址驗證');
  const [image, setImage] = useState([]);
  const [openImage, setOpenImage] = useState([]);
  const [enableOpenBlock, setEnableOpenBlock] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [commonQKeywords, setCommonQKeywords] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('qa');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddTabOpen, setIsAddTabOpen] = useState(false);
  const [newTabIndex, setNewTabIndex] = useState(0);
  const [isEditTab, setIsEditTab] = useState(false);
  const [editTabKey, setEditTabKey] = useState('');
  const [editTabName, setEditTabName] = useState('');
  const [deleteTabKey, setDeleteTabKey] = useState('');
  const [tabPaneList, setTabPaneList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);

  const { Content } = Layout;
  const [form] = Form.useForm();
  const { requiredValidator, symbolValidator, urlValidator } = inputValidator();
  const dispatch = useDispatch();
  const { productId, categoryName } = useParams();
  // const radioOptions = ['是', '否'];
  const {
    getFinancialProductDetail,
    // getFinancialProductCodeVerify,
    putFinancialProduct,
    // postFinancialProduct,
    getCommonQuestionByKeyword,
  } = useRequestFinancialProduct();

  const handlePreviewModalSwitch = () => {
    setPreviewModalOpen(isPreviewModalOpen ? false : true);
  };

  // const handleValidateEnglishName = async value => {
  //   const errMsg = form.getFieldError('product-url');
  //   if (errMsg.length > 0) {
  //     return sendErrorMessage('請輸入正確商品代碼。');
  //   }
  //   if (value && errMsg.length === 0) {
  //     setIsSearchLoading(true);
  //     setEnterBtnText(<SyncOutlined span />);
  //     const verifyRes = await getFinancialProductCodeVerify(value);
  //     if(!verifyRes) {
  //       form.setFields([{name: 'product-url', errors:['請輸入其他商品名稱。']}]);
  //       setEnterBtnText('驗證網址');
  //     } else {
  //       form.setFields([{name: 'product-url', errors: false}]);
  //       setEnterBtnText(<CheckOutlined />);
  //     }
  //     setTimeout(() => {
  //       setEnterBtnText('驗證網址');
  //     }, 5000);
  //     setIsSearchLoading(false);
  //   }
  // };

  const fetchQuestion = async keywords => {
    const res = await getCommonQuestionByKeyword(keywords);
    if (res) {
      const newArray = [];
      res.forEach(ele => {
        newArray.push({
          title: ele.title,
          categoryName: ele.category.categoryName,
          category2nd: ele.category2nd.categoryName,
          category3rd: ele.category3rd.categoryName,
        });
      });
      setQuestionList(newArray);
    }
  };

  const handleCommonQKeywords = value => {
    setCommonQKeywords(value);

    if (value.length === 0) {
      setQuestionList([]);
    } else {
      fetchQuestion(value.toString());
    }
  };



  const handleAddTabSwitch = () => {
    setIsEditTab(false);
    setIsAddTabOpen(isAddTabOpen ? false : true);
  };

  const handleEditTabSwitch = (tabKey, tabName) => {
    setIsEditTab(true);
    setEditTabKey(tabKey);
    setEditTabName(tabName);
    setIsAddTabOpen(isAddTabOpen ? false : true);
  };

  const handleTabEdit = newArray => {
    setTabPaneList(newArray);
    handleEditTabSwitch();
  };

  const handleTabChange = activeKey => {
    setActiveTabKey(activeKey);
  };

  const handleDeleteModalSwitch = deleteTabKey => {
    setIsDeleteModalOpen(isDeleteModalOpen ? false : true);
    setDeleteTabKey(deleteTabKey);
  };

  const handleUploadDrawerSwitch = () => {
    setIsUploadDrawerOpen(isUploadDrawerOpen ? false : true);
  };

  const handleDeleteFile = async fileName => {
    const attachmentListLength = attachmentList.length;
    for (let i = 0; i < attachmentList.length; i++) {
      if (attachmentList[i].filename === fileName) {
        attachmentList.splice(i, 1);
        break;
      }
    }

    const attachments = [];
    if (attachmentList.length > 0) {
      const fileArray = [...attachmentList];
      fileArray.forEach(ele => {
        attachments.push({ displayName: ele.displayName, filename: ele.filename });
      });
    }

    if(isEdit) {
      const params = { productId, attachments: attachments };
      const result = await putFinancialProduct(params);
      if (!result) {
        sendErrorMessage('刪除檔案失敗');
        return;
      } else {
        sendSuccessMessage('刪除檔案成功');
        if (attachmentListLength <= 1) {
          setAttachmentList([]);
        } else {
          setAttachmentList(attachmentList);
        }
        getExistData();
        return;
      }
    } else {
      setAttachmentList(attachmentList);
      sendSuccessMessage('刪除檔案成功');
    }
  };

  const onAddTab = tabName => {
    setIsEditTab(false);
    setNewTabIndex(newTabIndex + 1);
    const activeKey = `newTab${ newTabIndex }`;
    const newTab = { title: tabName, content: [], key: activeKey };
    setTabPaneList([...tabPaneList, newTab]);
    setActiveTabKey(activeKey);
    handleAddTabSwitch();
  };

  const onRemoveTab = (newPanes, newActiveKey) => {
    setTabPaneList(newPanes);
    setActiveTabKey(newActiveKey);
    setIsDeleteModalOpen(false);
  };

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setTabPaneList(prev => {
      const temp = prev;
      const currentTabIndex = temp.findIndex(
        ele => ele.key.toString() === activeTabKey
      );
      const tabEditorArray = temp[currentTabIndex].content;
      const destinationItem = tabEditorArray[result.destination.index];
      tabEditorArray[result.destination.index] =
        tabEditorArray[result.source.index];
      tabEditorArray[result.source.index] = destinationItem;

      return temp;
    });
  };

  const handleAppendNewEditorIntoList = newArray => {
    setTabPaneList(newArray);
  };

  const handleAppendNewToggleList = newArray => {
    setTabPaneList(newArray);
  };

  const onEditEditor = newArray => {
    setTabPaneList(newArray);
  };

  const removeEditItem = newArray => {
    setTabPaneList(newArray);
  };

  const onEditToggle = newArray => {
    setTabPaneList(newArray);
  };

  const handleFileUpload = fileData => {
    setAttachmentList(old => [
      ...old,
      { displayName: fileData.displayName, filename: fileData.filename },
    ]);
  };

  // const handleSave = async value => {
  //   const tabList = [];
  //   const tabArray = [...tabPaneList];
  //   tabArray.forEach(ele => {
  //     tabList.push({
  //       tabName: ele.title,
  //       content: JSON.stringify(ele.content),
  //     });
  //   });

  //   const attachments = [];
  //   if (attachmentList.length) {
  //     const fileArray = [...attachmentList];
  //     fileArray.forEach(ele => {
  //       attachments.push({ displayName: ele.name, filename: ele.name });
  //     });
  //   }

  //   let params = {
  //     ...value,
  //     categoryId: Number(categoryId),
  //     enableOpenBlock: value.enableOpenBlock === 0,
  //     imagePath: image[0].name,
  //     openImagePath: openImage.length === 0 ? '' : openImage[0].name,
  //     attachments: attachmentList,
  //     tabs: tabList,
  //   };
  //   if(value.keywords && value.keywords.length > 0) {
  //     params = {
  //       ...params,
  //       keywords: String(value.keywords)
  //     };
  //   } else {
  //     params = {
  //       ...params,
  //       keywords: 'undefined'
  //     };
  //   }
  //   if(value.commonQuestionKeywords && value.commonQuestionKeywords.length > 0) {
  //     params = {
  //       ...params,
  //       commonQuestionKeywords: String(value.commonQuestionKeywords)
  //     };
  //   } else {
  //     params = {
  //       ...params,
  //       commonQuestionKeywords: 'undefined'
  //     };
  //   }
  //   delete params['product-url'];
  //   let result;
  //   if (isEdit) {
  //     params = { ...params, productId };
  //     result = await putFinancialProduct(params);
  //     if (!result) {
  //       sendErrorMessage('編輯失敗');
  //       return;
  //     }
  //     sendSuccessMessage('編輯成功');
  //     window.location = `/cms/product/${ categoryId }/${ categoryName }`;
  //   } else {
  //     result = await postFinancialProduct(params);
  //     if (!result) {
  //       sendErrorMessage('新增失敗');
  //       return;
  //     }
  //     sendSuccessMessage('新增成功');
  //     window.location = `/cms/product/${ categoryId }/${ categoryName }`;
  //   }
  // };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上傳圖片</div>
    </div>
  );

  const uploadProps = {
    action: `${ process.env.REACT_APP_FILES_UPLOAD_URL }`,
    headers: {
      token: cookies.get('elktree-demo-key'),
    },
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes('edit')) {
      setIsEdit(true);
      getExistData();
    } else {
      setIsEdit(false);
    }
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '商品管理',
        },
        {
          path: '/product',
          pageName: '商品分類管理',
        },
        {
          path: '/product/create',
          pageName: isEdit ? '編輯商品' : '新增商品',
        },
      ])
    );
    // eslint-disable-next-line
  }, [isEdit]);

  const imageFileList = img => {
    return [{
      uid: img,
      name: img,
      status: 'done',
      // url: `${ process.env.REACT_APP_IMAGE_URL }/${ img }`,
      url: img,
    }];
  };

  const getExistData = async() => {
    const existData = await getFinancialProductDetail(productId);
    if (existData) {
      form.setFieldsValue({
        productCode: existData.productCode,
        productName: existData.productName,
        description: existData.description,
        imagePath: imageFileList(existData.imagePath),
        isActive: existData.isActive,
        enableOpenBlock: existData.enableOpenBlock === true ? 0 : 1,
        commonQuestionKeywords: existData.commonQuestionKeywords
      });
      if (existData.keywords) {
        if(existData.keywords === 'undefined' || existData.keywords?.length === 0){
          form.setFieldsValue({
            keywords: []
          });
        } else {
          form.setFieldsValue({
            keywords: existData.keywords
          });
        }
      }
      if (existData.commonQuestionKeywords) {
        if(existData.commonQuestionKeywords === 'undefined' || existData.commonQuestionKeywords.length === 0){
          form.setFieldsValue({
            commonQuestionKeywords: []
          });
        } else {
          form.setFieldsValue({
            commonQuestionKeywords: existData.commonQuestionKeywords
          });
        }
      }
      if (existData.enableOpenBlock) {
        form.setFieldsValue({
          openTitle: existData.openTitle,
          openDescription: existData.openDescription,
          openImagePath: [
            {
              uid: existData.openImagePath,
              name: existData.openImagePath,
              status: 'done',
              url: `${ process.env.REACT_APP_IMAGE_URL }/${ existData.openImagePath }`,
            },
          ],
          openButton1Name: existData.openButton1Name,
          openButton1Url: existData.openButton1Url,
          openButton2Name: existData.openButton2Name,
          openButton2Url: existData.openButton2Url,
        });
        setOpenImage(imageFileList(existData.imageOpenPath));
      }
      if (existData.tabs?.length) {
        // 還原成tabPaneList的格式
        const newArray = [];
        existData.tabs.forEach((ele, index) => {
          newArray.push({
            title: ele.tabName,
            content: JSON.parse(ele.content),
            key: index,
          });
        });
        setTabPaneList(newArray);
      }
      if (existData?.attachments?.length > 0) {
        const data = [];
        existData.attachments.forEach((e, idx) => {
          data.push({
            uid: idx.toString(),
            displayName: e.displayName,
            filename: e.filename,
            status: 'done',
            url: `${ process.env.REACT_APP_FILES_URL }/${ e.filename }`,
          });
        });
        setAttachmentList(data);
      }

      fetchQuestion(existData.commonQuestionKeywords);
      setImage(imageFileList(existData.imagePath));
      setEnableOpenBlock(existData.enableOpenBlock === true ? 0 : 1);
    }
  };

  // get image FileList by form
  const formImageFile = e => {
    if (e.file.status === 'done') {
      setImage(imageFileList(e.file.response.result.filename));
    } else {
      setImage([]);
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // get open image FileList by form
  const normOpenImageFile = e => {
    if(e.file.status === 'done') {
      setOpenImage(imageFileList(e.file.response.result.filename));
    } else {
      setOpenImage([]);
    }
    
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // active開戶設定
  // const handleEnableOpenBlock = (e) => {
  //   setEnableOpenBlock(e.target.value);
  // };

  // Prevent Enter key down after key in finish
  const preventKeyDown = e => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title add-question-title'>
            <h1>{isEdit ? '編輯商品' : '新增商品'}</h1>
            <Space>
              <CustomerButton onClick={handlePreviewModalSwitch}>
                預覽
              </CustomerButton>
              <CustomerButton
                color='blue'
                htmlType='submit'
                form='product-form'>
                儲存
              </CustomerButton>
            </Space>
          </div>

          <Form
            form={form}
            id='product-form'
            // onFinish={handleSave}
            onKeyDown={preventKeyDown}
            className='form-group add-question-form-group'
            layout='horizontal'
            wrapperCol={{ span: 15 }}
            labelCol={{ span: 4 }}
            initialValues={{
              categoryName: categoryName,
              isActive: false,
              enableOpenBlock: enableOpenBlock,
              productCode: '',
              openButton1Name: '',
              openButton1Url: '',
              openButton2Name: '',
              openButton2Url: '',
              openDescription: '',
              openImagePath: [],
              openTitle: '',
            }}>
            <Form.Item label='商品分類' name='categoryName'>
              <CustomerInput placeholder='' disabled />
            </Form.Item>

            {/* <Form.Item label='正式網址' name='product-url' required>
              <CustomerInput
                placeholder='https://'
                disabled
              />
              <Form.Item
                name='productCode'
                rules={[
                  ({ setFields }) => ({
                    validator(_, value) {
                      const regex = /^[A-z0-9_-]*$/;
                      if (value.length === 0) {
                        return setFields([
                          { name: 'product-url', errors: ['名稱不可為空白。'] },
                        ]);
                      } else if (!regex.test(value)) {
                        return setFields([
                          {
                            name: 'product-url',
                            errors: [
                              '名稱限半形英文、數字 、減號 (-)、下底線 (_)。',
                            ],
                          },
                        ]);
                      } else if (value.length < 2) {
                        return setFields([
                          {
                            name: 'product-url',
                            errors: ['長度最少2個字元。'],
                          },
                        ]);
                      } else {
                        setFields([{ name: 'product-url', errors: false }]);
                        // eslint-disable-next-line
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}>
                <CustomerSearchInput
                  maxLength={63}
                  style={{ minWidth:'280px' }}
                  placeholder={isEdit ? '' : '請輸入英文商品名稱'}
                  type='text'
                  onSearch={handleValidateEnglishName}
                  loading={isSearchLoading}
                  enterButton={enterBtnText}
                />
              </Form.Item>
            </Form.Item> */}

            <Form.Item
              label='商品名稱'
              name='productName'
              rules={[requiredValidator(), symbolValidator()]}>
              <CustomerInput placeholder='請輸入商品名稱' maxLength={12} />
            </Form.Item>

            <Form.Item
              label='簡短描述'
              name='description'
              rules={[requiredValidator()]}>
              <CustomerInput
                placeholder='請以50字內簡短描述'
                textArea
                rows={3}
                maxLength={50}
              />
            </Form.Item>

            <Form.Item
              name='imagePath'
              label='上傳商品圖片'
              getValueFromEvent={formImageFile}
              valuePropName='fileList'
              rules={[{ required: true, message: '請上傳圖片。' }]}>
              <Upload {...uploadProps} listType='picture-card' maxCount={1}>
                {image?.length > 0 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item label='狀態'>
              <Form.Item name='isActive' valuePropName='checked'>
                <CustomerSwitch />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => {
                  const isActive = form.getFieldValue('isActive');
                  return isActive ? <span>顯示</span> : <span>隱藏</span>;
                }}
              </Form.Item>
            </Form.Item>

            {/* <Form.Item label='是否開啟開戶設定' name='enableOpenBlock'>
              <CustomerRadio
                options={radioOptions}
                value={enableOpenBlock}
                onChange={handleEnableOpenBlock}
              />
            </Form.Item> */}
            {enableOpenBlock === 0 ? (
              <>
                <Form.Item
                  label='標題名稱'
                  name='openTitle'
                  rules={
                    enableOpenBlock === 0
                      ? [requiredValidator(), symbolValidator()]
                      : []
                  }>
                  <CustomerInput placeholder='請輸入標題名稱' maxLength={12} />
                </Form.Item>

                <Form.Item
                  label='內容描述'
                  name='openDescription'
                  rules={enableOpenBlock === 0 ? [requiredValidator()] : []}>
                  <CustomerInput
                    placeholder='請以50字內簡短描述'
                    textArea
                    rows={3}
                    maxLength={50}
                  />
                </Form.Item>

                <Form.Item
                  label='上傳圖片'
                  name='openImagePath'
                  getValueFromEvent={normOpenImageFile}
                  valuePropName='fileList'
                  rules={[
                    {
                      required: true,
                      message: '請上傳圖片。'
                    }
                  ]}>
                  <Upload {...uploadProps} listType='picture-card' maxCount={1}>
                    {openImage.length > 0 ? null : uploadButton}
                  </Upload>
                </Form.Item>

                <Form.Item
                  label='按鈕1名稱'
                  name='openButton1Name'
                  rules={enableOpenBlock === 0 ? [requiredValidator()] : []}>
                  <CustomerInput placeholder='請輸入按鈕名稱' maxLength={12} />
                </Form.Item>

                <Form.Item
                  label='按鈕1連結'
                  name='openButton1Url'
                  rules={enableOpenBlock === 0 ? [requiredValidator(), urlValidator()] : []}>
                  <CustomerInput placeholder='請輸入連結網址' />
                </Form.Item>

                <Form.Item
                  label='按鈕2名稱'
                  name='openButton2Name'
                  dependencies={['openButton2Url']}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const btn2Url = getFieldValue('openButton2Url');
                        if (btn2Url && !value) {
                          // eslint-disable-next-line
                          return Promise.reject('名稱不可為空。');
                        }
                        // eslint-disable-next-line
                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <CustomerInput placeholder='請輸入按鈕名稱' />
                </Form.Item>

                <Form.Item
                  label='按鈕2連結'
                  name='openButton2Url'
                  dependencies={['openButton2Name']}
                  rules={[
                    {
                      type: 'url',
                      message: '請輸入正確網址。',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const btn2name = getFieldValue('openButton2Name');
                        if (btn2name && !value) {
                          // eslint-disable-next-line
                          return Promise.reject('網址不可為空。');
                        }
                        // eslint-disable-next-line
                        return Promise.resolve();
                      },
                    }),
                  ]}>
                  <CustomerInput placeholder='請輸入連結網址' />
                </Form.Item>
              </>
            ) : (
              null
            )}

            <Form.Item label='商品關鍵字' name='keywords'>
              <Select
                placeholder='輸入選項後鍵入Enter，新增一個新的關鍵字'
                mode='tags'>
                {/* {productKeyword} */}
              </Select>
            </Form.Item>

            <Form.Item label='常見問題關鍵字' name='commonQuestionKeywords'>
              <Select
                placeholder='輸入選項後鍵入Enter，新增一個新的關鍵字'
                value={commonQKeywords}
                onChange={handleCommonQKeywords}
                mode='tags'>
                {commonQKeywords}
              </Select>
            </Form.Item>

            <Form.Item label='設定分頁'>
              <CustomerButton onClick={handleAddTabSwitch}>
                <PlusOutlined />
                新增分頁
              </CustomerButton>
            </Form.Item>
            <MultipleEditor
              tabPaneList={tabPaneList}
              activeTabKey={activeTabKey}
              handleTabChange={handleTabChange}
              handleAppendNewEditorIntoList={handleAppendNewEditorIntoList}
              handleAppendNewToggleList={handleAppendNewToggleList}
              handleRemoveEditItem={removeEditItem}
              handleOnEditEditor={onEditEditor}
              handleOnEditToggle={onEditToggle}
              questionList={questionList}
              attachmentList={attachmentList}
              handleFileUpload={handleFileUpload}
              handleDeleteFile={handleDeleteFile}
              isAddTabOpen={isAddTabOpen}
              isEditTab={isEditTab}
              editTabKey={editTabKey}
              editTabName={editTabName}
              handleAddTabSwitch={handleAddTabSwitch}
              isDeleteModalOpen={isDeleteModalOpen}
              onAddTab={onAddTab}
              handleEditTabSwitch={handleEditTabSwitch}
              handleDeleteModalSwitch={handleDeleteModalSwitch}
              handleDragEnd={handleDragEnd}
              handleTabEdit={handleTabEdit}
              deleteTabKey={deleteTabKey}
              onRemoveTab={onRemoveTab}
              isUploadDrawerOpen={isUploadDrawerOpen}
              handleUploadDrawerSwitch={handleUploadDrawerSwitch}
            />
          </Form>
        </div>
      </Content>
      <ProductPreviewModal
        isOpen={isPreviewModalOpen}
        handleClose={handlePreviewModalSwitch}
        fileList={attachmentList}
        tabPaneList={tabPaneList}
        questionList={questionList}
      />
    </Layout>
  );
}

export default CreateProduct;
