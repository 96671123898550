import React from 'react';
import moment from 'moment';
import { Modal } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';

import CustomerButton from '../../../layouts/CustomerButton';

import '../../../../styles/layouts/SubListModal.css';

const MenuFunctionSubListModal = ({ isOpen, handleClose, data }) => {
  if (!data) {
    return (<span></span>);
  }

  return (
    <Modal
      className='sub-table-modal'
      centered
      visible={isOpen}
      title='詳細列表'
      onCancel={handleClose}
      width={'auto'}
      destroyOnClose
      footer={[
        <CustomerButton key='submit' color='blue' onClick={handleClose}>
          確認
        </CustomerButton>,
      ]}>
      <table className='sub-table'>
        <tbody className='sub-table-tbody'>
          <tr>
            <td>功能名稱</td>
            <td>{data.title ? data.title : ''}</td>
          </tr>
          <tr>
            <td>標記</td>
            <td>{data.icon ? data.icon : ''}</td>
          </tr>
          <tr>
            <td>是否需要Trust</td>
            <td>{data.isTrust ? '是' : '否'}</td>
          </tr>
          <tr>
            <td>URL 網址</td>
            <td>{data.url ? data.url : '無'}</td>
          </tr>
          <tr>
            <td>是否開啟完整URL</td>
            <td>{data.isFullUrl ? '是' : '否'}</td>
          </tr>
          <tr>
            <td>Trust URL 網址</td>
            <td>{data.trustUrl === '' ? '' : data.trustUrl}</td>
          </tr>
          <tr style={{ height: 86 }}>
            <td>TrustBody 參數</td>
            <td>
              <pre>{ JSON.stringify(data.trustBody) }</pre>
              {/*trustBody?.map((t, idx) => {
              return (
                <div key={idx}>
                  <div>{`Key ${ idx + 1 }: ${ t.key } / value ${ idx + 1 }: ${
                    t?.value
                  }`}</div>
                </div>
              );
            })*/}
            </td>
          </tr>
          <tr>
            <td>功能開啟方式</td>
            <td>
              { data.isBlank ? '另開分頁' : data.isOpen ? '另開視窗' : '原頁開啟' }
            </td>
          </tr>
          <tr>
            <td>另開新視窗尺寸</td>
            <td>{ `${ data.openWidth ? data.openWidth : 0 } x ${ data.openHeight ? data.openHeight : 0 } px` }</td>
          </tr>
          <tr>
            <td>是否需要登入</td>
            <td>{ data.needLogin ? '是' : '否' }</td>
          </tr>
          <tr>
            <td>登入後顯示</td>
            <td>{ data.needLoginDisplay ? '是' : '否' }</td>
          </tr>
          <tr>
            <td>是否開啟權限管控</td>
            <td>{ data.isTrust ? '是' : '否' }</td>
          </tr>
          <tr>
            <td>綁定用戶權限</td>
            <td className='user-permission'>
              {
                data.permissions.groups ? 
                  data.permissions.groups.map((group, idx) => (
                    <span key={ idx } style={{ marginRight: 5, border: '1px solid #333', padding: 2 }}>
                      { group.groupName }
                    </span>
                  )) : null
              }
            </td>
          </tr>
          <tr>
            <td>自訂用戶權限</td>
            <td>
              {
                data.permissions.users ?
                  data.permissions.users.map((member, index) => (
                    <span key={ index } style={{ marginRight: 5, border: '1px solid #333', padding: 2 }}>
                      { member }
                    </span>
                  )) : null
              }
            </td>
          </tr>
          <tr>
            <td>狀態</td>
            <td>{data.isActive ? (<span style={{ color: '#22a16f' }}>顯示</span>) : (<span style={{ color: '#f45a4c' }}>隱藏</span>)}</td>
          </tr>
          <tr>
            <td>註記</td>
            <td>{data.remark ? data.remark : '無' }</td>
          </tr>
          <tr>
            <td>建立時間</td>
            <td>{data.createdAt ? moment(new Date(data.createdAt)).format('YYYY/MM/DD HH:mm:ss') : '' }</td>
          </tr>
          <tr>
            <td>更新時間</td>
            <td>{data.updatedAt ? moment(new Date(data.updatedAt)).format('YYYY/MM/DD HH:mm:ss') : '' }</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default MenuFunctionSubListModal;
