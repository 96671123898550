import React from 'react';
import { Modal } from 'antd';
import CustomerButton from './CustomerButton';

import '../../styles/layouts/CancelModal.css';
import { useSelector } from 'react-redux';

const LogoutModal = () => {
 
  const isLogoutModalOpen = useSelector(state => state.message.isLogoutModalOpen);
 
  const confirmLogout = () => {
    window.location = '/users/signin';
  };

  return (
    <Modal
      className='cancel-modal'
      centered
      visible={ isLogoutModalOpen }
      title='登出'
      width={320}
      destroyOnClose
      onOk={ confirmLogout }
      footer={[
        <CustomerButton key='submit' color='blue' onClick={ confirmLogout }>
          登出
        </CustomerButton>,
      ]}>
      <h4 className='cancel-modal-content'>登入逾期，請重新登入。</h4>
    </Modal>
  );
};

export default LogoutModal;
