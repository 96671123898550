import { getRequest, postRequest, putRequest, deleteRequest } from '../api';
import { errorHandle } from '../api/errorHandle.js';

/**
 * [requestGetAdSlots]
 * @return {object} [result]
 */
export async function requestGetAdSlots() {
  // const url = '/api/notification/adSlots';
  const url = '/JSON/advertisement/adSlotData.json';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = result.data.status;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetAdSlots]
 * @return {object} [result]
 */
 export async function requestVerifyAdSlot(slotCode) {
  const url = `/api/notification/verifyAdSlot/${ slotCode }`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPostAdSlot]
 * @return {object} [result]
 */
 export async function requestPostAdSlot(params) {
  const url = '/api/notification/adSlot';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetAdSlot]
 * @return {object} [result]
 */
 export async function requestGetAdSlot(slotId) {
  // const url = `/api/notification/adSlot/${ slotId }`;
  const url = `/JSON/advertisement/adSlotData.json`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPutAdSlot]
 * @return {object} [result]
 */
 export async function requestPutAdSlot(params) {
  const url = '/api/notification/adSlot';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetAdSlotDetail]
 * @return {object} [result]
 */
 export async function requestDeleteAdSlot(id) {
  const params = {
    slotId: id,
  };
  const url = '/api/notification/adSlot';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}