/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import cookies from 'js-cookie';
import {
  Layout,
  Form,
  Upload,
  Space,
  Menu,
  Dropdown,
  Button,
  Select,
} from 'antd';
import {
  InfoCircleFilled,
  MenuOutlined,
  CloseOutlined,
  CopyOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import CustomerInput from '../../layouts/CustomerInput';
import CustomerSelectCategory from '../../layouts/CustomerSelectCategory';
import CustomerRadio from '../../layouts/CustomerRadio';
// import EditorSection from '../../layouts/Editor';
import EditorBlock from '../../layouts/EditorBlock';
import EditorToggleBlock from '../../layouts/EditorToggleBlock';
import ListPreviewModal from '../../components/question/list/ListPreviewModal';

import '../../../styles/pages/SitePage.css';
import CustomerTimePicker from '../../layouts/CustomerTimePicker';
import CustomerButton from '../../layouts/CustomerButton';

import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useRequestAllCategory } from '../../../application/questions/useRequestAllCategory';
import { useQuestionFilterHandle } from '../../../application/questions/useQuestionFilterHandle';
import { useQuestionDetails } from '../../../application/questions/useRequestQuestion';
import { inputValidator } from '../../../application/validator';

const radioOptions = ['是', '否'];
const radioOptionsPublicMethod = ['立即發布', '排程發布'];

function AddQuestionList() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { Content } = Layout;
  const dispatch = useDispatch();
  const { requiredValidator, symbolValidator } = inputValidator();

  const { handleCategoryFilterTitle } = useQuestionFilterHandle();
  const {
    getQuestionCategories,
    getFirstAndSecondQuestionCategories,
    getSecondAndThirdQuestionCategories,
  } = useRequestAllCategory();
  const { getCommonQuestionDetail, createQuestion, updateQuestion } =
    useQuestionDetails();

  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [firstCategory, setFirstCategory] = useState();
  const [secondCategory, setSecondCategory] = useState();
  const [thirdCategory, setThirdCategory] = useState();
  const [firstCategoryList, setFirstCategoryList] = useState([]);
  const [secondCategoryList, setSecondCategoryList] = useState([]);
  const [thirdCategoryList, setThirdCategoryList] = useState([]);
  const [isPublish, setIsPublish] = useState();
  const [status, setStatus] = useState('');

  const [keywords, setKeywords] = useState([]);
  const [featured, setFeatured] = useState(1);
  const [onTop, setOnTop] = useState(1);
  const [postMethod, setPostMethod] = useState(0);
  const [scheduleDate, setScheduleDate] = useState(moment(new Date()));
  const [checkFileIsAvailable, setCheckFileIsAvailable] = useState(false);
  const [fileList, setFileList] = useState([]);
  // const [existFileList, setExistFileList] = useState([]);
  const [toggleList, setToggleList] = useState([]);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);

  const [isExitCreatePage, setIsExitCreatePage] = useState(false);

  const props = {
    action: `${ process.env.REACT_APP_FILES_UPLOAD_URL }`,
    headers: {
      token: cookies.get('elktree-demo-key'),
    },
    showUploadList: {
      showDownloadIcon: true,
      showRemoveIcon: true,
      downloadIcon: <CopyOutlined />,
    },
    beforeUpload: file => {
      const isPdfZip =
      file.type === 'application/zip' || file.type === 'application/pdf';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isPdfZip) {
        sendErrorMessage('檔案格式錯誤。');
        setCheckFileIsAvailable(false);
        return false || Upload.LIST_IGNORE;
      }
      if (!isLt2M) {
        sendErrorMessage('檔案過大');
        setCheckFileIsAvailable(false);
        return false || Upload.LIST_IGNORE;
      } else {
        setCheckFileIsAvailable(true);
        return true;
      }
    },
    onChange: info => {
      console.log('info:', info.fileList);
    },
    onDownload(file) {
      navigator.clipboard.writeText(file.url);
      sendSuccessMessage('複製成功');
    },
    onChange(info) {
      setFileList(info.fileList.slice());
      if (info.file.status === 'uploading') {
        sendSuccessMessage('上傳中');
      }
      if (info.file.status === 'done') {
        const arr = [];
        info.fileList.map(f => {
          if (!f.response) {
            arr.push({
              uid: f.url.split('/')[4],
              name: f.name,
              status: 'done',
              url: f.url,
            });
          } else {
            arr.push({
              uid: f.response.result.filename,
              name: f.name,
              status: 'done',
              url: `${process.env.REACT_APP_FILES_URL}/${f.response.result.filename}`,
            });
          }
        });
        setFileList(arr);
      }
      if (info.file.status === 'error') {
        console.log('error');
      }
    },
    onRemove: async(file) => {
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].uid === file.uid) {
          console.log(fileList[i]);
          fileList.splice(i, 1);
          break;
        }
      }
      let result;
      if (isEdit) {
        const params = { questionId: id, fileList };
        result = await updateQuestion(params);
        if (!result) {
          sendErrorMessage('刪除檔案失敗');
          return false;
        } else {
          sendSuccessMessage('刪除檔案成功');
          setFileList(fileList);
          getExistData();
          return true;
        }
      } else {
        sendSuccessMessage('刪除檔案成功');
        setFileList(fileList);
        return true;
      }
    },
    fileList: fileList,
  };

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '常見問題管理',
        },
        {
          path: '/question/list',
          pageName: '問題列表',
        },
        {
          path: '/question/list/add',
          pageName: '新增問題',
        },
      ])
    );

    if (id) {
      setIsEdit(true);
      getExistData();
    }
    // eslint-disable-next-line
  }, [dispatch, id]);

  useEffect(() => {
    getFirstCategory();

    // eslint-disable-next-line
  }, []);

  const getExistData = async () => {
    const existData = await getCommonQuestionDetail(id);
    console.log(existData);
    form.setFieldsValue({
      uuid: existData.uuid,
      title: existData.title,
      category: existData.category.categoryName,
      category2nd: existData.category2nd.categoryName,
      category3rd: existData.category3rd.categoryName,
      keywords: existData.keywords.length === 0 ? [] : existData.keywords,
      isFeatured: existData.isFeatured === true ? 0 : 1,
      isTop: existData.isTop === true ? 0 : 1,
      isPublish: existData.isPublish === true ? 0 : 1,
    });
    // console.log(existData);
    if (existData.title) {
      setTitle(existData.title);
    }
    if (existData?.category?.id) {
      setFirstCategory(existData.category.id);
    }
    if (existData?.category2nd?.id) {
      setSecondCategory(existData.category2nd.id);
    }
    if (existData?.category3rd?.id) {
      setThirdCategory(existData.category3rd.id);
    }
    if (existData.keywords) {
      setKeywords(existData.keywords);
    }
    if (existData.isFeatured) {
      setFeatured(0);
    }
    if (existData.isTop) {
      setOnTop(0);
    }
    if (
      existData.publishDate &&
      new Date(existData.publishDate).getTime() > new Date().getTime()
    ) {
      setPostMethod(1);
      setScheduleDate(moment(new Date(existData.publishDate), 'YYYY-MM-DD'));
    }

    if (existData.isPublish) {
      setIsPublish(existData.isPublish);
    }

    if (existData.content) {
      // const listData = JSON.parse(existData.content);
      const listData = existData.content;
      setToggleList(listData);
    }

    if(existData.status) {
      setStatus(existData.status);
    }

    if (existData?.attachments?.length > 0) {
      const data = [];
      existData.attachments.map((e, idx) => {
        data.push({
          // uid: idx.toString(),
          uid: e.filename,
          name: e.displayName,
          status: 'done',
          url: `${process.env.REACT_APP_FILES_URL}/${e.filename}`,
        });
      });
      setFileList(data);
    }
    getSecondCategory(existData.category.id);
    getThirdCategory(existData.category2nd.id);
  };


  const getFirstCategory = async () => {
    const list = await getQuestionCategories();
    const newArray = handleCategoryFilterTitle(list);
    setFirstCategoryList(newArray);
  };

  const getSecondCategory = async (id) => {
    const list = await getFirstAndSecondQuestionCategories(id);
    const newArray = handleCategoryFilterTitle(list);
    setSecondCategoryList(newArray);
  };

  const getThirdCategory = async (id) => {
    const list = await getSecondAndThirdQuestionCategories(id);
    const newArray = handleCategoryFilterTitle(list);
    setThirdCategoryList(newArray);
  };

  const handleTitleChange = e => {
    setTitle(e.target.value);
  };

  const handleFirstCategoryChange = async value => {
    setFirstCategory(value);
    const list = await getFirstAndSecondQuestionCategories(value);
    const newArray = handleCategoryFilterTitle(list);
    setSecondCategoryList(newArray);
  };

  // console.log(firstCategory);

  const handleSecondCategoryChange = async value => {
    setSecondCategory(value);
    const list = await getSecondAndThirdQuestionCategories(value);
    const newArray = handleCategoryFilterTitle(list);
    setThirdCategoryList(newArray);
  };

  const handleThirdCategoryChange = value => {
    setThirdCategory(value);
  };

  const handleKeywordsChange = value => {
    setKeywords(value);
  };

  const handleFeaturedChange = e => {
    setFeatured(e.target.value);
  };

  const handleOnTopChange = e => {
    setOnTop(e.target.value);
  };

  const handlePostMethodChange = e => {
    setPostMethod(e.target.value);
  };

  const onChangeScheduleDate = date => {
    setScheduleDate(date);
  };

  const disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  };

  // const handleFileUpload = fileList => {
  //   if (checkFileIsAvailable) {
  //     setFileList(fileList.fileList);
  //   }
  // };

  const handlePreviewModalOpen = () => {
    setPreviewModalOpen(true);
  };

  const handlePreviewModalClose = () => {
    setPreviewModalOpen(false);
  };

  const handleSave = async type => {
    const errMsg = form.getFieldsError(['title', 'category', 'category2nd']);
    if (
      errMsg[0].errors.length === 0 &&
      errMsg[1].errors.length === 0 &&
      errMsg[2].errors.length === 0
    ) {
      let saveData = {
        questionId: isEdit ? Number(id) : '',
        type,
        title,
        content: JSON.stringify(toggleList),
        categoryId: firstCategory,
        category2ndId: secondCategory,
        category3rdId: thirdCategory,
        keywords: keywords,
        isFeatured: featured === 1 ? false : true,
        isTop: featured === 1 || onTop === 1  ? false : true,
        postMethod: postMethod === 0 ? 'now' : 'schedule',
        publishDate: moment(new Date(scheduleDate)).format('YYYYMMDDHHmm'),
        fileList: fileList,
      };
      if(saveData.isFeatured === false) {
        saveData.isTop === false;
      }
      // console.log('saveData', saveData);
      let result;
      if (isEdit) {
        // 編輯>儲存 call update
        saveData = { ...saveData, isPublish: false  }
        result = await updateQuestion(saveData);
        console.log('is edit - saveData', saveData);
      } else {
        // 新增>儲存 call create
        result = await createQuestion(saveData);
      }

      if (!result) {
        sendErrorMessage('新增失敗');
        return;
      }
      sendSuccessMessage('新增成功');
      setIsExitCreatePage(true);
    } else {
      return;
    }
  };

  if (isExitCreatePage) {
    return <Redirect to={'/question/list'} push={true} />;
  }

  const onEditToggle = (index, toggleData) => {
    const data = [...toggleList];
    data[index] = toggleData;
    setToggleList(data);
  };

  const onEditEditor = (index, html) => {
    const data = [...toggleList];
    data[index] = {
      type: 'editor',
      content: {
        content: html,
      },
    };
    setToggleList(data);
  };

  const appendNewToggleList = () => {
    const data = [...toggleList];
    data.push({
      type: 'toggle',
      content: {
        title: '',
        content: '',
      },
    });
    setToggleList(data);
  };

  const appendNewEditorIntoList = () => {
    const data = [...toggleList];
    data.push({
      type: 'editor',
      content: {
        content: '',
      },
    });
    setToggleList(data);
  };

  const testData = () => {
    const errMsg = form.getFieldsError(['title', 'category', 'category2nd']);
    console.log(errMsg);
  };
  // get FileList by form
  const normOpenFile = e => {
    console.log('Upload event:', e);
    // console.log(checkFileIsAvailable);
    if (e.file.status === 'done' && checkFileIsAvailable) {
      setFileList(e.fileList);
    }

    if (e.file.status === 'removed') {
      handleDeleteFile(e.file);
    }

    if (Array.isArray(e)) {
      return e;
    }
    return e && fileList;
  };

  const removeEditItem = index => {
    const originList = [...toggleList];
    originList.splice(index, 1);
    setToggleList(originList);
  };

  const handleDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setToggleList(prev => {
      const temp = [...prev];
      const d = temp[result.destination.index];
      temp[result.destination.index] = temp[result.source.index];
      temp[result.source.index] = d;

      return temp;
    });
  };

  const menu = (
    <Menu className='editor-menu'>
      <Menu.Item key={1}>
        <div onClick={appendNewEditorIntoList}>圖文編輯器</div>
      </Menu.Item>
      <Menu.Item key={2}>
        <div onClick={appendNewToggleList}>開合列表</div>
      </Menu.Item>
    </Menu>
  );

  const previewData = {
    title,
    firstCategory: firstCategoryList.filter(
      e => e.id === Number(firstCategory)
    )[0]?.text,
    secondCategory: secondCategoryList.filter(
      e => e.id === Number(secondCategory)
    )[0]?.text,
    thirdCategory: thirdCategoryList.filter(
      e => e.id === Number(thirdCategory)
    )[0]?.text,
    toggleList,
    fileList,
    // html,
  };

  // console.log(postMethod);

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title add-question-title'>
            <h1 onClick={testData}>{isEdit ? '編輯問題' : '新增問題'}</h1>
            <Space>
              <CustomerButton onClick={handlePreviewModalOpen}>
                預覽
              </CustomerButton>
              <CustomerButton
                form='question-form'
                htmlType='submit'
                color='blue'
                onClick={() => {
                  handleSave('save');
                }}>
                儲存
              </CustomerButton>
              {postMethod === 0 ? (
                <CustomerButton
                  form='question-form'
                  htmlType='submit'
                  color='red'
                  onClick={() => {
                    handleSave('publish');
                  }}>
                  立即發布
                </CustomerButton>
              ) : (
                <CustomerButton
                  form='question-form'
                  htmlType='submit'
                  color='red'
                  onClick={() => {
                    handleSave('publish');
                  }}>
                  排程發布
                </CustomerButton>
              )}
            </Space>
          </div>

          <Form
            id='question-form'
            form={form}
            // className='form-group add-question-form-group'
            // onFinish={handleSave}
            layout='horizontal'
            wrapperCol={{ span: 10 }}
            labelCol={{ span: 3 }}>
            {isEdit ? (
              <Form.Item name='uuid' label='UUID'>
                <CustomerInput disabled />
              </Form.Item>
            ) : null}

            <Form.Item
              name='title'
              label='標題'
              rules={[requiredValidator('標題')]}>
              <CustomerInput
                placeholder='請輸入問題標題'
                value={title}
                onChange={handleTitleChange}
              />
            </Form.Item>

            <Form.Item
              name='category'
              label='第一層分類'
              rules={[requiredValidator('第一層分類')]}>
              <CustomerSelectCategory
                placeholder='請選擇第一層分類'
                options={firstCategoryList}
                onChange={handleFirstCategoryChange}
                selectedValue={firstCategory}
              />
            </Form.Item>

            <Form.Item
              name='category2nd'
              label='第二層分類'
              rules={[requiredValidator('第二層分類')]}>
              <CustomerSelectCategory
                placeholder='請選擇第二層分類'
                options={secondCategoryList}
                onChange={handleSecondCategoryChange}
                selectedValue={secondCategory}
              />
            </Form.Item>

            <Form.Item
              name='category3rd'
              label='第三層分類'
              rules={[requiredValidator('第三層分類')]}>
              <CustomerSelectCategory
                placeholder='請選擇第三層分類'
                options={thirdCategoryList}
                onChange={handleThirdCategoryChange}
                selectedValue={thirdCategory}
              />
            </Form.Item>

            <Form.Item name='keywords' label='關鍵字'>
              <Select
                placeholder='輸入選項後鍵入Enter，新增一個新的關鍵字'
                value={keywords}
                onChange={handleKeywordsChange}
                mode='tags'>
                {keywords}
              </Select>
            </Form.Item>

            <Form.Item name='isFeatured' label='設為精選'>
              <CustomerRadio
                options={radioOptions}
                onChange={handleFeaturedChange}
                value={featured}
                defaultValue={featured}
              />
            </Form.Item>

            {featured === 0 ? (
              <Form.Item name='isTop' label='設為置頂'>
                <CustomerRadio
                  options={radioOptions}
                  onChange={handleOnTopChange}
                  value={onTop}
                  defaultValue={onTop}
                />
              </Form.Item>
            ) : null}

            <Form.Item
              name='isPublish'
              label='發布設定'
              className='add-question-time-group'>
              <CustomerRadio
                options={isPublish && status !== 'scheduling' ? ['已發佈'] : radioOptionsPublicMethod}
                onChange={handlePostMethodChange}
                value={postMethod}
              />
              {isPublish && status !== 'scheduling' ? null : (
                <CustomerTimePicker
                  placeholder='設定發布時間'
                  format='YYYY-MM-DD HH:mm'
                  showTime
                  disabledDate={disabledDate}
                  onChange={onChangeScheduleDate}
                  value={scheduleDate}
                  disabled={postMethod === 0 ? true : false}
                />
              )}
            </Form.Item>

            <Form.Item
              label='上傳附件'
              className='add-question-upload'
              valuePropName='fileList'
              getValueFromEvent={normOpenFile}>
              <Upload
                {...props}
                // fileList={fileList}
                // onChange={handleUpload}
                >
                <div className='add-question-btn-group'>
                  <div>
                    <CustomerButton color='blue'>
                      <UploadOutlined />
                      選擇檔案
                    </CustomerButton>
                  </div>
                  <span>
                    <InfoCircleFilled style={{ marginRight: 4 }} />
                    支援上傳的檔案格式：pdf、zip，檔案大小限制：2MB以內。
                  </span>
                </div>
              </Upload>
            </Form.Item>

            <section className='add-question-editor'>
              <div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId='droppable' direction='vertical'>
                    {droppableProvided => (
                      <main
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}>
                        {toggleList.map((e, idx) => (
                          <Draggable
                            key={idx}
                            draggableId={`item-${idx}`}
                            index={idx}>
                            {draggableProvided => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}>
                                <div className='editor-drag-nav'>
                                  <Button
                                    type='text'
                                    onClick={() => {
                                      removeEditItem(idx);
                                    }}>
                                    <CloseOutlined />
                                  </Button>
                                  <div {...draggableProvided.dragHandleProps}>
                                    <MenuOutlined />
                                  </div>
                                </div>
                                {e.type === 'toggle' ? (
                                  <EditorToggleBlock
                                    value={e.content}
                                    onEditToggle={data => {
                                      onEditToggle(idx, data);
                                    }}
                                  />
                                ) : (
                                  <EditorBlock
                                    value={e.content?.content}
                                    onEdit={html => {
                                      onEditEditor(idx, html);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </main>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <Dropdown
                className='add-question-editor-drop'
                overlay={menu}
                placement='bottomCenter'
                trigger={['click']}>
                <div className='editor-new-btn'>＋ 新增編輯選單</div>
              </Dropdown>
            </section>
          </Form>
          <ListPreviewModal
            isOpen={isPreviewModalOpen}
            handleClose={handlePreviewModalClose}
            data={previewData}
          />
        </div>
      </Content>
    </Layout>
  );
}

export default AddQuestionList;
