import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { StarOutlined, StarFilled } from '@ant-design/icons';
// import { Link } from 'react-router-dom';
import { Table, Dropdown, Menu, Button } from 'antd';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
// import EmptyDisplay from '../../../layouts/EmptyDisplay';
import '../../../../styles/components/DataTable.css';
import Icon from '../../../../assets/images/datatable/basic-external-link.svg';
import ListSubModal from './ListSubModal';

const ListQuestionDataTable = ({
  tableData,
  // handleEditClick,
  handleDeleteClick,
  firstFilter,
  secondFilter,
  thirdFilter,
  handleFeatureToggle,
  handleGetDetail,
  questionDetail,
  handleSelectFirstFilter,
  handleSelectSecondFilter,
  handleCancelPublished,
  currentKeys,
}) => {
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [isListSubModalOpen, setListSubModalOpen] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [questionId, setQuestionId] = useState(0);
  const refSearchInput = useRef();

  // const handleResetSearch = () => {
  //   window.location.reload();
  // };

  const handleCancelModalOpen = () => {
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
    setSelectDeleteId('');
  };

  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const handleSubModalOpen = id => {
    setListSubModalOpen(true);
    handleGetDetail(id);
  };

  const handleSubModalClose = () => {
    setListSubModalOpen(false);
  };

  const setActionId = id => {
    setQuestionId(id);
    setSelectDeleteId(id);
  };

  const clickFeature = (currentStatus, id) => {
    const params = {
      questionId: id,
      isFeatured: !currentStatus,
      isTop: false
    };
    handleFeatureToggle(params);
  };

  const cancelPublished = () => {
    const cancelParams = {
      questionId: questionId,
      isPublish: false,
    };
    handleCancelPublished(cancelParams);
  };

  const menu = () => {
    return (
      <Menu>
        <Menu.Item key='edit'>
          <Link to={`/question/list/edit/${ questionId }`}>
            <span>編輯</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='delete'>
          <span onClick={handleCancelModalOpen}>刪除</span>
        </Menu.Item>
      </Menu>
    );
  };

  const publishMenu = () => {
    return (
      <Menu>
        <Menu.Item key='edit'>
          <Link to={`/question/list/edit/${ questionId }`}>
            <span>編輯</span>
          </Link>
        </Menu.Item>
        <Menu.Item key='cancel'>
          <span onClick={cancelPublished}>取消發布</span>
        </Menu.Item>
        <Menu.Item key='delete'>
          <span onClick={handleCancelModalOpen}>刪除</span>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      key: 'action',
      width: '3%',
      ellipsis: 'true',
      render(text, record) {
        return (
          <div
            className='dataTable-expand-button'
            onClick={() => handleSubModalOpen(record.id)}>
            {/* <img
              src='/images/datatable/basic-external-link.png'
              srcSet='/images/datatable/basic-external-link@2x.png 2x,
              /images/datatable/basic-external-link@3x.png 3x'
            /> */}
            <img src={Icon} style={{ width: 20, height: 20 }} alt="展開"/>
            {/* <PlusSquareOutlined /> */}
          </div>
        );
      },
    },
    {
      title: '精選',
      dataIndex: 'isFeatured',
      key: 'isFeatured',
      width: '6%',
      ellipsis: 'true',
      align: 'center',
      render(text, record) {
        return (
          <div
            className='table-feature-icon'
            onClick={() => {
              clickFeature(text, record.id);
            }}>
            {text ? <StarFilled /> : <StarOutlined />}
          </div>
        );
      },
      sorter: (a, b) => a.isFeatured - b.isFeatured,
    },
    {
      title: '標題',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'title',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
    },
    {
      title: '第一層分類',
      dataIndex: 'category',
      key: 'category',
      width: '10%',
      ellipsis: 'true',
      filters: firstFilter,
      onFilter: (value, record) => {
        handleSelectFirstFilter(value);
        return record.category === value;
      },
    },
    {
      title: '第二層分類',
      dataIndex: 'category2nd',
      key: 'category2nd.id',
      width: '10%',
      ellipsis: 'true',
      filters: secondFilter,
      onFilter: (value, record) => {
        handleSelectSecondFilter(value);
        return record.category2nd === value;
      },
    },
    {
      title: '第三層分類',
      dataIndex: 'category3rd',
      key: 'category3rd.id',
      width: '10%',
      ellipsis: 'true',
      filters: thirdFilter,
      onFilter: (value, record) => {
        return record.category3rd === value;
      },
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      className: 'question-table-updateAt',
      key: 'updatedAt',
      width: '10%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      defaultSortOrder: 'descend',
    },
    {
      title: '瀏覽次數',
      dataIndex: 'views',
      key: 'views',
      width: '10%',
      ellipsis: 'true',
      sorter: (a, b) => a.views - b.views,
    },
    {
      title: '狀態',
      dataIndex: 'status',
      key: 'status',
      width: '6%',
      ellipsis: 'true',
      render(text) {
        switch (text) {
          case 'published':
            return <span style={{ color: '#22a16f' }}>已發布</span>;
          case 'scheduling':
            return <span style={{ color: '#f45a4c' }}>排程中</span>;
          case 'unpublished':
            return <span style={{ color: '#f45a4c' }}>未發布</span>;
          case 'draft':
            return <span style={{ color: '#254a91' }}>草稿</span>;
          default:
            return;
        }
      },
    },
  ];

  // const locale = {
  //   emptyText: <EmptyDisplay handleReload={ handleResetSearch } isNeedReloadBtn={ tableData.length > 0 } />
  // };


  const renderTableCol = () => {
    const actCol = {
      title: '動作',
      key: 'id',
      width: '10%',
      ellipsis: 'true',
      render(text, record) {
        return (
          <Dropdown
            overlay={record.status === 'published' ? publishMenu : menu}
            placement='bottomCenter'>
            <Button
              className='customer-button customer-button-blue'
              onMouseOver={() => {
                setActionId(record.id);
              }}>
              動作
            </Button>
          </Dropdown>
        );
      },
    };

    const createDate =  {
      title: '建立時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '10%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      defaultSortOrder: 'descend',
    };

    const publishDate =  {
      title: '發布時間',
      dataIndex: 'publishDate',
      key: 'publishDate',
      width: '10%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
      sorter: (a, b) => new Date(a.publishDate) - new Date(b.publishDate),
      defaultSortOrder: 'descend',
    };

    switch (currentKeys) {
      case 'all':
        columns.splice(9, 0, actCol);
        columns.splice(10,1); //antd bug
        return columns;
      case 'published':
        columns.splice(8, 1, actCol);
        return columns;
      case 'scheduling':
        columns.splice(6, 0, createDate, publishDate);
        columns.splice(9, 2, actCol);
        return columns;
      case 'unpublished':   
        columns.splice(8, 1, actCol);
        return columns;
      case 'draft':
        columns.splice(6, 0, createDate);
        columns.splice(8, 2, actCol);
        return columns;
      default:
    }
  };

  return (
    <>
      <Table
        // locale={ locale }
        columns={renderTableCol()}
        dataSource={tableData}
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleChange={handleDelete}
      />
      <ListSubModal
        isOpen={isListSubModalOpen}
        data={questionDetail}
        handleClose={handleSubModalClose}
      />
    </>
  );
};

export default ListQuestionDataTable;
