import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space, Radio, Select, Switch, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import '../../../../styles/components/DrawerForm.css';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import { useCustomerGroup } from '../../../../application/menu/useCustomerGroup';
import CustomerCheckBox from '../../../layouts/CustomerCheckBox';
import { inputValidator } from '../../../../application/validator';
import CustomerSelect from '../../../layouts/CustomerSelect';
import CustomerRadio from '../../../layouts/CustomerRadio';

const urlInformation = '同網頁路徑下，可輸入相對位置。';

const FunctionDrawerForm = ({
  isOpen,
  isEdit,
  editData,
  handleClose,
  handleSave,
  handleEdit,
}) => {
  const { customerGroupList } = useCustomerGroup();
  const { requiredValidator, symbolValidator } = inputValidator();
  const { Option } = Select;
  const [form] = Form.useForm();

  const [trustBody, setTrustBody] = useState([
    {
      key: '',
      value: '',
    },
  ]);
  const [openFunctionMethod, setOpenFunctionMethod] = useState(null);
  const [isOpenPermission, setIsOpenPermission] = useState(1);

  const options = [
    {
      id: 'HOT',
      title: 'HOT'
    },
    {
      id: 'NEW',
      title: 'NEW'
    }
  ];

  console.log(editData);

  useEffect(() => {
    if(editData) {
      form.setFieldsValue({
        title: editData.title,
        icon: editData.icon,
        isTrust: editData.isTrust ? 0 : 1,
        isFullUrl: editData.isFullUrl,
        needLogin: editData.needLogin ? 0 : 1,
        needLoginDisplay: editData.needLoginDisplay ? 0 : 1,
        hasPermission: editData.hasPermission ? 0 : 1,
        isActive: editData.isActive,
        remark: editData.remark,
      });
      if(editData.isTrust) {
        form.setFieldsValue({
          trustUrl: editData.trustUrl
        });
      } else {
        form.setFieldsValue({
          url: editData.url
        });
      }
      if(editData.isBlank) {
        form.setFieldsValue({
          method: 'isBlank',
          
        });
      } else if (editData.isOpen) {
        form.setFieldsValue({
          method: 'isOpen',
          openWidth: editData.openWidth,
          openHeight: editData.openHeight,
        });
        setOpenFunctionMethod('isOpen');
      } else {
        form.setFieldsValue({
          method: 'none',
        });
      }

      if(editData.hasPermission) {
        const groups = editData.permissions.groups.map(g => {return g.groupId;});
        form.setFieldsValue({
          groups: groups,
          users: editData.permissions.users,
        });
      }
    }
    // if (editData.title) {
    //   setFunctionTitle(editData.title);  
    // }
    // if (editData.icon) {
    //   setFunctionIcon(editData.icon);
    // }
    // if (editData.isTrust) {
    //   setIsNeedTrust(editData.isTrust);
    //   form.setFieldsValue({});
    // }
    // if (editData.remark) {
    //   setRemark(editData.remark);
    // }
    // if (editData.url) {
    //   setUrl(editData.url);
    // }
    
    
    // if (editData.trustUrl) {
    //   setTrustUrl(editData.trustUrl);
    // }
    // /* trustBody */
    // if (editData.trustBody) {
    //   const newTrustBody = [];
    //   Object.entries(editData.trustBody).forEach(([key, value]) => {
    //     const item = {
    //       key: key,
    //       value: value,
    //     };
    //     newTrustBody.push(item);
    //   });
    //   setTrustBody(newTrustBody);
    // }
    // if (editData.isFullUrl) {
    //   setIsOpenFullUrl(editData.isFullUrl);
    // }
    // if (editData.isBlank) {
    //   setOpenFunctionMethod('isBlank');
    // }
    // if (editData.isOpen) {
    //   setOpenFunctionMethod('isOpen');
    // }
    // if (editData.openWidth) {
    //   setOpenWidth(editData.openWidth);
    // }
    // if (editData.openHeight) {
    //   setOpenHeight(editData.openHeight);
    // }
    // if (editData.hasPermission) {
    //   setIsOpenPermission(editData.hasPermission);
    // }
    // if (editData.needLogin) {
    //   setIsNeedToLogin(editData.needLogin);
    // }
    // if (editData.needLoginDisplay) {
    //   setIsLoginShow(editData.needLoginDisplay);
    // }
    // if (editData.isActive) {
    //   setIsActive(editData.isActive);
    // }
    // if (editData.permissions) {
    //   const data = [];
    //   editData.permissions.groups.map(e => {
    //     data.push(e.groupId);
    //   });
    //   setPermissionGroups(data);
    // }
    // if (editData.permissions) {
    //   setPermissionUsers(editData.permissions.users);
    // }
  }, [editData, form]);

  const handleClickToSave = (value) => {
    console.log(value);
    let tBody = '';
    for(let i = 0; i < trustBody.length; i++) {
      tBody += '{';
      tBody += trustBody[i].key + ': ';
      tBody += trustBody[i].value + '}';
      if(i !== trustBody.length -1) {
        tBody += ',';
      }
    }
    const result = {
      title: value.title,
      icon: value.icon === undefined ? '' : value.icon,
      isTrust: value.isTrust === 0 ? true : false,
      url: value.url ? value.url : '',
      trustUrl: value.trustUrl ? value.trustUrl : '',
      trustBody: tBody,
      isFullUrl: value.isFullUrl,
      isBlank: value.method === 'isBlank' ? true : false,
      isOpen: value.method === 'isOpen' ? true : false,
      openWidth: value.openWidth ? value.openWidth : 0,
      openHeight: value.openHeight ? value.openHeight : 0,
      needLogin: value.needLogin === 0 ? true : false,
      needLoginDisplay: value.needLoginDisplay === 0 ? true : false,
      hasPermission: value.hasPermission === 0 ? true : false,
      permissionGroups: value.groups ? value.groups : [],
      permissionUsers: value.users ? value.users : [],
      isActive: value.isActive ? value.isActive : false,
      remark: value.remark
    };

    if(openFunctionMethod !== 'isOpen') {
      result.openWidth = 0;
      result.openHeight = 0;
    }

    if(isOpenPermission) {
      result.permissionGroups = [];
      result.permissionUsers = [];
    }

    console.log(result);
    // const result = {
    //   functionTitle,
    //   functionIcon,
    //   isNeedTrust,
    //   trustUrl,
    //   trustBody,
    //   url,
    //   isOpenFullUrl,
    //   openFunctionMethod,
    //   openWidth,
    //   openHeight,
    //   isNeedToLogin,
    //   isLoginShow,
    //   isOpenPermission,
    //   permissionGroups,
    //   permissionUsers,
    //   isActive,
    //   remark,
    // };
    isEdit ? handleEdit(result) : handleSave(result);
  };

  console.log(trustBody);

  const changeTrustBodyKey = (index, value) => {
    const copyTrustBody = [...trustBody];
    copyTrustBody[index].key = value;
    setTrustBody(copyTrustBody);
  };

  const changeTrustBodyValue = (index, value) => {
    const copyTrustBody = [...trustBody];
    copyTrustBody[index].value = value;
    setTrustBody(copyTrustBody);
  };

  const selectFunctionMethod = e => {
    setOpenFunctionMethod(e.target.value);
  };


  const handleChangIsOpenPermission = e => {
    setIsOpenPermission(e.target.value);
  };

  const appendTrustBody = () => {
    const array = [...trustBody];
    array.push({
      key: '',
      value: '',
    });
    setTrustBody(array);
  };

  const handleDrawerClose = () => {
    handleClose();
    form.resetFields();
    setTrustBody([
      {
        key: '',
        value: '',
      },
    ]);
    setOpenFunctionMethod(null);
    setIsOpenPermission(1);
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯功能' : '新增功能'}
        width={700}
        onClose={handleDrawerClose}
        visible={isOpen}
        footer={
          <Space>
            <CustomerButton onClick={handleDrawerClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              form='menu-function-form'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='menu-function-form'
          onFinish={handleClickToSave}
          layout='horizontal'
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 6 }}
          initialValues={{
            title: '',
            // icon: '',
            isTrust: 1,
            url: '',
            method: '',
            isFullUrl: false,
            needLogin: 1,
            needLoginDisplay: 1,
            hasPermission: 1,
            isActive: false,
            remark: '',
          }}>
          <Form.Item
            label='功能名稱'
            name='title'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput placeholder='請輸入功能名稱' />
          </Form.Item>

          <Form.Item label='標記' name='icon'>
            <CustomerSelect placeholder='無' options={options} />
          </Form.Item>

          <Form.Item label='是否需要Trust' name='isTrust'>
            <CustomerRadio options={['是', '否']} />
          </Form.Item>

          <Form.Item
            shouldUpdate
            wrapperCol={{ span: 24 }}
            className='trust-form'>
            {() => {
              const isTrust = form.getFieldValue('isTrust');
              return isTrust ? (
                <Form.Item
                  label='URL 網址'
                  name='url'
                  wrapperCol={{ span: 24, offset: 3 }}
                  // className='form-input-with-checkbox'
                  rules={[requiredValidator()]}>
                  <CustomerInput placeholder='請輸入URL 網址' />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    label='Trust URL 網址'
                    name='trustUrl'
                    wrapperCol={{ span: 24, offset: 1 }}
                    className='form-input-with-checkbox'
                    rules={[requiredValidator()]}>
                    <CustomerInput placeholder='請輸入Trust URL 網址' />
                  </Form.Item>
                  <Form.Item
                    label='Trust Body參數'
                    name='trustBody'
                    wrapperCol={{ span: 18, offset: 1 }}
                    className='trust-input-item'
                    required
                    >
                    {trustBody.map((item, idx) => (
                      <Space key={idx} className='menu-flex-form-label'>
                        <Form.Item name={`trustKey${ idx }`} rules={[requiredValidator()]}>
                          <CustomerInput
                            // style={{ width: '48%' }}
                            placeholder='key'
                            value={item.key}
                            onChange={e => {
                              changeTrustBodyKey(idx, e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item name={`trustValue${ idx }`} rules={[requiredValidator()]}>
                          <CustomerInput
                            // style={{ width: '48%' }}
                            placeholder='value'
                            value={item.value}
                            onChange={e => {
                              changeTrustBodyValue(idx, e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Space>
                      // <main className='menu-flex-form-label' key={idx}>
                      //   <CustomerInput
                      //     style={{ width: '48%' }}
                      //     placeholder='key'
                      //     value={item.key}
                      //     onChange={e => {
                      //       changeTrustBodyKey(idx, e.target.value);
                      //     }}
                      //   />
                      //   <CustomerInput
                      //     style={{ width: '48%' }}
                      //     placeholder='value'
                      //     value={item.value}
                      //     onChange={e => {
                      //       changeTrustBodyValue(idx, e.target.value);
                      //     }}
                      //   />
                      // </main>
                    ))}
                  </Form.Item>
                  <Form.Item
                    className='form-has-two-input-checked'
                    wrapperCol={{ offset: 5 }}>
                    <CustomerButton
                      type='link'
                      style={{ color: '#3f5372', fontSize: 16, marginLeft: 8 }}
                      onClick={appendTrustBody}>
                      + 新增一組 Key 與 Value
                    </CustomerButton>
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 6 }}
            className='trust-from-checkbox'
            name='isFullUrl'
            valuePropName='checked'>
            <CustomerCheckBox>
              是否開啟完整URL
              <Tooltip placement='bottom' title={urlInformation}>
                <InfoCircleOutlined />
              </Tooltip>
            </CustomerCheckBox>
          </Form.Item>

          <Form.Item label='功能開啟方式' name='method'>
            <Radio.Group
              onChange={selectFunctionMethod}
              value={openFunctionMethod}>
              <Radio value={'none'}>原頁開啟</Radio>
              <Radio value={'isBlank'}>另開新分頁</Radio>
              <Radio value={'isOpen'}>另開新視窗</Radio>
            </Radio.Group>
          </Form.Item>

          {openFunctionMethod === 'isOpen' ? (
            <Form.Item label='另開新視窗尺寸' className='isOpen-form' required>
              <Space>
                <Form.Item label='寬度' name='openWidth' rules={[requiredValidator()]}>
                  <CustomerInput />
                </Form.Item>
                <Form.Item label='高度' name='openHeight' rules={[requiredValidator()]}>
                  <CustomerInput />
                </Form.Item>
              </Space>
            </Form.Item>
          ) : null}

          <Form.Item label='是否需要登入' name='needLogin'>
            <CustomerRadio options={['是', '否']} />
          </Form.Item>

          <Form.Item label='登入後顯示' name='needLoginDisplay'>
            <CustomerRadio options={['是', '否']} />
          </Form.Item>

          <Form.Item label='是否開啟權限控管' name='hasPermission'>
            <CustomerRadio options={['是', '否']}  
              onChange={handleChangIsOpenPermission}
              value={isOpenPermission}/>
          </Form.Item>

          {!isOpenPermission ? (
            <Form.Item label='綁定用戶權限' name='groups'>
              <Select mode='tags'>
                {customerGroupList.map((option, idx) => (
                  <Option value={option.id} key={idx}>
                    {option.groupName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}

          {!isOpenPermission ? (
            <Form.Item label='自訂用戶權限' name='users'>
              <Select mode='tags'></Select>
            </Form.Item>
          ) : null}

          <Form.Item label='狀態'>
            <Form.Item name='isActive' valuePropName='checked'>
              <Switch />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isActive');
                return (
                  <span style={{ marginLeft: 8 }}>
                    {isActive ? '顯示' : '隱藏'}
                  </span>
                );
              }}
            </Form.Item>
          </Form.Item>

          <Form.Item name='remark' label='註記'>
            <CustomerInput maxLength={20} />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default FunctionDrawerForm;
