import React, { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Drawer, Form, Space, Upload, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import CustomerButton from '../../layouts/CustomerButton';
import CustomerInput from '../../layouts/CustomerInput';
import CustomerSwitch from '../../layouts/CustomerSwitch';

import '../../../styles/components/DrawerForm.css';

import { inputValidator } from '../../../application/validator';
import CustomerRadio from '../../layouts/CustomerRadio';

const AdDrawerForm = ({
  editData,
  isOpen,
  isEdit,
  handleClose,
  handleSave,
  handleUpdate,
  form,
}) => {
  const {
    requiredValidator,
    // lowerCaseValidator,
    minLenValidator,
    symbolValidator,
    urlValidator,
  } = inputValidator();

  const { adSlotId } = useParams();

  const [desktopImage, setDesktopImage] = useState([]);
  const [mobileImage, setMobileImage] = useState([]);

  const { RangePicker } = DatePicker;

  const uploadProps = {
    action: `${ process.env.REACT_APP_FILES_UPLOAD_URL }`,
    headers: {
      token: cookies.get('elktree-demo-key'),
    },
  };

  const formatSaveTime = (time) => {
    const year = moment(time).format('YYYY');
    const month = moment(time).format('MM');
    const day = moment(time).format('DD');
    const hour = moment(time).format('HH');
    const min = moment(time).format('mm');

    return year + month + day + hour + min;
  };

  useEffect(() => {
    if (editData.desktopImagePath) {
      setDesktopImage(imageFileList(editData.desktopImagePath));
    }
    if (editData.mobileImagePath) {
      setMobileImage(imageFileList(editData.mobileImagePath));
    }
  }, [editData]);

  const handleClickToSave = value => {
    const params = {
      ...value,
      slotId: Number(adSlotId),
      desktopImagePath: desktopImage[0].name,
      mobileImagePath: mobileImage[0].name,
      startTime: formatSaveTime(value.timepicker[0]._d),
      endTime: formatSaveTime(value.timepicker[1]._d),
      isBlank: value.isBlank === 0,
    };

    delete params['timepicker'];

    isEdit ? handleUpdate({...params, adId: editData.id}) : handleSave(params);
  };

  // console.log(editData);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上傳圖片</div>
    </div>
  );

  const imageFileList = img => {
    return [
      {
        uid: img,
        name: img,
        status: 'done',
        url: `${ process.env.REACT_APP_IMAGE_URL }/${ img }`,
      },
    ];
  };

  // getFileList by form
  const normDesktopImageFile = e => {
    console.log('Upload event:', e);

    if (e.file.status === 'done') {
      setDesktopImage(imageFileList(e.file.response.result.filename));
    } else {
      setDesktopImage([]);
    }

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  // getFileList by form
  const normMobileImageFile = e => {
    console.log('Upload event:', e);

    if (e.file.status === 'done') {
      setMobileImage(imageFileList(e.file.response.result.filename));
    } else {
      setMobileImage([]);
    }

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };


  const disabledDate = current => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  };

  const handleDrawerClose = () => {
    handleClose();
    setDesktopImage([]);
    setMobileImage([]);
  };

  // console.log(desktopImage);

  return (
    <>
      <Drawer
        title={isEdit ? '編輯廣告圖片' : '新增廣告圖片'}
        width={700}
        onClose={handleDrawerClose}
        visible={isOpen}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleDrawerClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              color='blue'
              form='product-drawer'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='product-drawer'
          className='form-group'
          layout='horizontal'
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 6 }}
          onFinish={handleClickToSave}
          initialValues={{
            adName: '',
            desktopImagePath: [],
            mobileImagePath: [],
            timepicker: [],
            url: '',
            isBlank: 1,
            isActive: false,
          }}>
          <Form.Item
            name='adName'
            label='廣告標題'
            rules={[
              requiredValidator(),
              symbolValidator(),
              minLenValidator(2),
            ]}>
            <CustomerInput placeholder='請輸入廣告標題名稱' />
          </Form.Item>

          <Form.Item
            // name='desktopImagePath'
            label='上傳電腦版圖片'
            getValueFromEvent={normDesktopImageFile}
            valuePropName='fileList'
            rules={[{ required: true, message: '請上傳圖片。' }]}
            >
            <Upload {...uploadProps} listType='picture-card' maxCount={1}>
              {/* {desktopImage.length > 0 ? null : uploadButton} */}
              {uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item
            // name='mobileImagePath'
            label='上傳手機版圖片'
            getValueFromEvent={normMobileImageFile}
            valuePropName='fileList'
            rules={[{ required: true, message: '請上傳圖片。' }]}
            >
            <Upload {...uploadProps} listType='picture-card' maxCount={1}>
              {/* {mobileImage.length > 0 ? null : uploadButton} */}
              {uploadButton}
            </Upload>
          </Form.Item>

          <Form.Item
            name='timepicker'
            label='起始/結束時間'
            rules={[
              {
                required: true,
                message: '請選擇起訖日期。',
              },
            ]}>
            <RangePicker
              placeholder={['起始時間', '結束時間']}
              className='drawer-form-timepicker'
              disabledDate={disabledDate}
              format="YYYY-MM-DD HH:mm"
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  moment('00:00', 'HH:mm'),
                  moment('23:59', 'HH:mm'),
                ],
              }}
            />
          </Form.Item>

          <Form.Item
            name='url'
            label='連結網址'
            rules={[requiredValidator(), urlValidator()]}>
            <CustomerInput placeholder='請輸入網址 http://...' />
          </Form.Item>

          <Form.Item label='網址開啟方式' name='isBlank'>
            <CustomerRadio options={['原頁開啟', '另開新分頁']} />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive' valuePropName='checked'>
              <CustomerSwitch />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isActive');
                return <span>{isActive ? '顯示' : '隱藏'}</span>;
              }}
            </Form.Item>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AdDrawerForm;
