import { HELLO_WORLD } from '../types';

const initialState = {};

const Hello_World = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case HELLO_WORLD:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default Hello_World;
