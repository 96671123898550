import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space } from 'antd';

import '../../../../styles/components/DrawerForm.css';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import CustomerSwitch from '../../../layouts/CustomerSwitch';
import CustomerSearchInput from '../../../layouts/CustomerSearchInput';
import { inputValidator } from '../../../../application/validator';

const UserManegeDrawerForm = ({
  isOpen,
  isEdit,
  editData,
  handleClose,
  handleSearchUser,
  handleSave,
  handleUpdate,
  isLoading,
  enterBtnText,
  form,
}) => {
  const { requiredValidator } = inputValidator();

  const [isActive, setIsActive] = useState(false);
  const [selectGroups, setSelectGroups] = useState([]);

  useEffect(() => {
    if (editData.isActive) {
      setIsActive(editData.isActive);
    }

    if (editData?.groups && editData?.groups.length > 0) {
      const groups = [];
      editData.groups.forEach(e => {
        groups.push(e.groupId);
      });
      setSelectGroups(groups);
    }
  }, [editData]);

  const handleClickToSave = value => {
    if (isEdit) {
      handleUpdate({
        groups: selectGroups,
        isActive: value.isActive,
        staffNo: value.staffNo,
      });
    } else {
      handleSave({
        groups: selectGroups,
        isActive: value.isActive,
        staffNo: value.staffNo,
      });
    }
  };

  const handleSwitchChange = checked => {
    setIsActive(checked);
  };

  const handleDrawerClose = () => {
    handleClose();
    setIsActive(false);
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯使用者' : '新增使用者'}
        width={700}
        onClose={handleDrawerClose}
        visible={isOpen}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleDrawerClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              form='function-user-drawer'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='function-user-drawer'
          layout='horizontal'
          onFinish={handleClickToSave}
          wrapperCol={{ span: 22 }}
          labelCol={{ span: 6 }}
          initialValues={{
            staffNo: '',
            staffName: '',
            isActive: isActive,
          }}>
          <Form.Item
            label='員工編號'
            name='staffNo'
            rules={isEdit ? [] : [requiredValidator()]}>
            {isEdit ? (
              <CustomerInput disabled={true} />
            ) : (
              <CustomerSearchInput
                placeholder='請輸入員工編號'
                onSearch={handleSearchUser}
                enterButton={enterBtnText}
                allowClear
                isLoading={isLoading}
              />
            )}
          </Form.Item>

          <Form.Item label='使用者名稱' name='staffName'>
            <CustomerInput placeholder='使用者名稱' disabled={true} />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive'>
              <CustomerSwitch
                onChange={handleSwitchChange}
                checked={isActive}
              />
            </Form.Item>
            <span>{isActive ? '有效' : '無效'}</span>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default UserManegeDrawerForm;
