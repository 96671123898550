import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Table, Space } from 'antd';

import CustomerButton from '../../../layouts/CustomerButton';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
import MenuFunctionSubListModal from './MenuFunctionSubListModal';
import Icon from '../../../../assets/images/datatable/basic-external-link.svg';

import '../../../../styles/layouts/MenuDataTable.css';


const FunctionDataTable = ({
  tableData,
  handleClickSelectFunctionDetail,
  functionDetails,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState(null);
  const [isSubDataModalOpen, setSubDataModalOpen] = useState(false);

  const refSearchInput = useRef();

  const handleCancelModalOpen = id => {
    setSelectDeleteId(id);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const handleSubDataModalOpen = async id => {
    await handleClickSelectFunctionDetail(id);
    // setSubListData(data);
    setSubDataModalOpen(true);
  };

  const handleSubDataModalClose = () => {
    setSubDataModalOpen(false);
  };

  const columns = [
    {
      key: 'action',
      width: '3%',
      ellipsis: 'true',
      render(text, record) {
        return (
          <div
            className='dataTable-expand-button'
            onClick={() => handleSubDataModalOpen(record.id)}>
            <img src={Icon} style={{ width: 20, height: 20 }} alt="展開"/>
          </div>
        );
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '6%',
      ellipsis: 'true',
    },
    {
      title: '功能名稱',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'title',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
    },
    {
      title: '是否需登入',
      dataIndex: 'needLogin',
      key: 'needLogin',
      width: '12%',
      ellipsis: 'true',
      render(text) {
        return <div>{text ? '是' : '否'}</div>;
      },
    },
    {
      title: '登入後顯示',
      dataIndex: 'needLoginDisplay',
      key: 'needLoginDisplay',
      width: '12%',
      ellipsis: 'true',
      render(text) {
        return <div>{text ? '是' : '否'}</div>;
      },
    },
    {
      title: '建立時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
    },
    {
      title: '更新時間',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '15%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
    },
    {
      title: '狀態',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '8%',
      ellipsis: 'true',
      render(text) {
        return (
          <span style={text ? { color: '#22a16f' } : { color: '#f45a4c' }}>
            {text ? '顯示' : '隱藏'}
          </span>
        );
      },
    },
    {
      title: '註記',
      dataIndex: 'remark',
      key: 'remark',
      width: '12%',
      ellipsis: 'true',
    },
    {
      title: '動作',
      key: 'action',
      width: '20%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={() => {
                handleCancelModalOpen(record.id);
              }}>
              刪除
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                handleEditClick(record.id);
              }}>
              編輯
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={ columns }
        dataSource={ tableData }
        pagination={ { showSizeChanger: true, } }
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={ isCancelModalOpen }
        handleClose={ handleCancelModalClose }
        handleChange={ handleDelete }
      />
      <MenuFunctionSubListModal
        isOpen={ isSubDataModalOpen }
        handleClose={ handleSubDataModalClose }
        data={ functionDetails }
      />
    </>
  );
};

export default FunctionDataTable;
