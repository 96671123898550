import React from 'react';
import { Empty } from 'antd';
import CustomerButton from './CustomerButton';
import dataNullImg from '../../assets/images/common/data-null.svg';

const EmptyDisplay = ({ handleReload, isNeedReloadBtn }) => (
  <>
    <Empty
      style={{ paddingBottom: 28 }}
      image={dataNullImg}
      imageStyle={{
        height: 140,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 12,
        marginBottom: 16,
      }}
      description={
        <span
          style={{
            marginBottom: 20,
            fontSize: 16,
            fontWeight: 'normal',
            letterSpacing: '0.4px',
            color: '#3f5372',
          }}>
          搜尋無結果
        </span>
      }>
      {isNeedReloadBtn ? (
        <CustomerButton color='red' onClick={handleReload}>
          重新整理
        </CustomerButton>
      ) : null}
    </Empty>
    <style jsx global>
      {`
        .ant-table-tbody > tr > td {
          border-bottom: none;
        }
      `}
    </style>
  </>
);

export default EmptyDisplay;
