import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

/**
 * [requestGetCommonQuestionLists]
 * @return {object} [result]
 */
export async function requestGetCommonQuestionLists(params) {
  const url = '/JSON/question/questionList.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetCommonQuestionLists]
 * @return {object} [result]
 */
export async function requestGetCommonQuestionDetail(id) {
  // const url = `/api/question/commonList/${ id }`;
  const url = '/JSON/question/questionDetail.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPostCommonQuestion]
 * @param  {string} options.title          [問題標題]
 * @param  {string} options.content        [問題內容]
 * @param  {string} options.keywords       [關鍵字]
 * @param  {number} options.categoryId     [主分類編號]
 * @param  {number} options.category2ndId  [第二層分類編號]
 * @param  {number} options.category3rdId  [第三層分類編號]
 * @param  {string} options.publishDate    [發布日期]
 * @param  {boolean} options.isPublish     [是否發布]
 * @param  {boolean} options.isFeatured    [是否精選]
 * @param  {boolean} options.isTop         [是否置頂]
 * @param  {Array}  options.attachments    [附件]
 *  @return {object}                       [result]
 */
export async function requestPostCommonQuestion({
  title,
  content,
  keywords,
  categoryId,
  category2ndId,
  category3rdId,
  publishDate,
  isPublish,
  isFeatured,
  isTop,
  attachments = []
}) {
  const url = '/api/question/commonList';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, {
      title,
      content,
      keywords,
      categoryId,
      category2ndId,
      category3rdId,
      publishDate,
      isPublish,
      isFeatured,
      isTop,
      attachments
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPutCommonQuestion]
 * @return {object} [result]
 */
export async function requestPutCommonQuestion({
  questionId,
  title,
  content,
  keywords,
  categoryId,
  category2ndId,
  category3rdId,
  publishDate,
  isPublish,
  isFeatured,
  isTop,
  attachments = []
}) {
  const url = '/api/question/commonList';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, {
      questionId,
      title,
      content,
      keywords,
      categoryId,
      category2ndId,
      category3rdId,
      publishDate,
      isPublish,
      isFeatured,
      isTop,
      attachments
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeleteCommonQuestion]
 * @return {object} [result]
 */
export async function requestDeleteCommonQuestion(id) {
  const params = {
    questionId: id
  };
  const url = '/api/question/commonList';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
