import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { useSelector } from 'react-redux';

import '../../styles/layouts/Breadcrumb.css';

function BreadcrumbLayout() {

  const routeList = useSelector(state => state.breadcrumb.routeList);
  
  return (
    <>
      <Breadcrumb className='site-breadcrumb'>
        {
          routeList.map((breadcrumb, idx) => (
            <Breadcrumb.Item key={ idx }>
              {
                breadcrumb.path ? (
                  <Link to={ breadcrumb.path }>
                    { breadcrumb.pageName }
                  </Link>
                ) : (<span>{ breadcrumb.pageName }</span>)
              }
            </Breadcrumb.Item>
          ))
        }
      </Breadcrumb>
    </>
  );
}

export default BreadcrumbLayout;
