import { useState, useEffect } from 'react';
import Ajv from 'ajv';
import { customers } from '../../domain/menu/customersManage.js';
import { customersGroup } from '../../domain/menu/customerGroupManage.js';
import {
  requestGetCustomerGroupList,
  requestCreateNewGroup,
  requestUpdateCustomersGroup,
  requestDeleteCustomerGroup,
  requestGetCustomersList,
  requestUpdateCustomer
} from '../../services/menu/customerService.js';

export function useCustomerGroup() {
  const [customerGroupList, setCustomerGroupList] = useState([]);

  const ajv = new Ajv();
  const validate = ajv.compile(customersGroup);

  useEffect(() => {
    getCustomerGroupList();
  }, []);

  const getCustomerGroupList = async() => {
    const listData = await requestGetCustomerGroupList();
    if (listData.status && listData.data) {
      setCustomerGroupList(listData.data);
    }
  };

  const createCustomerGroup = async(userGroupName) => {
    const valid = validate({ groupName: userGroupName });
    if (!valid) {
      console.log(validate.errors);
      return false;
    }
    const result = await requestCreateNewGroup(userGroupName);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const updateCustomerGroup = async({ id, newGroupName }) => {
    const result = await requestUpdateCustomersGroup({
      id,
      updateName: newGroupName
    });
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const deleteCustomerGroup = async(id) => {
    const result = await requestDeleteCustomerGroup(id);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    customerGroupList,
    getCustomerGroupList,
    createCustomerGroup,
    updateCustomerGroup,
    deleteCustomerGroup
  };
}


export function useCustomersList(groupId) {
  const [customersList, setCustomersList] = useState([]);

  const ajv = new Ajv();
  const validate = ajv.compile(customers);

  useEffect(() => {
    getCustomerList(groupId);
  }, [groupId]);

  const getCustomerList = async(groupId) => {
    const listData = await requestGetCustomersList(groupId);
    if (listData.status && listData.data) {
      setCustomersList(listData.data);
    }
  };

  const appendNewCustomerIntoGroup = async(newCustomer) => {
    const valid = validate({ userId: newCustomer });
    if (!valid) {
      console.log(validate.errors);
      return false;
    }
    const result = await requestUpdateCustomer({
      groupId,
      addMembers: [newCustomer]
    });
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const deleteCustomerFromGroup = async(deletedCustomer) => {
    const result = await requestUpdateCustomer({
      groupId,
      delMembers: [deletedCustomer]
    });
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    customersList,
    getCustomerList,
    appendNewCustomerIntoGroup,
    deleteCustomerFromGroup
  };
}
