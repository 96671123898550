import {
  SET_BREAD_CRUMB
} from '../types';

const initialState = {
  routeList: [
    {
      path: '/',
      pageName: '首頁'
    }
  ]
};

const Breadcrumb = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_BREAD_CRUMB:
      return {
        ...state,
        routeList: payload.routeList
      };
    default:
      return state;
  }
};

export default Breadcrumb;
