import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

/**
 * [requestGetFunctionMenu]
 * @param  {number} functionGroupId     [function group ID]
 * @return {object}                     [result]
 */
export async function requestGetFunctionMenu(functionGroupId) {
  // const url = `/api/auth/userGroupMenu?groupIds=${ functionGroupId }`;
  const result = {
    status: false,
    data: null
  };
  // try {
  //   result.data = await getRequest(url);
  //   result.status = true;
  // } catch (err) {
  //   result.data = errorHandle(err);
  // }
  return result;
}

/**
 * [requestGetFunctionDatail]
 * @param  {number} id [group id]
 * @return {object}    [result]
 */
export async function requestGetFunctionDetail(id) {
  const url = `/JSON/permission/permissionFunctionList.json`;
  const result = {
    status: false,
    data: null
  };
  try {
    // if (!id) {
    //   throw Error('id required');
    // }
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    if (err.toString() === 'Error: id required') {
      result.data = err.toString();
    } else {
      result.data = errorHandle(err);
    }
  }
  return result;
}

/**
 * [requestCreateNewPermission]
 * @param  {number} options.groupId        [group ID]
 * @param  {string} options.permissionName [permission name]
 * @param  {string} options.permissionCode [permission code]
 * @param  {string} options.permissionPath [permission path]
 * @param  {boolean} options.isActive      [isActive]
 * @return {object}                        [result]
 */
export async function requestCreateNewPermission({ 
  groupId,
  permissionName,
  permissionCode,
  permissionPath,
  isActive
}) {
  const url = '/api/auth/permission';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, {
      groupId,
      permissionName,
      permissionCode,
      permissionPath,
      isActive
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestUpdatePermission({
  permissionId,
  permissionName,
  permissionCode,
  permissionPath,
  isActive
}) {
  const url = '/api/auth/permission';
  const requestBody = {
    permissionId,
    permissionName,
    permissionCode,
    permissionPath,
    isActive
  };
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, requestBody);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeletePermission]
 * @param  {number} id  [id]
 * @return {object}     [result]
 */
export async function requestDeletePermission(id) {
  const url = '/api/auth/permission';
  const result = {
    status: false,
    data: null
  };
  const body = {
    permissionId: id
  };
  try {
    if (!id) {
      throw Error('no id');
    }
    result.data = await deleteRequest(url, body);
    result.status = true;
  } catch (err) {
    if (err.toString() === 'Error: no id') {
      result.data = err.toString();
    } else {
      result.data = errorHandle(err);
    }
  }
  return result;
}
