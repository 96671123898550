import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space } from 'antd';

import '../../../../styles/components/DrawerForm.css';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import CustomerSwitch from '../../../layouts/CustomerSwitch';
import CustomerRadio from '../../../layouts/CustomerRadio';
import { inputValidator } from '../../../../application/validator';

const PlatformCreateDrawerForm = ({
  form,
  isOpen,
  isEdit,
  editData,
  handleClose,
  handleEdit,
  handleSave,
}) => {
  const {
    requiredValidator,
    symbolValidator,
    maxLenValidator,
    lowerCaseValidator,
    minLenValidator,
  } = inputValidator();

  const [isDesktopMainActive, setDesktopMainActive] = useState(true);
  const [isDesktopPersonalActive, setDesktopPersonalActive] = useState(false);
  const [isMobileMainActive, setMobileMainActive] = useState(false);
  const [isMobilePersonalActive, setMobilePersonalActive] = useState(false);

  useEffect(() => {
    if (editData.desktop) {
      editData?.desktop?.main?.isActive
        ? setDesktopMainActive(true)
        : setDesktopMainActive(false);
    }
    editData?.desktop?.personal?.isActive
      ? setDesktopPersonalActive(true)
      : setDesktopPersonalActive(false);
    editData?.mobile?.main?.isActive
      ? setMobileMainActive(true)
      : setMobileMainActive(false);
    editData?.mobile?.personal?.isActive
      ? setMobilePersonalActive(true)
      : setMobilePersonalActive(false);
  }, [editData]);

  const handleClickToSave = value => {
    const result = {
      platformName: value.platformName,
      platformCode: value.platformCode,
      desktop: {
        main: {
          // id: 34,
          isActive: value.isDesktopMainActive,
          needLoginDisplay: value.isDesktopMainDisplay === 0,
        },
        personal: {
          // id: 34,
          isActive: value.isDesktopPersonalActive,
          needLoginDisplay: value.isDesktopPersonalDisplay === 0,
        },
      },
      mobile: {
        main: {
          // id: 34,
          isActive: value.isMobileMainActive,
          needLoginDisplay: value.isMobileMainDisplay === 0,
        },
        personal: {
          // id: 34,
          isActive: value.isMobilePersonalActive,
          needLoginDisplay: value.isMobilePersonalDisplay === 0,
        },
      },
    };
    isEdit ? handleEdit(result) : handleSave(result);
  };

  const changeDesktopMainActive = () => {
    setDesktopMainActive(isDesktopMainActive === true ? false : true);
  };

  const changeDesktopPersonalActive = () => {
    setDesktopPersonalActive(isDesktopPersonalActive === true ? false : true);
  };

  const changeMobileMainActive = () => {
    setMobileMainActive(isMobileMainActive === true ? false : true);
  };

  const changeMobilePersonalActive = () => {
    setMobilePersonalActive(isMobilePersonalActive === true ? false : true);
  };

  const handleDrawerClose = () => {
    handleClose();
    setDesktopMainActive(true);
    setDesktopPersonalActive(false);
    setMobileMainActive(false);
    setMobilePersonalActive(false);
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯平台' : '新增平台'}
        width={700}
        onClose={handleDrawerClose}
        visible={isOpen}
        footer={
          <Space>
            <CustomerButton onClick={handleDrawerClose}>取消</CustomerButton>
            <CustomerButton
              form='menu-platform-form'
              htmlType='submit'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          id='menu-platform-form'
          form={form}
          className='form-group'
          layout='horizontal'
          onFinish={handleClickToSave}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 6 }}
          initialValues={{
            isDesktopMainActive: true,
            isDesktopPersonalActive: false,
            isMobileMainActive: false,
            isMobilePersonalActive: false,
          }}>
          <Form.Item
            name='platformName'
            label='平台名稱'
            rules={[
              requiredValidator(),
              maxLenValidator(20),
              symbolValidator(),
            ]}>
            <CustomerInput placeholder='請輸入平台名稱' />
          </Form.Item>

          <Form.Item
            name='platformCode'
            label='平台代碼'
            rules={[
              requiredValidator(),
              lowerCaseValidator(),
              minLenValidator(2),
              maxLenValidator(63),
            ]}>
            <CustomerInput placeholder='請輸入平台代碼' disabled={isEdit} />
          </Form.Item>

          <Form.Item label='電腦版選單設定'>
            <b className='note-text'>
              電腦版-主選單/個人選單若未啟用，預設選單會以電腦版選單為預設。
            </b>
          </Form.Item>

          <Form.Item label='主選單' labelCol={{ offset: 6, span: 5 }}>
            <Form.Item name='isDesktopMainActive' valuePropName='checked'>
              <CustomerSwitch
                checked={isDesktopMainActive}
                onChange={changeDesktopMainActive}
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isDesktopMainActive');
                return <span>{isActive ? '啟用' : '關閉'}</span>;
              }}
            </Form.Item>
          </Form.Item>

          {form.getFieldValue('isDesktopMainActive') ? (
            <Form.Item
              name='isDesktopMainDisplay'
              label='登入後顯示'
              labelCol={{ offset: 6, span: 5 }}>
              <CustomerRadio options={['是', '否']} />
            </Form.Item>
          ) : null}

          <Form.Item label='個人選單' labelCol={{ offset: 6, span: 5 }}>
            <Form.Item name='isDesktopPersonalActive' valuePropName='checked'>
              <CustomerSwitch
                checked={isDesktopPersonalActive}
                onChange={changeDesktopPersonalActive}
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isDesktopPersonalActive');
                return <span>{isActive ? '啟用' : '關閉'}</span>;
              }}
            </Form.Item>
          </Form.Item>

          {form.getFieldValue('isDesktopPersonalActive') ? (
            <Form.Item
              name='isDesktopPersonalDisplay'
              label='登入後顯示'
              labelCol={{ offset: 6, span: 5 }}>
              <CustomerRadio options={['是', '否']} />
            </Form.Item>
          ) : null }

          <Form.Item label='手機版選單設定'>
            <b className='note-text'>
              手機版-主選單/個人選單若未啟用，預設選單會以此平台電腦版選單為預設。
            </b>
          </Form.Item>

          <Form.Item label='主選單' labelCol={{ offset: 6, span: 5 }}>
            <Form.Item name='isMobileMainActive' valuePropName='checked'>
              <CustomerSwitch
                checked={isMobileMainActive}
                onChange={changeMobileMainActive}
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isMobileMainActive');
                return <span>{isActive ? '啟用' : '關閉'}</span>;
              }}
            </Form.Item>
          </Form.Item>
          {form.getFieldValue('isMobileMainActive') ? (
            <Form.Item
              name='isMobileMainDisplay'
              label='登入後顯示'
              labelCol={{ offset: 6, span: 5 }}>
              <CustomerRadio options={['是', '否']} />
            </Form.Item>
          ) : null}

          <Form.Item label='個人選單' labelCol={{ offset: 6, span: 5 }}>
            <Form.Item name='isMobilePersonalActive' valuePropName='checked'>
              <CustomerSwitch
                checked={isMobilePersonalActive}
                onChange={changeMobilePersonalActive}
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isMobilePersonalActive');
                return <span>{isActive ? '啟用' : '關閉'}</span>;
              }}
            </Form.Item>
          </Form.Item>

          {form.getFieldValue('isMobilePersonalActive') ? (
            <Form.Item
              name='isMobilePersonalDisplay'
              label='登入後顯示'
              labelCol={{ offset: 6, span: 5 }}>
              <CustomerRadio options={['是', '否']} />
            </Form.Item>
          ) : null}

          <Form.Item wrapperCol={{ span: 15 }}></Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default PlatformCreateDrawerForm;
