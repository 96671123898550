import { useState, useEffect } from 'react';
import Ajv from 'ajv';
import { functionPermission } from '../../domain/permission/functionPermission.js';
import {
  requestGetFunctionDetail,
  requestCreateNewPermission,
  requestDeletePermission,
  requestUpdatePermission,
  // requestGetFunctionMenu
} from '../../services/permission/functionDetailsService.js';

export function useFunctionGroup(id) {
  const [groupDetails, setGroupDetails] = useState([]);

  const ajv = new Ajv();
  const validate = ajv.compile(functionPermission);

  useEffect(() => {
    getGroupDetails(id);
  }, [id]);

  const getGroupDetails = async(id) => {
    const res = await requestGetFunctionDetail();
    if (res.status) {
      const data = res.data.data;
      const groupIndex = data.findIndex(g => g.id === id);
      setGroupDetails(data[groupIndex].permissions);
      // setGroupDetails(data.data.data);
    }
  };

  const selectPermissionData = (permissionId) => {
    const sortData = groupDetails.filter(e => e.id === permissionId);
    if (sortData.length > 0) {
      return sortData[0];
    } else {
      return false;
    }
  };

  const createNewPermission = async(body) => {
    const valid = validate(body);
    if (!valid) {
      console.log(validate.errors);
      return false;
    }
    const result = await requestCreateNewPermission(body);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const updatePermission = async({ 
    permissionId,
    permissionName,
    permissionCode,
    permissionPath,
    isActive
  }) => {
    const requestBody = {
      permissionId,
      permissionName,
      permissionCode,
      permissionPath,
      isActive
    };
    const valid = validate(requestBody);
    if (!valid) {
      console.log(validate.errors);
      return false;
    }
    const result = await requestUpdatePermission(requestBody);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const deletePermission = async(id) => {
    const result = await requestDeletePermission(id);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    groupDetails,
    getGroupDetails,
    createNewPermission,
    updatePermission,
    deletePermission,
    selectPermissionData
  };
}

export function useFunctionsMenu() {
  // const [functionsMenu, setFunctionMenu] = useState([]);

  // useEffect(() => {
  //   getFunctionMenu(0);
  // }, []);

  // const getFunctionMenu = async(groupId) => {
    // const listData = await requestGetFunctionMenu(groupId);
    // if (listData?.data.data.result.length > 0) {
    //   const result = listData.data.data.result;
    //   const dataArray = [];
    //   result.map((e) => {
    //     const permissions = e.permissions;
    //     const childrenArray = [];
    //     permissions.map((el) => {
    //       const childrenItem = {
    //         title: el.permissionName,
    //         key: el.id,
    //         hasAuth: el.hasAuth,
    //       };
    //       childrenArray.push(childrenItem);
    //     });
    //     const item = {
    //       title: e.groupName,
    //       key: `g-${ e.id }`,
    //       children: childrenArray
    //     };
    //     dataArray.push(item);
    //   });
    //   setFunctionMenu(dataArray);
    // }
  // };

  return {
    // functionsMenu,
    // getFunctionMenu
  };
}
