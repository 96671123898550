import React from 'react';
import { Button } from 'antd';

import '../../styles/layouts/CustomerButton.css';

const CustomerButton = ({
  children,
  color,
  onClick,
  className,
  htmlType,
  ...props
}) => {
  return (
    <Button
      className={`customer-button customer-button-${ color } ${ className || '' }`}
      onClick={onClick}
      htmlType={htmlType}
      {...props}>
      {children}
    </Button>
  );
};

export default CustomerButton;
