import React from 'react';
import { Modal } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';

import CustomerButton from '../../../layouts/CustomerButton';

import '../../../../styles/layouts/SubListModal.css';

const PlatformSubModal = ({ isOpen, handleClose, data }) => {
  if (!data) {
    return (<span></span>);
  }

  return (
    <Modal
      className='sub-table-modal'
      centered
      visible={isOpen}
      title='詳細列表'
      onCancel={handleClose}
      width={520}
      destroyOnClose
      footer={[
        <CustomerButton key='submit' color='blue' onClick={handleClose}>
          確認
        </CustomerButton>,
      ]}>
      <table className='sub-table'>
        <tbody className='sub-table-tbody'>
          <tr>
            <td>平台名稱</td>
            <td>{data.platformName ? data.platformName : ''}</td>
          </tr>
          <tr>
            <td>平台代碼</td>
            <td>{data.platformCode ? data.platformCode : ''}</td>
          </tr>
          <tr>
            <td colSpan='100' style={{ backgroundColor: '#e6ebf5' }}>
              電腦版選單
            </td>
          </tr>
          <tr>
            <td>主選單</td>
            <td
              style={
                data.desktop.main.isActive
                  ? { color: '#22a16f' }
                  : { color: '#f45a4c' }
              }>
              {data.desktop.main.isActive ? '啟用' : '關閉'}
            </td>
          </tr>
          <tr>
            <td>登入後顯示</td>
            <td>{data.desktop.main.needLoginDisplay ? '是' : '無'}</td>
          </tr>
          <tr>
            <td>個人選單</td>
            <td
              style={
                data.desktop.personal.isActive
                  ? { color: '#22a16f' }
                  : { color: '#f45a4c' }
              }>
              {data.desktop.personal.isActive ? '啟用' : '關閉'}
            </td>
          </tr>
          <tr>
            <td>登入後顯示</td>
            <td>{data.desktop.personal.needLoginDisplay ? '是' : '無'}</td>
          </tr>
          <tr>
            <td colSpan='100' style={{ backgroundColor: '#e6ebf5' }}>
              手機版選單
            </td>
          </tr>
          <tr>
            <td>主選單</td>
            <td
              style={
                data.mobile.main.isActive
                  ? { color: '#22a16f' }
                  : { color: '#f45a4c' }
              }>
              {data.mobile.main.isActive ? '啟用' : '關閉'}
            </td>
          </tr>
          <tr>
            <td>登入後顯示</td>
            <td>{data.mobile.main.needLoginDisplay ? '是' : '無'}</td>
          </tr>
          <tr>
            <td>個人選單</td>
            <td
              style={
                data.mobile.personal.isActive
                  ? { color: '#22a16f' }
                  : { color: '#f45a4c' }
              }>
              {data.mobile.personal.isActive ? '啟用' : '關閉'}
            </td>
          </tr>
          <tr>
            <td>登入後顯示</td>
            <td>{data.mobile.personal.needLoginDisplay ? '是' : '無'}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default PlatformSubModal;