/* eslint-disable */
import React from 'react';
import FunctionPermissionGroup from '../../presentation/pages/permission/function';
import FunctionQuestionManager from '../../presentation/pages/permission/functionQuestionManager';
import UserGroup from '../../presentation/pages/permission/usergroup';
import UserGroupManager from '../../presentation/pages/permission/userGroupManager';
import UserManager from '../../presentation/pages/permission/userManage';

const permissionRoutes = [
  {
    path: '/permission/function',
    exact: true,
    view: () => <FunctionPermissionGroup />,
  },
  {
    path: '/permission/function/:id/:name',
    exact: false,
    view: () => <FunctionQuestionManager />,
  },
  {
    path: '/permission/user_group',
    exact: true,
    view: () => <UserGroup />,
  },
  {
    path: '/permission/user_group/:id/:name',
    exact: true,
    view: () => <UserGroupManager />,
  },
  {
    path: '/permission/users',
    exact: true,
    view: () => <UserManager />,
  },
];

export default permissionRoutes;
