import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { Table, Space } from 'antd';

import ColumnSearchProps from '../../layouts/ColumnSearchForm';
import CustomerButton from '../../layouts/CustomerButton';
import CancelModal from '../../layouts/CancelModal';
import { sendErrorMessage, sendSuccessMessage } from '../../layouts/Message';
// import ProductEditDrawerForm from './ProductEditDrawerForm';

import '../../../styles/components/DataTable.css';

const AdSlotsDataTable = ({
  tableData,
  handleEditClick,
  handleDeleteClick,
}) => {
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState(null);
  const [adCounts, setAdCounts] = useState();
  const [data, setData] = useState(tableData);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);

  const refSearchInput = useRef();

  const handleCancelModalOpen = (id, count) => {
    setSelectDeleteId(id);
    setAdCounts(count);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    if (adCounts > 0) {
      sendErrorMessage('請先刪除廣告，再刪除分類。');
    } else {
      handleDeleteClick(selectDeleteId);
      sendSuccessMessage('刪除成功。');
    }
    handleCancelModalClose();
  };

  const toAdsPage = (id, name) => {
    window.location = `advertisement/${ id }/${ name }`;
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '7%',
      ellipsis: 'true',
    },
    {
      title: '板位名稱',
      dataIndex: 'slotName',
      key: 'slotName',
      width: '15%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'slotName',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
    },
    {
      title: '廣告數量',
      dataIndex: 'adCounts',
      key: 'adCounts',
      width: '8%',
      ellipsis: 'true',
    },
    {
      title: '建立時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '14%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '14%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
    },
    {
      title: '電腦版建議尺寸',
      // dataIndex: 'isActive',
      key: 'desktopSize',
      width: '9%',
      ellipsis: 'true',
      render(text, record) {
        return (
          <span>
            {record.desktopWidth}x<br/>{record.desktopHeight}px
          </span>
        );
      },
    },
    {
      title: '手機版建議尺寸',
      // dataIndex: 'isActive',
      key: 'mobileSize',
      width: '9%',
      ellipsis: 'true',
      render(text, record) {
        return (
          <span>
            {record.mobileWidth}x<br/>{record.mobileHeight}px
          </span>
        );
      },
    },
    {
      title: '狀態',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '8%',
      ellipsis: 'true',
      render(text) {
        return (
          <div style={text ? { color: '#22a16f' } : { color: '#f45a4c' }}>
            {text ? '顯示' : '隱藏'}
          </div>
        );
      },
    },
    {
      title: '最後更新者',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: '10%',
      ellipsis: 'true',
    },
    {
      title: '動作',
      key: 'action',
      width: '30%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={() => {
                handleCancelModalOpen(record.id, record.adCounts);
              }}>
              刪除
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                handleEditClick(record.id);
              }}>
              編輯
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                toAdsPage(record.id, record.slotName);
              }}>
              查看廣告
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        rowKey='id'
        pagination={false}
        className='table-container'
      />
      <CancelModal
        isOpen={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleChange={handleDelete}
      />
    </>
  );
};

export default AdSlotsDataTable;
