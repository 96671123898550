import { useState, useEffect } from 'react';
import { 
  requestGetUsersGroupList,
  requestSingleUserGroup,
  requestCreateNewUsersGroup,
  requestDeleteUserGroup,
  requestUpdateUserGroup
} from '../../services/permission/usersGroupService.js';

export function useUserGroup() {
  const [userGroupList, setUserGroupList] = useState([]);

  useEffect(() => {
    getUsersGroupList();
  }, []);

  const getUsersGroupList = async() => {
    const listData = await requestGetUsersGroupList();
    if (listData.status) {
      // const data = [];
      // listData.data.data.map((e) => {
      //   const item = {
      //     usersCount: e.users.length,
      //     ...e
      //   };
      //   data.push(item);
      // });
      setUserGroupList(listData.data.data);
    }
  };

  const getSingleUserGroupEditData = async(groupId) => {
    let targetData;
      const res = await requestSingleUserGroup(groupId);
      const resData = res.data.data;
      const groupIndex = resData.findIndex(i => i.id === groupId);
      targetData = resData[groupIndex];
      return targetData;
  };

  const createNewUserGroup = async(newUserGroupData) => {
    const result = await requestCreateNewUsersGroup(newUserGroupData);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const updateUserGroup = async(updatedUserGroupData) => {
    const result = await requestUpdateUserGroup(updatedUserGroupData);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const deleteUserGroup = async(id) => {
    const result = await requestDeleteUserGroup(id);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    userGroupList,
    getSingleUserGroupEditData,
    getUsersGroupList,
    createNewUserGroup,
    updateUserGroup,
    deleteUserGroup
  };
}

export function useSingleGroup(userGroupId) {
  const [groupUsersList, setGroupUsersList] = useState([]);
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [groupAllUserId,setGroupAllUserId] = useState([]);
  const [groupName, setGroupName] = useState('');

  useEffect(() => {
    getGroupUsersList(userGroupId);
  }, [userGroupId]);

  const getGroupUsersList = async(groupId) => {
    const res = await requestSingleUserGroup(groupId);
    const resData = res.data.data;
    const groupIndex = resData.findIndex(i => i.id === groupId);
    const groupData = resData[groupIndex];
    setGroupName(groupData.groupName);
    if (groupData.users?.length > 0) {
      setGroupUsersList(groupData.users);
      const usersArray = [];
      groupData.users.forEach((e) => {
        usersArray.push(e.userId);
      });
      setGroupAllUserId(usersArray);
    }

    if (resData.groupPermissions?.length > 0) {
      const permissionsData = [];
      resData.groupPermissions.forEach((group) => {
        group.permissions.map((item) => {
          return item.hasAuth ? permissionsData.push(item.id) : null;
        });
      });
      setGroupPermissions(permissionsData);
    }
  };

  const addUserIntoGroup = async(groupId, userId) => {
    if (groupAllUserId.filter(e => e === userId).length > 0) {
      return false;
    }

    const result = await requestUpdateUserGroup({
      groupId,
      users: [userId, ...groupAllUserId]
    });
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const deleteUserFromGroup = async(groupId, userId) => {
    const newUserIdArray = [...groupAllUserId];
    newUserIdArray.splice(groupAllUserId.indexOf(userId), 1);
    console.log(newUserIdArray);
    const result = await requestUpdateUserGroup({
      groupId,
      users: newUserIdArray
    });
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    groupUsersList,
    groupPermissions,
    groupAllUserId,
    groupName,
    getGroupUsersList,
    addUserIntoGroup,
    deleteUserFromGroup
  };

}
