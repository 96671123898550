import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

/**
 * [requestGetUsersList]
 * @return {Object}     [result]
 */
export async function requestGetUsersList() {
  const url = '/JSON/permission/userList.json';
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    console.log('res.data', res.data);
    result.data = res.data;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestUserBasicData]
 * @param  {String} staffNo [staff number]
 * @return {Object}         [result]
 */
export async function requestUserBasicData(staffNo) {
  const url = `/api/auth/emp/${ staffNo }`;
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestUserDetails]
 * @param  {Number} userId [user ID]
 * @return {Object}        [result]
 */
export async function requestUserDetails(userId) {
  const url = '/JSON/permission/userList.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestCreateNewAccount description]
 * @param  {String}  options.staffNo  [staff number]
 * @param  {Array}   options.groups   [groups id array]
 * @param  {Boolean} options.isActive [is active or not]
 * @return {Object}                   [result]
 */
export async function requestCreateNewAccount({ staffNo, groups = [], isActive = true }) {
  const url = '/api/auth/user';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, {
      staffNo,
      groups,
      isActive
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestUpdateAccount]
 * @param  {Number} options.userId    [user ID]
 * @param  {Array} options.groups     [groups id array]
 * @param  {Boolean} options.isActive [is active or not]
 * @return {Object}                   [result]
 */
export async function requestUpdateAccount({ userId, groups, isActive }) {
  const url = '/api/auth/user';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, {
      userId,
      groups,
      isActive
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeleteAccount]
 * @param  {Number} userId [user ID]
 * @return {Object}        [result]
 */
export async function requestDeleteAccount(userId) {
  const url = '/api/auth/user';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, {
      userId
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

