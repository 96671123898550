import React from 'react';
import { Radio } from 'antd';

import '../../styles/layouts/CustomerRadio.css';

const CustomerRadio = ({ onChange, options, value, ...props }) => {
  return (
    <Radio.Group onChange={onChange} value={value} {...props}>
      {options.map((option, idx) => (
        <Radio key={idx} value={idx}>
          {option}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default CustomerRadio;
