export const inputValidator = () => {
  const requiredValidator = name => {
    const validator = {
      required: true,
      message: name ? `${ name }為必填欄位` : '名稱不可為空白。',
    };
    return validator;
  };

  const symbolValidator = msg => {
    const validator = {
      pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9]*$/,
      message: msg || '名稱不能包含空格、標點符號。',
    };

    return validator;
  };

  const lowerCaseValidator = () => {
    const validator = {
      pattern: /^[a-zA-Z0-9_-]*$/,
      message: '名稱限半形英文、數字 、減號 (-)、下底線 (_)。',
    };

    return validator;
  };

  const maxLenValidator = len => {
    const validator = {
      max: len,
      message: `長度最多可為${ String(len) }個字元。`,
      type: 'string',
    };

    return validator;
  };

  const minLenValidator = len => {
    const validator = {
      min: len,
      message: `長度最少需為${ String(len) }個字元。`,
      type: 'string',
    };

    return validator;
  };

  const intValidator = () => {
    const validator = {
      type: 'number',
      message: '僅限整數數字。',
    };

    
    return validator;
  };

  const idValidator = () => {
    const validator = {
      pattern: /^[A-Z]{1}[1-2]{1}[0-9]{8}$/,
      message: '請輸入有效的身份證字號。',
    };

    return validator;
  };

  const selectValidator = () => {
    const validator = {
        message: '請選擇顯示階層。',
        required: true,
    };

    return validator;
  };

  const urlValidator = () => {
    const validator = {
      message: '請輸入正確網址。',
      type: 'url',
    };

    return validator;
  };

  return {
    requiredValidator,
    symbolValidator,
    lowerCaseValidator,
    maxLenValidator,
    minLenValidator,
    intValidator,
    idValidator,
    selectValidator,
    urlValidator,
  };
};
