import React from 'react';
import { Select } from 'antd';

import '../../styles/layouts/CustomerSelect.css';

const { Option } = Select;

const CustomerSelect = ({ placeholder, options, onChange, selectedValue, isSearch, ...props }) => {
  return (
    <Select
      placeholder={ placeholder }
      onChange={ onChange }
      value={selectedValue}
      showSearch={ isSearch }
      optionFilterProp={ 'children' }
      size='large'
      {...props}
      /* filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      } */
      /* filterSort={(optionA, optionB) =>
        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
      } */
      // dropdownRender={menu => <div>{console.log(menu)}123</div>}
    >
      { 
        options.map((option, idx) => (
          <Option key={idx} value={ option.id }>
            { option.title } { option.remark ? (<span style={{ color: '#b3b3b3' }}>{ `(${ option.remark })` }</span>) : null  }
          </Option>
        ))
      }
    </Select>
  );
};

export default CustomerSelect;
