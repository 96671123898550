import React, { useState } from 'react';
import moment from 'moment';
import { Table, Space } from 'antd';
import CustomerButton from '../../../layouts/CustomerButton';
import CancelModal from '../../../layouts/CancelModal';
import EmptyDisplay from '../../../layouts/EmptyDisplay';
import '../../../../styles/layouts/MenuDataTable.css';


  const ThirdCategoryTable = ({
    tableData,
    handleEditClick,
    handleDeleteClick,
  }) => {
    const [isCancelModalOpen, setCancelModal] = useState(false);
    const [selectDeleteId, setSelectDeleteId] = useState(null);

    const handleCancelModalOpen = id => {
      setSelectDeleteId(id);
      setCancelModal(true);
    };

    const handleCancelModalClose = () => {
      setCancelModal(false);
    };

    const handleDelete = () => {
      handleDeleteClick(selectDeleteId);
      handleCancelModalClose();
    };

    const columns = [
      {
        title: '第三層分類',
        dataIndex: 'categoryName',
        key: 'categoryName',
        width: '30%',
        ellipsis: 'true',
        render(text) {
          return <span style={{ color: '#0d1623' }}>{text}</span>;
        },
      },

      {
        title: '建立時間',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '15%',
        ellipsis: 'true',
        render(data) {
          return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
        },
        sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      },
      {
        title: '更新日期',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: '15%',
        ellipsis: 'true',
        render(data) {
          return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
        },
        sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      },
      {
        title: '狀態',
        dataIndex: 'isActive',
        key: 'isActive',
        width: '10%',
        ellipsis: 'true',
        render(text) {
          return (
            <div style={text ? { color: '#22a16f' } : { color: '#f45a4c' }}>
              {text ? '顯示' : '隱藏'}
            </div>
          );
        },
      },
      {
        title: '動作',
        key: 'action',
        width: '18%',
        ellipsis: 'true',
        render(record) {
          return (
            <Space size='middle'>
              <CustomerButton
                color='red'
                onClick={() => {
                  handleCancelModalOpen(record.id);
                }}>
                刪除
              </CustomerButton>
              <CustomerButton
                color='blue'
                onClick={() => {
                  handleEditClick(
                    record.id,
                    record.categoryName,
                    record.isActive
                  );
                }}>
                編輯
              </CustomerButton>
            </Space>
          );
        },
      },
    ];

    const locale = {
      emptyText: <EmptyDisplay />,
    };

    return (
      <>
        <Table
          locale={locale}
          columns={columns}
          dataSource={tableData}
          rowKey='id'
          className='table-container'
        />
        <CancelModal
          isOpen={isCancelModalOpen}
          handleClose={handleCancelModalClose}
          handleChange={handleDelete}
        />
      </>
    );
  };

export default ThirdCategoryTable;
