import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import CustomerButton from '../../layouts/CustomerButton';
import CategoryCreateSecondDrawerForm from '../../components/question/category/CategoryCreateSecondDrawer';
import SecondCategoryTable from '../../components/question/category/CategorySecondDataTable';
import { useRequestSecondQuestionCategory } from '../../../application/questions/useRequestSecondQuestionCategory';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import '../../../styles/pages/SitePage.css';

function SecondCategory() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [form] = Form.useForm();
  const { sIdx, sName } = useParams();

  const {
    getFirstAndSecondQuestionCategories,
    secondCategoryList,
    postSecondQuestionCategory,
    putRequestPutSecondCategory,
    delRequestDeleteSecondCategory,
  } = useRequestSecondQuestionCategory();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectId, setSelectId] = useState();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '常見問題管理',
        },
        {
          path: '/question/category/first',
          pageName: '分類列表',
        },
        {
          path: `/question/category/second/${ sIdx }/${ sName }`,
          pageName: `${ sName }`,
        },
      ])
    );
  }, [dispatch, sIdx, sName]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  const handleSave = async params => {
    const result = await postSecondQuestionCategory(params);
    if (!result) {
      sendErrorMessage('新增失敗');
    } else {
      sendSuccessMessage('新增成功');
      getFirstAndSecondQuestionCategories(sIdx);
      handleDrawerClose();
    }
  };

  const handleDeleteClick = async id => {
    const result = await delRequestDeleteSecondCategory(id);
    if (result !== true) {
      sendErrorMessage(result);
    } else {
      sendSuccessMessage('刪除成功');
      getFirstAndSecondQuestionCategories(sIdx);
    }
  };

  const handleEdit = async params => {
    const result = await putRequestPutSecondCategory(params);
    if (!result) {
      sendErrorMessage('編輯失敗');
      return;
    }
    sendSuccessMessage('編輯成功');
    getFirstAndSecondQuestionCategories(sIdx);
    handleDrawerClose();
  };

  const handleEditClick = (id, categoryName, isActive) => {
    form.setFieldsValue({
      category1stName: sName,
      categoryName: categoryName,
      isActive: isActive,
    });
    setSelectId(id);
    setIsEdit(true);
    handleDrawerOpen();
  };

  useEffect(() => {
    getFirstAndSecondQuestionCategories(sIdx);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>{sName}</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增第二層
            </CustomerButton>
          </div>
          <SecondCategoryTable
            tableData={secondCategoryList}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
          />
        </div>
      </Content>
      <CategoryCreateSecondDrawerForm
        handleClose={handleDrawerClose}
        handleSave={handleSave}
        handleEdit={handleEdit}
        isOpen={isDrawerOpen}
        form={form}
        selectId={selectId}
        isEdit={isEdit}
      />
    </Layout>
  );
}

export default SecondCategory;
