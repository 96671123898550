/* eslint-disable */
import React from 'react';
import MenuFunction from '../../presentation/pages/menu/function';
import UserGroup from '../../presentation/pages/menu/usergroup';
import UserGroupList from '../../presentation/pages/menu/userGroupList';
import Platform from '../../presentation/pages/menu/platform';
import PlatformManage from '../../presentation/pages/menu/platformManage';

const menuRoutes = [
  {
    path: '/menu/function',
    exact: true,
    view: () => <MenuFunction />,
  },
  {
    path: '/menu/group',
    exact: true,
    view: () => <UserGroup />,
  },
  {
    path: '/menu/group/:id/:name',
    exact: true,
    view: () => <UserGroupList />,
  },
  {
    path: '/menu/platform',
    exact: true,
    view: () => <Platform />,
  },
  {
    path: '/menu/platform/:id/:name',
    exact: true,
    view: () => <PlatformManage />,
  },
];

export default menuRoutes;
