import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import { errorHandle } from '../api/errorHandle.js';

export async function requestGetWebFunctionsList() {
  const url = '/api/menu/functions';
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestGetWebFunctionsSubList(id) {
  const url = `/api/menu/function/${ id }`;
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestCreateNewFunction(
//   { 
//   title,
//   icon,
//   remark,
//   url,
//   isFullUrl,
//   isTrust,
//   trustUrl,
//   trustBody,
//   isBlank,
//   isOpen,
//   openWidth,
//   openHeight,
//   hasPermission,
//   needLogin,
//   needLoginDisplay,
//   isActive,
//   permissionGroups,
//   permissionUsers
// }
params
) {
  const path = '/api/menu/function';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(path, params
    //   {
    //   title,
    //   icon,
    //   remark,
    //   url,
    //   isFullUrl,
    //   isTrust,
    //   trustUrl,
    //   trustBody,
    //   isBlank,
    //   isOpen,
    //   openWidth,
    //   openHeight,
    //   hasPermission,
    //   needLogin,
    //   needLoginDisplay,
    //   isActive,
    //   permissionGroups,
    //   permissionUsers
    // }
    );
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestUpdateFunction( params
//   {
//   functionId,
//   title,
//   icon,
//   remark,
//   url,
//   isFullUrl,
//   isTrust,
//   trustUrl,
//   trustBody,
//   isBlank,
//   isOpen,
//   openWidth,
//   openHeight,
//   hasPermission,
//   needLogin,
//   needLoginDisplay,
//   isActive,
//   permissionGroups,
//   permissionUsers
// }
) {
  const path = '/api/menu/function';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(path, params
    //   {
    //   functionId,
    //   title,
    //   icon,
    //   remark,
    //   url,
    //   isFullUrl,
    //   isTrust,
    //   trustUrl,
    //   trustBody,
    //   isBlank,
    //   isOpen,
    //   openWidth,
    //   openHeight,
    //   hasPermission,
    //   needLogin,
    //   needLoginDisplay,
    //   isActive,
    //   permissionGroups,
    //   permissionUsers
    // }
    );
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestDeleteFunction(id) {
  const url = '/api/menu/function';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, {
      functionId: id,
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
