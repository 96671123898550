import { requestGetQuestionCategories} from '../../services/questions/firstCategoryService';
import { requestGetQuestionCategoriesWithId } from '../../services/questions/secondCategoryService';
import { requestGet2ndQuestionCategoriesWithId } from '../../services/questions/thirdCategoryService';


// -----first-----
export function useRequestAllCategory() {
  const getQuestionCategories = async() => {
    const listData = await requestGetQuestionCategories();
    if (listData.status) {
      return listData.data.data;
    }
  };

  // ----second-----
  const getFirstAndSecondQuestionCategories = async(id) => {
    const listData = await requestGetQuestionCategoriesWithId(id);
    if (listData.status) {
      return (listData.data?.data?.category2nd);
    }
    return listData.data?.data?.category2nd;
  };

  // ----third-----
  const getSecondAndThirdQuestionCategories = async(id) => {
    const listData = await requestGet2ndQuestionCategoriesWithId(id);
    if (listData.status) {
      return(listData.data?.data?.category3rd);
    }
  };

  return {
    getQuestionCategories,
    getFirstAndSecondQuestionCategories,
    getSecondAndThirdQuestionCategories,
  };
}