import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  requestGetCommonQuestionLists,
  requestGetCommonQuestionDetail,
  requestPostCommonQuestion,
  requestPutCommonQuestion,
  requestDeleteCommonQuestion
  } from '../../services/questions/questionsService';

export function useRequestQuestion() {
  const [questionList, setQuestionList] = useState('');

  useEffect(() => {
    getCommonQuestionList();
  }, []);

  const getCommonQuestionDetail = async(questionId) => {
    const res = await requestGetCommonQuestionDetail(questionId);
    if (res.status) {
      return res.data.data.result;
    } else {
      return false;
    }
  };

  // -----questions-----

  const getCommonQuestionList = async(status, categoryIds, category2ndIds, category3rdIds) => {
    const params = {
      status,
      categoryIds,
      category2ndIds,
      category3rdIds,
    };
    const res = await requestGetCommonQuestionLists(params);
    let data;
    if (res.status) {
      if(res.data.data.length) {
        data = res.data.data;
        data.forEach(ele => {
          ele.category = ele.category.categoryName;
          ele.category2nd = ele.category2nd.categoryName;
          ele.category3rd = ele.category3rd.categoryName;
        });
        if(status) {
          const dataByStatus = data.filter(q => q.status === status);
          setQuestionList(dataByStatus);
        } else {
          setQuestionList(data);
        }
      } else{
        setQuestionList('');
      }
    }
  };

  const putQuestion = async(params) => {
    const res = await requestPutCommonQuestion(params);
    if (res.status) {
      return true;
    }
  };

  const delQuestion = async(id) => {
    const res = await requestDeleteCommonQuestion(id);
    if (res.status) {
      return true;
    }
  };

  return {
    getCommonQuestionDetail,
    getCommonQuestionList,
    questionList,
    putQuestion,
    delQuestion
  };
}

export function useQuestionDetails() {

  const getCommonQuestionDetail = async(questionId) => {
    const res = await requestGetCommonQuestionDetail(questionId);
    if (res.status) {
      return res.data.data;
    } else {
      return false;
    }
  };

  const createQuestion = async({
    type,
    title,
    content,
    categoryId,
    category2ndId,
    category3rdId,
    keywords,
    isFeatured,
    isTop,
    postMethod,
    publishDate,
    fileList
  }) => {
    // console.log(fileList);
    const attachments = [];
    fileList.forEach((e) => {
      attachments.push({
        displayName: e.name,
        filename: e.uid,
      });
    });
    let body;
    if (type === 'publish') {
      body = {
        title,
        content,
        categoryId,
        category2ndId,
        category3rdId,
        keywords,
        isFeatured,
        isTop,
        isPublish: type === 'publish' ? true : false,
        publishDate: postMethod === 'schedule' ? publishDate : moment(new Date()).format('YYYYMMDD'),
        attachments
      };
    } else {
      body = {
        title,
        content,
        categoryId,
        category2ndId,
        category3rdId,
        keywords,
        isFeatured,
        isTop,
        isPublish: type === 'publish' ? true : false,
        attachments
      };
    }

    // console.log(body);
    
    const res = await requestPostCommonQuestion(body);
    if (res.status) {
      return true;
    } else {
      return false;
    }
  };

  const updateQuestion = async({
    questionId,
    type,
    title,
    content,
    categoryId,
    category2ndId,
    category3rdId,
    keywords,
    isFeatured,
    isTop,
    postMethod,
    publishDate,
    fileList
  }) => {
    const attachments = [];
    if(fileList.length > 0) {
      fileList.forEach((e) => {
        console.log(e);
        attachments.push({
          displayName: e.name,
          filename: e.uid,
        });
      });
    }
    let body;
    if (type === 'publish') {
      body = {
        questionId,
        title,
        content,
        categoryId,
        category2ndId,
        category3rdId,
        keywords,
        isFeatured,
        isTop,
        isPublish: type === 'publish' ? true : false,
        publishDate: postMethod === 'schedule' ? publishDate : moment(new Date()).format('YYYYMMDD'),
        attachments
      };
    } else {
      body = {
        questionId,
        title,
        content,
        categoryId,
        category2ndId,
        category3rdId,
        keywords,
        isFeatured,
        isTop,
        isPublish: type === 'publish' ? true : false,
        attachments
      };
    }
    const res = await requestPutCommonQuestion(body);
    if (res.status) {
      return true;
    } else {
      return false;
    }
  };

  const deleteQuestion = async(id) => {
    const res = await requestDeleteCommonQuestion(id);
    if (res.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    getCommonQuestionDetail,
    createQuestion,
    updateQuestion,
    deleteQuestion,
  };

}
