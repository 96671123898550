import { useState, useEffect } from 'react';
import {
  requestGetAdSlots,
  requestPostAdSlot,
  requestVerifyAdSlot,
  requestGetAdSlot,
  requestPutAdSlot,
  requestDeleteAdSlot,
} from '../../services/advertisement/adSlotsService';

export function useRequestAdSlot() {
  const [adSlots, setAdSlots] = useState([]);

  useEffect(() => {
    getAdSlots();
  }, []);

  // 取得所有版位
  const getAdSlots = async() => {
    const listData = await requestGetAdSlots();
    if (listData.status) {
      setAdSlots(listData.data.data);
    }
  };

  const getVerifyAdSlot = async slotCode => {
    const res = await requestVerifyAdSlot(slotCode);
    if (res.status) {
      console.log(res);
      return res.data.data.success;
    }
  };

  const postAdSlot = async params => {
    const res = await requestPostAdSlot(params);
    if (res.status) {
      return true;
    }
  };

  // 取得版位 & 版位內的廣告
  const getAdSlot = async slotId => {
    const res = await requestGetAdSlot(slotId);
    if (res.status) {
      return res.data.data;
    }
  };

  const putAdSlot = async params => {
    const res = await requestPutAdSlot(params);
    if (res.status) {
      return true;
    }
  };

  const deleteAdSlot = async params => {
    const res = await requestDeleteAdSlot(params);
    if (res.status) {
      return true;
    }
  };

  return {
    adSlots,
    getAdSlots,
    getVerifyAdSlot,
    postAdSlot,
    getAdSlot,
    putAdSlot,
    deleteAdSlot,
  };
}
