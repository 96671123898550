import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';

import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import PlatformDataTable from '../../components/tradePlatform/PlatformDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import PlatformDrawerForm from '../../components/tradePlatform/PlatformDrawerForm';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import '../../../styles/pages/SitePage.css';
import { useRequestTradePlatformCategories } from '../../../application/tradePlatform/useRequestTradePlatform';

function TradePlatformPage() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [form] = Form.useForm();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [enterBtnText, setEnterBtnText] = useState('確認檢核');
  const [isLoading, setIsLoading] = useState(false);

  const {
    platformList,
    getTradePlatformCategories,
    getTradeTradePlatformCategory,
    postTradePlatformCategory,
    deleteTradePlatformCategory,
    putTradePlatformCategory,
    getVerifyPlatformCategoryCode,
  } = useRequestTradePlatformCategories();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '交易平台管理',
        },
        {
          path: '/trade-platform',
          pageName: '分類管理',
        },
      ])
    );
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  const handleSave = async saveData => {
    const result = await postTradePlatformCategory(saveData);
    if (!result) {
      sendErrorMessage('更新失敗');
      return;
    }

    handleDrawerClose();
    getTradePlatformCategories();
    sendSuccessMessage('更新成功');
  };

  const handleUpdate = async newData => {
    const body = {
      categoryId: selectId,
      ...newData,
    };
    console.log(body);
    const result = await putTradePlatformCategory(body);
    if (!result) {
      sendErrorMessage('更新失敗');
      return;
    }

    handleDrawerClose();
    getTradePlatformCategories();
    sendSuccessMessage('更新成功');
  };

  const handleDeleteClick = async id => {
    const result = await deleteTradePlatformCategory(id);
    if (!result) {
      sendErrorMessage('刪除失敗');
      return;
    } else {
      sendSuccessMessage('刪除成功');
      getTradePlatformCategories();
    }
  };

  const handleEditClick = async id => {
    setSelectId(id);
    const res = await getTradeTradePlatformCategory(id);
    console.log(res);
    form.setFieldsValue({
      categoryCode: res.categoryCode,
      categoryName: res.categoryName,
      isActive: res.isActive,
    });
    setEditData(res);
    setIsEdit(true);
    handleDrawerOpen();
  };

  const handleDragSort = async params => {
    console.log('handleDragSort-outside', params);
    const result = await putTradePlatformCategory(params);
    if (!result) {
      sendErrorMessage('排序失敗');
      return;
    } else {
      sendSuccessMessage('排序成功');
      getTradePlatformCategories();
    }
  };

  const handleSearchCode = async value => {
    setIsLoading(true);
    setEnterBtnText(<SyncOutlined spin />);
    const res = await getVerifyPlatformCategoryCode(value);
    if (res.status === 500) {
      form.setFields([
        { name: 'categoryCode', errors: ['分類代碼不可重複。'] },
      ]);
      setEnterBtnText('確認檢核');
    } else {
      form.setFields([{ name: 'categoryCode', errors: null }]);
      setEnterBtnText(<CheckOutlined />);
      setEnterBtnText('重新確認');
    }
    setTimeout(() => {
      setEnterBtnText('確認檢核');
    }, 5000);
    setIsLoading(false);
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>分類管理</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增分類
            </CustomerButton>
          </div>
          <PlatformDataTable
            tableData={platformList}
            handleDeleteClick={handleDeleteClick}
            handleEditClick={handleEditClick}
            handleDragSort={handleDragSort}
          />
        </div>
      </Content>
      <PlatformDrawerForm
        isOpen={isDrawerOpen}
        handleClose={handleDrawerClose}
        handleSave={handleSave}
        handleEdit={handleUpdate}
        isEdit={isEdit}
        isLoading={isLoading}
        enterBtnText={enterBtnText}
        editData={editData}
        form={form}
        handleSearch={handleSearchCode}
      />
    </Layout>
  );
}

export default TradePlatformPage;
