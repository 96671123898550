import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space } from 'antd';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import CustomerSwitch from '../../../layouts/CustomerSwitch';
import { useFunctionsMenu } from '../../../../application/permission/useFunctionGroup.js';
import { inputValidator } from '../../../../application/validator';

import '../../../../styles/components/DrawerForm.css';

const UserGroupDrawerForm = ({
  isOpen,
  isEdit,
  editData,
  handleClose,
  // handleSave,
  // handleUpdate,
}) => {
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const { functionsMenu } = useFunctionsMenu();
  const { requiredValidator, symbolValidator } = inputValidator();

  useEffect(() => {
    if (!isOpen) {
      setCheckedKeys([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (editData.isActive) {
      setIsActive(editData.isActive);
    }

    if (isEdit) {
      if (editData?.permissions?.length > 0) {
        setCheckedKeys(editData?.permissions);
      }
    }
  }, [editData, isEdit, functionsMenu]);

  // const onCheck = checkedKeysValue => {
  //   const inputCheck = checkedKeysValue.filter(e => typeof e === 'number');
  //   setCheckedKeys(inputCheck);
  // };

  // const handleClickToSave = value => {
  //   const formData = {
  //     ...value,
  //     permissions: checkedKeys
  //   };
  //   isEdit ? handleUpdate(formData) : handleSave(formData);

  //   console.log(formData);
  // };

  const handleSwitchChange = checked => {
    setIsActive(checked);
  };

  const handleDrawerClose = () => {
    setCheckedKeys([]);
    setIsActive(false);
    handleClose();
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯使用者群組' : '新增使用者群組'}
        width={700}
        onClose={handleDrawerClose}
        visible={isOpen}
        bodyStyle={{ paddingBottom: 80 }}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleDrawerClose}>取消</CustomerButton>
            <CustomerButton
              form='function-user-group-drawer'
              htmlType='submit'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          name='form'
          id='function-user-group-drawer'
          layout='horizontal'
          // onFinish={handleClickToSave}
          wrapperCol={{ span: 22 }}
          labelCol={{ span: 6 }}
          initialValues={{
            groupName: isEdit ? editData.groupName : null,
            isActive: isEdit ? editData.isActive : isActive,
            permissions: checkedKeys,
          }}>
          <Form.Item
            label='使用者群組名稱'
            name='groupName'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput maxLength={20} placeholder='請輸入使用者群組名稱' />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive'>
              <CustomerSwitch
                onChange={handleSwitchChange}
                checked={isActive}
              />
            </Form.Item>
            <span>{isActive ? '有效' : '無效'}</span>
          </Form.Item>

          {/* <Form.Item
            label='功能權限設定'
            className='drawer-tree-section'
            name='permissions'>
            <Tree
              treeData={functionsMenu}
              showLine={true}
              checkable
              checkedKeys={checkedKeys}
              onCheck={onCheck}></Tree>
          </Form.Item> */}
        </Form>
      </Drawer>
    </>
  );
};

export default UserGroupDrawerForm;
