import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Table, Space } from 'antd';
import CustomerButton from '../../../layouts/CustomerButton';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
import EmptyDisplay from '../../../layouts/EmptyDisplay';
import '../../../../styles/components/DataTable.css';


const GroupDataTable = ({ tableData, handleEditClick, handleDeleteClick }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId ] = useState(null);
  const refSearchInput = useRef();

  const handleResetSearch = () => {
    window.location.reload();
  };

  const handleCancelModalOpen = (id) => {
    setSelectDeleteId(id);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '6%',
      ellipsis: 'true',
    },
    {
      title: '群組名稱',
      dataIndex: 'groupName',
      key: 'groupName',
      width: '14%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'groupName',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
      render(text, record) {
        return (
          <Link
            style={{ color: '#0d1623' }}
            className='table-link'
            to={`group/${ record.id }/${ text }`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: '人數',
      dataIndex: 'memberCounts',
      key: 'memberCounts',
      width: '50%',
      ellipsis: 'true',
    },
    {
      title: '動作',
      key: 'action',
      width: '30%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton color='red' onClick={ () => { handleCancelModalOpen(record.id); } }>
              刪除
            </CustomerButton>
            <CustomerButton color='blue' onClick={ () => { handleEditClick(record.id, record.groupName); } }>
              編輯
            </CustomerButton>
            <Link
              style={{ color: '#0d1623' }}
              className='table-link'
              to={`group/${ record.id }/${ record.groupName }`}
            >
              <CustomerButton color='blue'>
                用戶名單
              </CustomerButton>
            </Link>
          </Space>
        );
      },
    },
  ];

  const locale = {
    emptyText: <EmptyDisplay handleReload={ handleResetSearch } isNeedReloadBtn={ tableData.length > 0 } />
  };

  return (
    <>
      <Table
        locale={ locale }
        columns={ columns }
        dataSource={ tableData }
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={ isCancelModalOpen }
        handleClose={ handleCancelModalClose }
        handleChange={ handleDelete }
      />
      
    </>
  );
};

export default GroupDataTable;
