import React, { useEffect, useState } from 'react';
import { Drawer, Form, Space } from 'antd';

import CustomerButton from '../../layouts/CustomerButton';
import CustomerInput from '../../layouts/CustomerInput';
import CustomerSwitch from '../../layouts/CustomerSwitch';
import CustomerSearchInput from '../../layouts/CustomerSearchInput';

import '../../../styles/components/DrawerForm.css';

import { inputValidator } from '../../../application/validator';

const ProductDrawerForm = ({
  editData,
  isOpen,
  isEdit,
  isLoading,
  handleClose,
  handleSave,
  handleUpdate,
  handleVerifyCode,
  enterBtnText,
  form,
}) => {
  const {
    requiredValidator,
    lowerCaseValidator,
    minLenValidator,
    symbolValidator,
  } = inputValidator();

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (editData.isActive) {
      setIsActive(editData.isActive);
    }
  }, [editData]);

  const handleClickToSave = value => {
    const updateResult = {
      categoryId: editData.id,
      sequence: editData.sequence,
      categoryName: value.categoryName,
      isActive: value.isActive,
    };
    isEdit ? handleUpdate(updateResult) : handleSave(value);
  };

  const handleSwitchChange = checked => {
    setIsActive(checked);
  };

  const handleSwitchClose = () => {
    handleClose();
    setIsActive(false);
  };

  return (
    <>
      <Drawer
        title={ isEdit ? '編輯分類' : '新增分類'  }
        width={700}
        onClose={handleSwitchClose}
        visible={isOpen}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleSwitchClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              color='blue'
              form='product-drawer'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='product-drawer'
          className='form-group'
          layout='horizontal'
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 5 }}
          onFinish={handleClickToSave}
          initialValues={{
            categoryCode: '',
            categoryName: '',
            isActive: isActive,
          }}>
          <Form.Item
            name='categoryCode'
            label='分類代碼'
            rules={[
              requiredValidator(),
              lowerCaseValidator(),
              minLenValidator(2),
            ]}>
            {isEdit ? (
              <CustomerInput disabled={true} />
            ) : (
              <CustomerSearchInput
                placeholder='請輸入分類英文名稱 (建立網址使用)'
                buttonText={enterBtnText}
                onSearch={handleVerifyCode}
                isLoading={isLoading}
              />
            )}
          </Form.Item>

          <Form.Item
            name='categoryName'
            label='分類名稱'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput placeholder='請輸入分類中文名稱' />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive'>
              <CustomerSwitch
                onChange={handleSwitchChange}
                checked={isActive}
              />
            </Form.Item>
            <span>{isActive ? '顯示' : '隱藏'}</span>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ProductDrawerForm;
