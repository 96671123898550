import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import ProductCategoryListDataTable from '../../components/product/ProductCategoryListDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import '../../../styles/pages/SitePage.css';
import useRequestFinancialProduct from '../../../application/product/useRequestProduct';

function ProductCategoryList() {
  const dispatch = useDispatch();
  const { id, name } = useParams();
  const { Content } = Layout;

  const [dataSource, setDataSource] = useState();

  const {
    getFinancialProductCategory,
    deleteFinancialProduct,
    putFinancialProduct,
  } = useRequestFinancialProduct();


  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '商品管理',
        },
        {
          path: '/product',
          pageName: '商品分類管理',
        },
        {
          path: `/product/${ id }/${ name }`,
          pageName: `${ name }`,
        },
      ])
    );
    const fetchData = async() => {
      const data = await getFinancialProductCategory(id);
      setDataSource(data);
    };
    fetchData();
    // eslint-disable-next-line
  }, [dispatch, id, name]);

  const handleDeleteFirst = async(productId) => {
    const result = await deleteFinancialProduct(productId);
    if (result !== true) {
      sendErrorMessage(result);
    } else {
      sendSuccessMessage('刪除成功');
      const data = await getFinancialProductCategory(id);
      setDataSource(data);
    }
  };

  const handleDragSort = async params => {
    console.log('handleDragSort-outside', params);
    const result = await putFinancialProduct(params);
    if (!result) {
      sendErrorMessage('排序失敗');
      return;
    } else {
      sendSuccessMessage('排序成功');
      getFinancialProductCategory(id);
    }
  };

  const toCreateProductPage = () => {
    window.location = window.location.pathname + '/create';
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>{name}</h1>
            <CustomerButton color='red' onClick={toCreateProductPage}>
              新增商品
            </CustomerButton>
          </div>
          <ProductCategoryListDataTable
            tableData={dataSource}
            handleDeleteClick={handleDeleteFirst}
            handleDragSort={handleDragSort}
          />
        </div>
      </Content>
    </Layout>
  );
}

export default ProductCategoryList;
