import React from 'react';
import { Modal, Form } from 'antd';
import CustomerButton from './CustomerButton';
import CustomerInput from './CustomerInput';
import { inputValidator } from '../../services/validotor';
import { sendErrorMessage } from './Message';

import '../../styles/layouts/FunctionModal.css';

const CreateGroupModal = ({
  isOpen,
  isEdit,
  handleClose,
  handleChange,
  handleUpdate,
  existGroupName,
  title,
  label,
  placeholder
}) => {

  const { requiredValidator, symbolValidator } = inputValidator();

  // const [groupName, setGroupName] = useState('');

  /*useEffect(() => {
    if (!isOpen) {
      setGroupName('');
    }
  }, [isOpen]);*/

  /* useEffect(() => {
    if (existGroupName) {
      setGroupName(existGroupName);
    }
  }, [existGroupName]); */

  /* const inputGroupName = e => {
    setGroupName(e.target.value);
  }; */

  const handleClickToSave = value => {
    isEdit ? handleUpdate(value.groupName) : handleChange(value.groupName);
  };

  const handleFinishFailed = errorInfo => {
    if (errorInfo) {
      sendErrorMessage('請檢查輸入內容。');
    }
  };

  return (
    <Modal
      className='function-modal'
      centered
      visible={isOpen}
      title={ title }
      onCancel={handleClose}
      width={320}
      destroyOnClose
      footer={[
        <CustomerButton key='cancel' onClick={ handleClose }>
          取消
        </CustomerButton>,
        <CustomerButton
          form='modal-form'
          key='submit'
          color='blue'
          htmlType='submit'>
          確定
        </CustomerButton>,
      ]}>
      <h4 className='function-modal-content modal-input-required'>{ label }</h4>
      {/*<Input
        placeholder={placeholder}
        className='function-modal-input'
        value={ groupName }
        onChange={ inputGroupName }
      />*/}
      <Form
        id='modal-form'
        onFinish={handleClickToSave}
        onFinishFailed={handleFinishFailed}>
        <Form.Item
          name='groupName'
          rules={[requiredValidator(), symbolValidator()]}>
          <CustomerInput
            maxLength={20}
            placeholder={ placeholder }
            defaultValue={existGroupName || ''}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateGroupModal;
