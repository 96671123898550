import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import '../../../styles/pages/SitePage.css';

import UserGroupDataTable from '../../components/permission/usergroup/UserGroupDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import UserGroupDrawerForm from '../../components/permission/usergroup/UserGroupDrawer';
// import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useUserGroup } from '../../../application/permission/useUsersGroup.js';

function UserGroup() {
  const dispatch = useDispatch();

  const { Content } = Layout;

  const {
    userGroupList,
    // getUsersGroupList,
    // createNewUserGroup,
    // deleteUserGroup,
    getSingleUserGroupEditData,
    // updateUserGroup,
  } = useUserGroup();

  const [isOpen, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  // const [selectId, setSelectId] = useState(null);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '權限管理',
        },
        {
          path: '/permission/user_group',
          pageName: '使用者群組管理',
        },
      ])
    );
  }, [dispatch]);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  // 新增
  // const handleSave = async newUserGroupData => {
  //   const result = await createNewUserGroup(newUserGroupData);
  //   if (!result) {
  //     sendErrorMessage('新增失敗');
  //     return;
  //   } else {
  //     handleModalClose();
  //     getUsersGroupList();
  //     sendSuccessMessage('新增成功');
  //   }
  // };

  // 編輯
  // const handleUpdate = async newUserGroupData => {
  //   const body = {
  //     groupId: selectId,
  //     ...newUserGroupData,
  //   };

  //   const result = await updateUserGroup(body);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   } else {
  //     handleModalClose();
  //     getUsersGroupList();
  //     sendSuccessMessage('更新成功');
  //   }
  // };

  const handleEditClick = async id => {
    // setSelectId(id);
    const res = await getSingleUserGroupEditData(id);
    setEditData(res);
    setIsEdit(true);
    handleModalOpen();
  };

  // 刪除
  // const handleDeleteClick = async id => {
  //   const result = await deleteUserGroup(id);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }
  //   getUsersGroupList();
  //   sendSuccessMessage('更新成功');
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>使用者群組管理</h1>
            <CustomerButton color='red' onClick={handleModalOpen}>
              新增使用者群組
            </CustomerButton>
          </div>
          <UserGroupDataTable
            tableData={userGroupList}
            handleEditClick={handleEditClick}
            // handleDeleteClick={handleDeleteClick}
          />
        </div>
      </Content>
      <UserGroupDrawerForm
        isOpen={isOpen}
        isEdit={isEdit}
        editData={editData}
        handleClose={handleModalClose}
        // handleSave={handleSave}
        // handleUpdate={handleUpdate}
      />
    </Layout>
  );
}

export default UserGroup;
