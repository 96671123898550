export const customersGroup = {
  title: 'customersGroup',
  type: 'object',
  required: ['groupName'],
  properties: {
    groupName: {
      type: 'string',
      minLength: 1
    }
  }
};
