import React, { useState, useRef } from 'react';
import { Table, Space } from 'antd';
import { Link } from 'react-router-dom';

import CustomerButton from '../../../layouts/CustomerButton';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
import PlatformSubModal from './PlatformSubModal';
import Icon from '../../../../assets/images/datatable/basic-external-link.svg';

import '../../../../styles/layouts/MenuDataTable.css';

const PlatformDataTable = ({
  tableData,
  handleEditClick,
  handleDeleteClick
}) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [isSubDataModalOpen, setSubDataModalOpen] = useState(false);
  const [currentDetailModalIndex, setCurrentDetailModalIndex] = useState('');
  const [selectDeleteId, setSelectDeleteId] = useState(null);
  const refSearchInput = useRef();

  const handleCancelModalOpen = platformCode => {
    setSelectDeleteId(platformCode);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const handleSubDataModalOpen = (index) => {
    setSubDataModalOpen(true);
    setCurrentDetailModalIndex(index);
  };

  const handleSubDataModalClose = () => {
    setSubDataModalOpen(false);
  };
    
  const handleSettingMenu = (id, name, code) => {
    window.location = `/cms/menu/platform/${ id }/${ name }?code=${ code }`;
  };

  const columns = [
    {
      key: 'action',
      width: '3%',
      ellipsis: 'true',
      render(text, record, index) {
        return (
          <div
            className='dataTable-expand-button'
            onClick={() => handleSubDataModalOpen(index)}>
            <img
              src={Icon}
              style={{ width: 20, height: 20 }}
              alt="展開"
            />
          </div>
        );
      },
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '6%',
      ellipsis: 'true',
    },
    {
      title: '平台名稱',
      dataIndex: 'platformName',
      key: 'platformName',
      width: '14%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'platformName',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
      render(text, record) {
        return (
          <Link
            style={{ color: '#0d1623' }}
            className='table-link'
            to={`/menu/platform/${ record.id }/${ text }?code=${ record.platformCode }`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: '平台代碼',
      dataIndex: 'platformCode',
      key: 'platformCode',
      width: '50%',
      ellipsis: 'true',
    },
    {
      title: '動作',
      key: 'action',
      width: '30%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={() => {
                handleCancelModalOpen(record.platformCode);
              }}>
              刪除
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                handleEditClick(record);
              }}>
              編輯
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                handleSettingMenu(record.id, record.platformName, record.platformCode);
              }}>
              設定選單
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleChange={handleDelete}
      />
      <PlatformSubModal
        isOpen={isSubDataModalOpen}
        handleClose={handleSubDataModalClose}
        data={tableData[currentDetailModalIndex]}
      />
    </>
  );
};

export default PlatformDataTable;
