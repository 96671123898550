import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';
// import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import UserManageDataTable from '../../components/permission/user/UserManageDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import UserManegeDrawerForm from '../../components/permission/user/UserManageDrawer';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';
import '../../../styles/pages/SitePage.css';

import { useUserManage } from '../../../application/permission/useUserManage.js';

function UserManager() {
  const dispatch = useDispatch();
  const { Content } = Layout;

  const {
    usersList,
    // getUsersList,
    getUserData,
    getUserDetails,
    // creatNewUser,
    // updateUser,
    // deleteUser,
  } = useUserManage();

  const [isOpen, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  // const [searchUserName, setSearchUserName] = useState('');
  // const [userEmpId, setUserEmpId] = useState('');
  // const [selectId, setSelectId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [enterBtnText, setEnterBtnText] = useState('確認檢核');
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '權限管理',
        },
        {
          path: '/permission/users',
          pageName: '使用者管理',
        },
      ])
    );
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  const handleEditClick = async id => {
    // setSelectId(id);
    const res = await getUserDetails(id);
    form.setFieldsValue({
      staffNo: res.staffNo,
      staffName: res.staffName,
      isActive: res.isActive,
    });
    setEditData(res);
    setIsEdit(true);
    handleDrawerOpen();
  };

  const handleSearchUser = async value => {
    setIsLoading(true);
    setEnterBtnText(<SyncOutlined spin />);
    const { name, empID } = await getUserData(value);
    const findStaffNo = usersList.filter(data => {
      return data.staffNo === empID;
    });
    if (!name) {
      form.setFields([{ name: 'staffNo', errors: ['查無此員工編號。'] }]);
      setEnterBtnText('確認檢核');
    } else if (findStaffNo.length > 0) {
      form.setFields([
        { name: 'staffNo', errors: ['員工編號不可重複。'] },
        { name: 'staffName', value: '' },
      ]);
      setEnterBtnText('確認檢核');
    } else {
      form.setFieldsValue({ staffName: name });
      form.setFields([{ name: 'staffNo', errors: null }]);
      setEnterBtnText(<CheckOutlined />);
      setEnterBtnText('重新確認');
    }
    setTimeout(() => {
      setEnterBtnText('確認檢核');
    }, 5000);
    setIsLoading(false);
  };

  // 新增
  // const handleSave = async saveData => {
  //   const result = await creatNewUser(saveData);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }

  //   handleDrawerClose();
  //   getUsersList();
  //   sendSuccessMessage('更新成功');
  // };

  // 編輯
  // const handleUpdate = async newUserData => {
  //   const body = {
  //     userId: selectId,
  //     ...newUserData,
  //   };
  //   const result = await updateUser(body);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }

  //   handleDrawerClose();
  //   getUsersList();
  //   sendSuccessMessage('更新成功');
  // };

  // 刪除
  // const handleDeleteClick = async id => {
  //   const result = await deleteUser(id);
  //   if (!result) {
  //     sendErrorMessage('刪除失敗');
  //     return;
  //   }
  //   getUsersList();
  //   sendSuccessMessage('刪除成功');
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>使用者管理</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增使用者
            </CustomerButton>
          </div>
          <UserManageDataTable
            tableData={usersList}
            handleEditClick={handleEditClick}
            // handleDeleteClick={handleDeleteClick}
          />
        </div>
      </Content>
      <UserManegeDrawerForm
        isOpen={isOpen}
        isEdit={isEdit}
        editData={editData}
        handleClose={handleDrawerClose}
        // handleSave={handleSave}
        handleSearchUser={handleSearchUser}
        // handleUpdate={handleUpdate}
        isLoading={isLoading}
        enterBtnText={enterBtnText}
        form={form}
      />
    </Layout>
  );
}

export default UserManager;
