import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import '../../../styles/pages/SitePage.css';

import FunctionQuestionDataTable from '../../components/permission/function/FunctionQuestionDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import FunctionQuestionDrawerForm from '../../components/permission/function/FunctionQuestionDrawer';
// import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import {
  useFunctionGroup
} from '../../../application/permission/useFunctionGroup.js';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

function FunctionQuestionManager() {
  const dispatch = useDispatch();

  const { Content } = Layout;
  const { name, id } = useParams();

  const {
    groupDetails,
    // getGroupDetails,
    // createNewPermission,
    // updatePermission,
    // deletePermission,
    selectPermissionData
  } = useFunctionGroup(id);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});
  // const [selectEditId, setSelectEditId] = useState(null);

  useEffect(() => {

    dispatch(setBreadcrumb([
      {
        path: '',
        pageName: '權限管理'
      },
      {
        path: '/permission/function',
        pageName: '功能權限群組管理'
      },
      {
        path: `'/permission/function/${ id }/${ name }`,
        pageName: `${ name }`
      }
    ]));

  }, [dispatch, id, name]);

  useEffect(() => {
    if (!isModalOpen) {
      setEditData({});
      setIsEdit(false);
      // setSelectEditId(null);
    }
  }, [isModalOpen]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleEditClick = (id) => {
    const selectData = selectPermissionData(id);
    if (!selectData) {
      // sendErrorMessage('系統錯誤，請稍後再試');
      return;
    }
    // setSelectEditId(id);
    setIsEdit(true);
    setEditData(selectData);
    handleModalOpen();
  };

  // 新增
  // const handleCreate = async(data) => {
  //   const body = {
  //     groupId: id,
  //     ...data
  //   };
  //   const result = await createNewPermission(body);
  //   if (!result) {
  //     sendErrorMessage('建立失敗');
  //     return;
  //   }
  //   getGroupDetails(id);
  //   handleModalClose();
  //   sendSuccessMessage('建立成功');
  // };

  // 編輯
  // const handleUpdate = async(data) => {
  //   console.log('updated', data);
  //   const body = {
  //     permissionId: selectEditId,
  //     ...data
  //   };
  //   const result = await updatePermission(body);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }
  //   getGroupDetails(id);
  //   sendSuccessMessage('更新成功');
  //   handleModalClose();
  // };

  // const handleDeleteClick = async(permissionid) => {
  //   const result = await deletePermission(permissionid);
  //   if (!result) {
  //     sendErrorMessage('刪除失敗');
  //     return;
  //   }
  //   getGroupDetails(id);
  //   sendSuccessMessage('刪除成功');
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>{ name }</h1>
            <CustomerButton
              color='red'
              onClick={ handleModalOpen }
            >
              新增權限
            </CustomerButton>
          </div>
          <FunctionQuestionDataTable
            detailsData={ groupDetails }
            // handleDeleteClick={ handleDeleteClick }
            handleEditClick={ handleEditClick }
          />
        </div>
      </Content>
      <FunctionQuestionDrawerForm
        isOpen={ isModalOpen }
        isEdit={ isEdit }
        editData={ editData }
        handleClose={ handleModalClose }
        // handleSave={ handleCreate }
        // handleUpdate={ handleUpdate }
      />
    </Layout>
  );
}

export default FunctionQuestionManager;
