/* eslint-disable */
import React from 'react';
import AdvertisementMainPage from '../../presentation/pages/advertisement';
import AdSlotPage from '../../presentation/pages/advertisement/adSlot';

const advertisementRoutes = [
  {
    path: '/advertisement',
    exact: true,
    view: () => <AdvertisementMainPage />
  },
  {
    path: '/advertisement/:adSlotId/:adSlotName',
    exact: true,
    view: () => <AdSlotPage />
  }
];

export default advertisementRoutes;
