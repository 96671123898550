import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Space } from 'antd';

import EmptyDisplay from '../../../layouts/EmptyDisplay';
import CustomerButton from '../../../layouts/CustomerButton';
// import CategoryCreateFirstDrawer from '../category/CategoryCreateFirstDrawer';
import CancelModal from '../../../layouts/CancelModal';
import { MenuOutlined } from '@ant-design/icons';
import '../../../../styles/components/DataTable.css';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const type = 'DraggableBodyRow';

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${ className }${ isOver ? dropClassName : '' }`}
      style={{ cursor: 'pointer', ...style }}
      {...restProps}
    />
  );
};

const CategoryDataTable = ({
  tableData,
  handleEditClick,
  handleDeleteClick,
  handleDragSort,
}) => {
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState(null);
  const [data, setData] = useState(tableData);

  const handleCancelModalOpen = id => {
    setSelectDeleteId(id);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };


  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const handleDrag = (id, newIndex) => {
    const params = {
      categoryId: id,
      sequence: newIndex
    };
    handleDragSort(params);
  };

  const toNextCategory = (id, name) => {
    window.location = `/cms/question/category/second/${ id }/${ name }`;
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columns = [
    {
      title: '排序',
      key: 'action',
      width: '7%',
      ellipsis: 'true',
      render() {
        return (
          <div>
            <MenuOutlined style={{ color: '#a9b6cb' }} />
          </div>
        );
      },
    },
    {
      title: '第一層分類',
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: '15%',
      ellipsis: 'true',
      render(text, record) {
        return (
          <Link
            style={{ color: '#0d1623' }}
            className='table-link'
            to={`second/${ record.id }/${ text }`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: '問題數量',
      dataIndex: 'questionCounts',
      key: 'questionCounts',
      width: '12%',
      ellipsis: 'true',
      sorter: (a, b) => Number(a.questionCounts) - Number(b.questionCounts)
    },
    {
      title: '建立時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '17%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '17%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      },
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    },
    {
      title: '瀏覽次數',
      dataIndex: 'views',
      key: 'views',
      width: '10%',
      ellipsis: 'true',
    },
    {
      title: '狀態',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '10%',
      ellipsis: 'true',
      render(text) {
        return (
          <div style={text ? { color: '#22a16f' } : { color: '#f45a4c' }}>
            {text ? '顯示' : '隱藏'}
          </div>
        );
      },
    },
    {
      title: '動作',
      key: 'action',
      width: '31%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={() => {
                handleCancelModalOpen(record.id);
              }}>
              刪除
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                handleEditClick(record.id, record.categoryName, record.isActive);
              }}>
              編輯
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                toNextCategory(record.id, record.categoryName);
              }}>
              分類設定
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = data[dragIndex];
      const categoryId = data[dragIndex].id;
      handleDrag(categoryId, hoverIndex);
      setData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const locale = {
    emptyText: <EmptyDisplay />
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Table
          locale={locale}
          columns={columns}
          dataSource={data}
          components={components}
          rowKey='id'
          pagination={false}
          className='table-container'
          onRow={(record, index) => ({
            index,
            moveRow,
            record
          })}/>
      </DndProvider>
      <CancelModal
        isOpen={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleChange={handleDelete}
      />
    </>
  );
};

export default CategoryDataTable;
