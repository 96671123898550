import React from 'react';
// import React, { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Tabs, Dropdown, Button, Menu } from 'antd';
import {
  EllipsisOutlined,
  CloseOutlined,
  MenuOutlined,
} from '@ant-design/icons';

import EditorToggleBlock from '../../../presentation/layouts/EditorToggleBlock';
import EditorBlock from '../../../presentation/layouts/EditorBlock';
import CreateProductQuestionTable from '../../components/product/CreateProductQuestionTable';
import CreateProductFileTable from '../../components/product/CreateProductFileTable';
import CreateGroupModal from '../../layouts/CreateGroupModal';
import CancelModal from '../../layouts/CancelModal';

const MultipleEditor = ({
  tabPaneList,
  activeTabKey,
  handleTabChange,
  handleDragEnd,
  handleAppendNewEditorIntoList,
  handleAppendNewToggleList,
  handleRemoveEditItem,
  handleOnEditEditor,
  handleOnEditToggle,
  questionList,
  attachmentList,
  handleFileUpload,
  isAddTabOpen,
  isEditTab,
  editTabKey,
  editTabName,
  handleAddTabSwitch,
  isDeleteModalOpen,
  onAddTab,
  handleEditTabSwitch,
  handleDeleteModalSwitch,
  handleTabEdit,
  onRemoveTab,
  deleteTabKey,
  isUploadDrawerOpen,
  handleUploadDrawerSwitch,
  handleDeleteFile,
  isTradingPlatform
}) => {
  const { TabPane } = Tabs;

  const editorMenu = (
    <Menu className='editor-menu'>
      <Menu.Item key={1}>
        <div
          onClick={() => {
            appendNewEditorIntoList();
          }}>
          圖文編輯器
        </div>
      </Menu.Item>
      <Menu.Item key={2}>
        <div
          onClick={() => {
            appendNewToggleList();
          }}>
          開合列表
        </div>
      </Menu.Item>
    </Menu>
  );

  const tabMenu = (tabKey, tabName) => {
    return (
      <Menu>
        <Menu.Item
          key='0'
          onClick={() => {
            onEditTabSwitch(tabKey, tabName);
          }}>
          <span style={{ cursor: 'pointer' }}>編輯</span>
        </Menu.Item>
        <Menu.Item
          key='1'
          onClick={() => {
            onDeleteModalSwitch(tabKey);
          }}>
          <span style={{ cursor: 'pointer' }}>刪除</span>
        </Menu.Item>
      </Menu>
    );
  };

  const onTabChange = activeKey => {
    handleTabChange(activeKey);
  };

  const onTabEdit = newTabName => {
    const newArray = [...tabPaneList];
    newArray.forEach(ele => {
      if (ele.key === editTabKey) {
        ele.title = newTabName;
      }
    });
    handleTabEdit(newArray);
  };

  const handleRemoveTab = () => {
    let newActiveKey = activeTabKey;
    let lastIndex;
    const newArray = tabPaneList;
    newArray.forEach((pane, i) => {
      if (pane.key === deleteTabKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = newArray.filter(pane => pane.key !== deleteTabKey);
    if (newPanes.length && newActiveKey === deleteTabKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    onRemoveTab(newPanes, newActiveKey);
  };

  const onEditTabSwitch = (tabKey, tabName) => {
    handleEditTabSwitch(tabKey, tabName);
  };

  const onDeleteModalSwitch = deleteTabKey => {
    handleDeleteModalSwitch(deleteTabKey);
  };

  const appendNewEditorIntoList = () => {
    const newArray = [...tabPaneList];
    newArray.forEach(ele => {
      if (ele.key.toString() === activeTabKey) {
        console.log('in');
        const data = ele.content;
        data.push({
          type: 'editor',
          content: {
            content: ' ',
          },
        });
      }
    });
    handleAppendNewEditorIntoList(newArray);
  };

  const appendNewToggleList = () => {
    const newArray = [...tabPaneList];
    newArray.forEach(ele => {
      if (ele.key.toString() === activeTabKey) {
        const data = ele.content;
        data.push({
          type: 'toggle',
          content: {
            title: ' ',
            content: ' ',
          },
        });
      }
    });
    handleAppendNewToggleList(newArray);
  };

  const removeEditItem = index => {
    const newArray = [...tabPaneList];
    let data;
    newArray.forEach(ele => {
      if (ele.key.toString() === activeTabKey) {
        data = [...ele.content];
        data.splice(index, 1);
        ele.content = data;
      }
    });
    handleRemoveEditItem(newArray);
  };

  const onEditEditor = (index, html) => {
    const newArray = [...tabPaneList];
    let data;
    newArray.forEach(ele => {
      if (ele.key.toString() === activeTabKey) {
        data = ele.content;
        data[index] = {
          type: 'editor',
          content: {
            content: html,
          },
        };
      }
    });
    handleOnEditEditor(newArray);
  };

  const onEditToggle = (index, toggleData) => {
    const newArray = [...tabPaneList];
    let data;
    newArray.forEach(ele => {
      if (ele.key.toString() === activeTabKey) {
        data = ele.content;
        data[index] = toggleData;
      }
    });
    handleOnEditToggle(newArray);
  };

  const onFileUpload = fileList => {
    handleFileUpload(fileList);
  };

  const onDragEnd = result => {
    handleDragEnd(result);
  };

  return (
    <>
      <Tabs
        hideAdd
        type='editable-card'
        onChange={onTabChange}
        activeKey={activeTabKey}>
        {tabPaneList.map(pane => (
          <TabPane
            tab={
              <div>
                <span>
                  {pane.title}{' '}
                  <Dropdown
                    trigger={['click']}
                    overlay={tabMenu(pane.key, pane.title)}>
                    <EllipsisOutlined style={{ marginLeft: '10px' }} />
                  </Dropdown>
                </span>
              </div>
            }
            key={pane.key}
            closable={false}>
            {/* tab pane content(editor) */}
            <section className='add-question-editor'>
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId='droppable' direction='vertical'>
                    {droppableProvided => (
                      <main
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}>
                        {pane.content.map((e, idx) => (
                          <Draggable
                            key={idx}
                            draggableId={`item-${ idx }`}
                            index={idx}>
                            {draggableProvided => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}>
                                <div className='editor-drag-nav'>
                                  <Button
                                    type='text'
                                    onClick={() => {
                                      removeEditItem(idx);
                                    }}>
                                    <CloseOutlined />
                                  </Button>
                                  <div {...draggableProvided.dragHandleProps}>
                                    <MenuOutlined />
                                  </div>
                                </div>
                                {e.type === 'toggle' ? (
                                  <EditorToggleBlock
                                    value={e.content}
                                    onEditToggle={data => {
                                      onEditToggle(idx, data);
                                    }}
                                  />
                                ) : (
                                  <EditorBlock
                                    value={e.content?.content}
                                    onEdit={html => {
                                      onEditEditor(idx, html);
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </main>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <Dropdown
                className='add-question-editor-drop'
                overlay={editorMenu}
                placement='bottomCenter'
                trigger={['click']}>
                <div className='editor-new-btn'>＋ 新增編輯選單</div>
              </Dropdown>
            </section>
          </TabPane>
        ))}
        <TabPane tab={'常見問題'} key={'qa'} closable={false}>
          <CreateProductQuestionTable list={questionList} />
        </TabPane>
        <TabPane tab={'相關下載'} key={'download'} closable={false}>
          <CreateProductFileTable
            fileList={attachmentList}
            onFileUpload={onFileUpload}
            isUploadDrawerOpen={isUploadDrawerOpen}
            handleUploadDrawerSwitch={handleUploadDrawerSwitch}
            handleDeleteFile={handleDeleteFile}
            isTradingPlatform={isTradingPlatform}
          />
        </TabPane>
      </Tabs>
      <CreateGroupModal
        isOpen={isAddTabOpen}
        isEdit={isEditTab}
        handleClose={handleAddTabSwitch}
        handleChange={onAddTab}
        handleUpdate={onTabEdit}
        existGroupName={editTabName}
        title={isEditTab ? '編輯分頁' : '新增分頁'}
        label={'分頁名稱'}
        placeholder={'請輸入分頁名稱'}
      />
      <CancelModal
        isOpen={isDeleteModalOpen}
        handleClose={handleDeleteModalSwitch}
        handleChange={handleRemoveTab}
      />
    </>
  );
};

export default MultipleEditor;
