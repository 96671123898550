import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';

import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import '../../../styles/pages/SitePage.css';

import GroupDataTable from '../../components/menu/usergroup/GroupDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import CreateGroupModal from '../../layouts/CreateGroupModal';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import {
  useCustomerGroup
} from '../../../application/menu/useCustomerGroup.js';

function UserGroup() {
  const dispatch = useDispatch();
  const { Content } = Layout;

  const {
    customerGroupList,
    getCustomerGroupList,
    createCustomerGroup,
    updateCustomerGroup,
    deleteCustomerGroup
  } = useCustomerGroup();

  const [isCreateGroupModalOpen, setCreateGroupModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editCustomerGroupName, setEditCustomerGroupName ] = useState('');
  const [editCustomerGroupId, setEditCustomerGroupId] = useState(null);

  useEffect(() => {

    dispatch(setBreadcrumb([
      {
        path: '',
        pageName: '選單管理'
      },
      {
        path: '/menu/group',
        pageName: '用戶群組管理'
      }
    ]));

  }, [dispatch]);

  useEffect(() => {
    if (!isCreateGroupModalOpen) {
      setEditCustomerGroupName('');
      setEditCustomerGroupId(null);
      setIsEdit(false);
    }
  }, [isCreateGroupModalOpen]);

  const handleCreateGroupModalOpen = () => {
    setCreateGroupModal(true);
  };

  const handleCreateGroupModalClose = () => {
    setCreateGroupModal(false);
  };

  const handleCreateNewGroup = async(newGroupName) => {
    const result = await createCustomerGroup(newGroupName);
    if (!result) {
      sendErrorMessage('新增失敗');
      return;
    }
    handleCreateGroupModalClose();
    getCustomerGroupList();
    sendSuccessMessage('新增成功');
  };

  const handleUpdate = async(newGroupName) => {
    const result = await updateCustomerGroup({ 
      id: editCustomerGroupId,
      newGroupName
    });
    if (!result) {
      sendErrorMessage('更新失敗');
      return;
    }
    handleCreateGroupModalClose();
    getCustomerGroupList();
    sendSuccessMessage('更新成功');
  };

  const handleDeleteClick = async(id) => {
    const result = await deleteCustomerGroup(id);
    if (!result) {
      sendErrorMessage('刪除失敗');
      return;
    }
    getCustomerGroupList();
    sendSuccessMessage('刪除成功');
  };

  const handleEditClick = (id, newGroupName) => {
    setIsEdit(true);
    setEditCustomerGroupName(newGroupName);
    setEditCustomerGroupId(id);
    handleCreateGroupModalOpen();
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>用戶群組管理</h1>
            <CustomerButton color='red' onClick={handleCreateGroupModalOpen}>
              新增用戶群組
            </CustomerButton>
          </div>
          <GroupDataTable
            tableData={ customerGroupList }
            handleEditClick={ handleEditClick }
            handleDeleteClick={ handleDeleteClick }
          />
        </div>
      </Content>
      <CreateGroupModal
        isOpen={ isCreateGroupModalOpen }
        isEdit={ isEdit }
        handleClose={ handleCreateGroupModalClose }
        handleChange={ handleCreateNewGroup }
        handleUpdate={ handleUpdate }
        existGroupName={ editCustomerGroupName }
        title={ isEdit ? '編輯用戶群組' : '新增用戶群組' }
        label={'群組名稱'}
        placeholder={'請輸入群組名稱'}
      />
    </Layout>
  );
}

export default UserGroup;
