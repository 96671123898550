import { getRequest, postRequest, putRequest, deleteRequest } from '../api';
import { errorHandle } from '../api/errorHandle.js';

/**
 * [requestPostFinancialProduct]
 * @return {object} [result]
 */
export async function requestPostFinancialProduct(params) {
  const url = '/api/introduction/financialProduct';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPutFinancialProduct]
 * @return {object} [result]
 */
export async function requestPutFinancialProduct(params) {
  const url = '/api/introduction/financialProduct';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeleteFinancialProduct]
 * @return {object} [result]
 */
export async function requestGetFinancialProductDetail(id) {
  const url = `/JSON/product/productCategory.json`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetFinancialProductDetail]
 * @return {object} [result]
 */
export async function requestDeleteFinancialProduct(id) {
  const params = {
    productId: id,
  };
  const url = '/api/introduction/financialProduct';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestFinancialProductCategory]
 * @return {object} [result]
 */
export async function requestFinancialProductCategory(id) {
  const url = `/JSON/product/productCategory.json`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
/**
 * [requestGetFinancialProductVerify]
 * @return {object} [result]
 */
export async function requestGetFinancialProductVerify(code) {
  const params = {
    productCode: code,
  };
  const url = `/api/introduction/verifyFinancialProduct/${ code }`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetCommonQuestionByKeyword]
 * @return {object} [result]
 */
export async function requestGetCommonQuestionByKeyword(keywords) {
  const params = {
    page: 1,
    pageSize: 1000,
    keywords,
  };
  // 前台的api
  const url = `${ process.env.REACT_APP_FRONT_API_BASE_URL }/commonQuestions`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url, params, true);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
