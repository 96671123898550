import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Table, Space, Tooltip } from 'antd';
import CustomerButton from '../../../layouts/CustomerButton';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
import EmptyDisplay from '../../../layouts/EmptyDisplay';
import '../../../../styles/components/DataTable.css';


const UserManageDataTable = ({ tableData, handleEditClick, handleDeleteClick }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [isCancelModalOpen, setCancelModal] = useState(false);
  // const [selectDeleteId, setSelectDeleteId ] = useState(null);
  const refSearchInput = useRef();

  const handleResetSearch = () => {
    window.location.reload();
  };

  const handleCancelModalOpen = (id) => {
    // setSelectDeleteId(id);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    // handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const textData = (data) => {
    let text = '';
    data.forEach((e, idx) => {
      if (idx > 0) {
        text = text + ', ' + e.groupName;
      } else {
        text = e.groupName;
      }
    });
    return text;
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '6%',
      ellipsis: 'true',
    },
    {
      title: '使用者名稱',
      dataIndex: 'staffName',
      key: 'staffName',
      width: '13%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'staffName',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
    },
    {
      title: '員編',
      dataIndex: 'staffNo',
      key: 'staffNo',
      width: '8%',
      ellipsis: 'true',
    },
    {
      title: '隸屬群組',
      dataIndex: 'groups',
      key: 'groups',
      width: '22%',
      ellipsis: 'true',
      render(record) {
        return (
          <Tooltip placement="bottom" title={ textData(record) }>
            <span style={{ cursor: 'pointer' }}>
              {
                record.map((e, index) => (
                  <div key={ index } className='dataTable-tag'>
                    { e.groupName }
                  </div>
                ))
              }
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: '最後登入時間',
      dataIndex: 'lastLoginTime',
      key: 'lastLoginTime',
      width: '14%',
      ellipsis: 'true',
      render(data) {
        return data ? moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss') : '';
      }
    },
    {
      title: '建立時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '14%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      }
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '14%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      }
    },
    {
      title: '狀態',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '7%',
      ellipsis: 'true',
      render(text) {
        return (
          <span
            style={
              text ? { color: '#22a16f' } : { color: '#f45a4c' }
            }>
            { text ? '有效' : '無效'}
          </span>
        );
      },
    },
    {
      title: '動作',
      key: 'action',
      width: '20%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={ () => { handleCancelModalOpen(record.id); } }
            >
              刪除
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={ () => { handleEditClick(record.id); } }
            >
              編輯
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  const locale = {
    emptyText: <EmptyDisplay handleReload={ handleResetSearch } isNeedReloadBtn={ tableData.length > 0 } />
  };

  return (
    <>
      <Table
        locale={ locale }
        columns={ columns }
        dataSource={ tableData }
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={ isCancelModalOpen }
        handleClose={ handleCancelModalClose }
        handleChange={ handleDelete }
      />
    </>
  );
};

export default UserManageDataTable;
