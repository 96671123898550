import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
// import { LoadingOutlined } from '@ant-design/icons';

import CustomerButton from '../../../layouts/CustomerButton';

import '../../../../styles/layouts/SubListModal.css';

const ListSubModal = ({ isOpen, handleClose, data }) => {
  // if (!data) {
  //   return <LoadingOutlined />;
  // }

  const checkStatus = status => {
    switch (status) {
      case 'published':
        return <span style={{ color: '#22a16f' }}>已發布</span>;
      case 'unpublished':
        return <span style={{ color: '#f45a4c' }}>排程中</span>;
      case 'scheduling':
        return <span style={{ color: '#f45a4c' }}>未發布</span>;
      case 'draft':
        return <span style={{ color: '#254a91' }}>草稿</span>;
      default:
        return;
    }
  };

  return (
    <Modal
      className='sub-table-modal'
      centered
      visible={isOpen}
      title='詳細列表'
      onCancel={handleClose}
      width={520}
      destroyOnClose
      footer={[
        <CustomerButton key='submit' color='blue' onClick={handleClose}>
          確認
        </CustomerButton>,
      ]}>
      <table className='sub-table'>
        <tbody className='sub-table-tbody'>
          <tr>
            <td>標題</td>
            <td>{data.title ? data.title : ''}</td>
          </tr>
          <tr>
            <td>第一層分類</td>
            <td>{data.category ? data.category.categoryName : ''}</td>
          </tr>
          <tr>
            <td>第二層分類</td>
            <td>{data.category2nd ? data.category2nd.categoryName : ''}</td>
          </tr>
          <tr>
            <td>第三層分類</td>
            <td>{data.category3rd ? data.category3rd.categoryName : ''}</td>
          </tr>
          <tr>
            <td>關鍵字</td>
            <td>
              {data.keywords ? data.keywords : ''}
            </td>
          </tr>
          <tr>
            <td>設為精選</td>
            <td>{data.isFeatured ? '是' : '否'}</td>
          </tr>
          <tr>
            <td>設為置頂</td>
            <td>{data.isTop ? '是' : '否'}</td>
          </tr>
          <tr>
            <td>建立時間</td>
              <td>{moment(new Date(data.createdAt)).format('YYYY/MM/DD HH:mm:ss')}</td>
          </tr>

          <tr>
            <td>發布時間</td>
            <td>{data.publishDate ? moment(new Date(data.publishDate)).format('YYYY/MM/DD HH:mm:ss') : '無'}</td>
          </tr>
          <tr>
            <td>更新時間</td>
            <td>{data.updatedAt ? moment(new Date(data.updatedAt)).format('YYYY/MM/DD HH:mm:ss') : '無'}</td>
          </tr>
          <tr>
            <td>瀏覽次數</td>
            <td>{data.views}</td>
          </tr>
          <tr>
            <td>狀態</td>
            <td>{checkStatus(data.status)}</td>
          </tr>
          <tr>
            <td>正向點擊</td>
            <td>{data.like}</td>
          </tr>
          <tr>
            <td>反向點擊</td>
            <td>{data.dislike}</td>
          </tr>
          <tr>
            <td>上傳附件</td>
            <td>{data.attachments && data.attachments.length ? data.attachments.filename : '無' }</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default ListSubModal;
