import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Table, Space } from 'antd';
import CustomerButton from '../../../layouts/CustomerButton';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
import EmptyDisplay from '../../../layouts/EmptyDisplay';
import '../../../../styles/components/DataTable.css';


const FunctionQuestionDataTable = ({ detailsData, handleEditClick, handleDeleteClick }) => {

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId ] = useState(null);
  const refSearchInput = useRef();

  const handleResetSearch = () => {
    window.location.reload();
  };

  const handleCancelModalOpen = (id) => {
    setSelectDeleteId(id);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
      ellipsis: 'true',
    },
    {
      title: '權限群組名稱',
      dataIndex: 'permissionName',
      key: 'permissionName',
      width: '20%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'name',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
    },
    {
      title: '建立時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      }
    },
    {
      title: '更新日期',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '15%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      }
    },
    {
      title: '狀態',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '10%',
      ellipsis: 'true',
      render(text) {
        return (
          <span
            style={
              text ? { color: '#22a16f' } : { color: '#f45a4c' }
            }>
            {text ? '有效' : '無效'}
          </span>
        );
      },
    },
    {
      title: '最後更新者',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      width: '10%',
      ellipsis: 'true'
    },
    {
      title: '動作',
      key: 'action',
      width: '20%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={() => {
                handleCancelModalOpen(record.id);
              }}>
              刪除
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={ 
                () => { handleEditClick(record.id); } 
              }
            >
              編輯
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  const locale = {
    emptyText: <EmptyDisplay handleReload={ handleResetSearch } isNeedReloadBtn={ detailsData.length > 0 } />
  };

  return (
    <>
      <Table
        locale={ locale }
        columns={columns}
        dataSource={ detailsData }
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={ isCancelModalOpen }
        handleClose={ handleCancelModalClose }
        handleChange={ handleDelete }
      />
    </>
  );
};

export default FunctionQuestionDataTable;
