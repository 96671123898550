import React, { useState, useRef } from 'react';
import { Table, Space } from 'antd';
import CustomerButton from '../../../layouts/CustomerButton';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
import EmptyDisplay from '../../../layouts/EmptyDisplay';
import '../../../../styles/components/DataTable.css';


const UserGroupListDataTable = ({ tableData, handleDeleteClick }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState(null);
  const refSearchInput = useRef();

  const handleResetSearch = () => {
    window.location.reload();
  };

  const handleCancelModalOpen = id => {
    setSelectDeleteId(id);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '8%',
      ellipsis: 'true',
    },
    {
      title: '身分證字號',
      dataIndex: 'userId',
      key: 'userId',
      width: '82%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'userId',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      ),
    },
    {
      title: '動作',
      key: 'action',
      width: '10%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={ () => {
                handleCancelModalOpen(record.userId);
              } }>
              刪除
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  const locale = {
    emptyText: <EmptyDisplay handleReload={ handleResetSearch } isNeedReloadBtn={ tableData.length > 0 } />
  };

  return (
    <>
      <Table
        locale={ locale }
        columns={ columns }
        dataSource={ tableData }
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={isCancelModalOpen}
        handleClose={handleCancelModalClose}
        handleChange={handleDelete}
      />
    </>
  );
};

export default UserGroupListDataTable;
