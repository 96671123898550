import { getRequest, postRequest, putRequest, deleteRequest } from '../api';
import { errorHandle } from '../api/errorHandle';

/**
 * [requestGetAd]
 * @return {object} [result]
 */
 export async function requestGetAd(adId) {
  // const url = `/api/notification/ad/${ adId }`;
  const url = `/JSON/advertisement/adSlotData.json`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}


/**
 * [requestPostAd]
 * @return {object} [result]
 */
 export async function requestPostAd(params) {
  const url = '/api/notification/ad';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPutAd]
 * @return {object} [result]
 */
 export async function requestPutAd(params) {
  const url = '/api/notification/ad';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetAd]
 * @return {object} [result]
 */
 export async function requestDeleteAd(id) {
  const params = {
    adId: id,
  };
  const url = '/api/notification/ad';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}