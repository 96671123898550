import { useState, useEffect } from 'react';
import { requestMenuSettings } from '../../services/menu/menuService.js';

export function useMenu() {
  const [menuList, setMenuList] = useState([]);

  useEffect(() => { 
    getMenuList();
  }, []);

  const getMenuList = async() => {
    const listData = await requestMenuSettings();
    if (listData) {
      setMenuList(listData.data.menuList);
    }
  };

  return {
    menuList,
    getMenuList
  };
}
