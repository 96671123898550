import { useState, useEffect } from 'react';
import { requestGetQuestionCategories, requestPostCategory, requestDeleteCategory, requestPutCategory } from '../../services/questions/firstCategoryService';

export function useRequestFirstQuestionCategory() {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    getQuestionCategories();
  }, []);

 // -----first-----

  const getQuestionCategories = async() => {
    const listData = await requestGetQuestionCategories();
    if (listData.status) {
      setCategoryList(listData.data.data);
    }
  };

  const postQuestionCategory = async(params) => {
    const res = await requestPostCategory(params);
    if (res.status) {
      return true;
    }
  };

  const putRequestPutCategory = async(params) => {
    const res = await requestPutCategory(params);
    if (res.status) {
      return true;
    }
  };

  const delRequestDeleteCategory = async(id) => {
    const res = await requestDeleteCategory(id);
    if (res.status) {
      return true;
    } else {
      return res.data.message;
    }
  };


  return {
    categoryList,
    getQuestionCategories,
    postQuestionCategory,
    delRequestDeleteCategory,
    putRequestPutCategory
  };
}