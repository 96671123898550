import axios from 'axios';
import cookies from 'js-cookie';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const adminToken = 'elktree-demo-key';

export function getRequest(apiRoute, bodyParams) {
  const authToken = cookies.get(adminToken);

  let params;
  if (!bodyParams) {
    params = null;
  } else {
    params = bodyParams;
  }
  const config = {
    headers: {
      token: authToken,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    params
  };

  return axios.get(apiRoute, config);
}

export function postRequest(apiRoute, bodyParams) {
  // const authToken = cookies.get(adminToken);
  const url = baseUrl + apiRoute;
  const config = {
    headers: {
      // token: authToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  };
  let params;
  if (!bodyParams) {
    params = {};
  } else {
    params = bodyParams;
  }
  return axios.post(url, params, config);
}

export function putRequest(apiRoute, bodyParams) {
  const authToken = cookies.get(adminToken);
  const url = baseUrl + apiRoute;
  const config = {
    headers: {
      token: authToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  };
  let params;
  if (!bodyParams) {
    params = {};
  } else {
    params = bodyParams;
  }
  return axios.put(url, params, config);
}

export function deleteRequest(apiRoute, bodyParams) {
  const authToken = cookies.get(adminToken);
  const url = baseUrl + apiRoute;
  let data;
  if (!bodyParams) {
    data = null;
  } else {
    data = bodyParams;
  }
  const config = {
    headers: {
      token: authToken,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data
  };
  return axios.delete(url, config);
}

export function uploadRequest(apiRoute, bodyParams) {
  const authToken = cookies.get(adminToken);
  const url = baseUrl + apiRoute;
  const config = {
    headers: {
      token: authToken,
      'Content-Type': 'multipart/form-data',
    }
  };
  let params;
  if (!bodyParams) {
    params = {};
  } else {
    params = bodyParams;
  }
  return axios.post(url, params, config);
}

