import { useState, useEffect } from 'react';
import { 
  requestGetPermissionFunctionList,
  requestCreateFunction,
  requestUpdateFunction,
  requestDeleteFunction
} from '../../services/permission/permissionFuctionService.js';

export function usePermissionFunction() {
  const [permissionFunctionsList, setPermissionFunctionsList] = useState([]);



  useEffect(() => {
    getFunctionsList();
  }, []);

  const getFunctionsList = async() => {
    const listData = await requestGetPermissionFunctionList();
    if (listData.status) {
      setPermissionFunctionsList(listData.data.data);
    }
  };

  const createFunction = async(newFunctionGroupName) => {
    const result = await requestCreateFunction(newFunctionGroupName);
      if (result.status) {
        return true;
      } else {
        return false;
      }
  };

  const updateFunction = async({ id, newFunctionGroupName }) => {
    const result = await requestUpdateFunction(id, newFunctionGroupName);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const deleteFunction = async(id) => {
    const result = await requestDeleteFunction(id);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };
  
  return {
    permissionFunctionsList,
    getFunctionsList,
    createFunction,
    updateFunction,
    deleteFunction
  };
}
