import { 
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

export async function requestGetCustomerGroupList() {
  const url = '/api/menu/permissionGroups';
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestCreateNewGroup(userGroupName) {
  const url = '/api/menu/permissionGroup';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, {
      groupName: userGroupName
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestUpdateCustomersGroup({ id, updateName }) {
  const url = '/api/menu/permissionGroup';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, {
      groupId: id,
      groupName: updateName
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestDeleteCustomerGroup(id) {
  const url = '/api/menu/permissionGroup';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, {
      groupId: id
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestGetCustomersList(groupId) {
  const url = `/api/menu/permissionGroup/${ groupId }`;
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result.members;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestUpdateCustomer({ groupId, addMembers = [], delMembers = [] }) {
  const url = '/api/menu/permissionGroup';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, {
      groupId,
      addMembers,
      delMembers
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
