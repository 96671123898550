/**
 * User Domain
 * export define function
 */

/**
 * [description]
 * @param  { any } loginData [input data]
 * @return { object }        [{ account: string, password: string }]
 */
export const defineUserLogin = (loginData) => {
  return {
    staffNo: loginData?.staffNo,
    password: loginData?.password
  };
};
