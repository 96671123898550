import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space } from 'antd';
// import { Drawer, Form, Tree, Space } from 'antd';
// import { CheckOutlined } from '@ant-design/icons';

// import { useFunctionsMenu } from '../../../../application/permission/useFunctionGroup.js';
import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import CustomerSwitch from '../../../layouts/CustomerSwitch';
import CustomerSearchInput from '../../../layouts/CustomerSearchInput.js';
import { inputValidator } from '../../../../application/validator/index.js';
// import { inputValidator } from '../../../../services/validotor/index.js';

import '../../../../styles/components/DrawerForm.css';

const UserGroupManegeDrawerForm = ({
  isOpen,
  userData,
  userGroupPermission,
  groupName,
  handleClose,
  handleSearchUser,
  isSearchLoading,
  handleSave,
  enterBtnText,
  form,
}) => {
  const { requiredValidator, symbolValidator } = inputValidator();

  // const [checkedKeys, setCheckedKeys] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const [userName, setUserName] = useState('');
  // const [selectGroups, setSelectGroups] = useState([]);
  const [searchUserId, setSearchUserId] = useState(0);

  // const { functionsMenu } = useFunctionsMenu();

  useEffect(() => {
    if (!isOpen) {
      setUserName('');
      setIsActive(true);
      setSearchUserId(0);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (userGroupPermission.length > 0) {
  //     setCheckedKeys(userGroupPermission);
  //   }
  // }, [userGroupPermission]);

  useEffect(() => {
    if (userData.staffName) {
      setUserName(userData.staffName);
    } else {
      setUserName('');
    }

    if (userData.id) {
      setSearchUserId(userData.id);
    }

    setIsActive(userData.isActive);

    if (userData?.groups && userData?.groups.length > 0) {
      const groups = [];
      userData.groups.forEach(e => {
        groups.push(e.groupName);
      });
      // setSelectGroups(groups);
    }
  }, [userData]);

  // const onCheck = checkedKeysValue => {
  //   const inputCheck = checkedKeysValue.filter(e => typeof e === 'number');
  //   console.log('inputCheck', inputCheck);
  //   setCheckedKeys(inputCheck);
  // };

  const handleClickToSave = () => {
    handleSave({
      userId: searchUserId,
      userName,
    });
  };

  const handleSwitchChange = checked => {
    setIsActive(checked);
  };

  return (
    <>
      <Drawer
        title='加入使用者'
        width={700}
        onClose={handleClose}
        visible={isOpen}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleClose}>取消</CustomerButton>
            <CustomerButton
              onClick={handleClickToSave}
              htmlType='submit'
              form='function-user-group-manager-drawer'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='function-user-group-manager-drawer'
          className='usergroup-form-group'
          layout='horizontal'
          wrapperCol={{ span: 22 }}
          labelCol={{ span: 6 }}
          initialValues={{
            staffNo: '',
            staffName: '',
            groups: [],
            isActive: isActive,
          }}>
          <Form.Item
            label='員工編號'
            name='staffNo'
            rules={[requiredValidator('員工編號'), symbolValidator()]}>
            <CustomerSearchInput
              placeholder='請輸入員工編號'
              onSearch={handleSearchUser}
              loading={isSearchLoading}
              enterButton={enterBtnText}
              allowClear
            />
          </Form.Item>

          <Form.Item label='使用者名稱' name='staffName'>
            <CustomerInput placeholder='使用者名稱' disabled={true} />
          </Form.Item>

          <Form.Item label='隸屬群組'>
            <CustomerInput
              disabled
              placeholder='隸屬群組'
              value={groupName}
            />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive'>
              <CustomerSwitch
                disabled
                onChange={handleSwitchChange}
                checked={isActive}
              />
            </Form.Item>
            <span>{isActive ? '有效' : '無效'}</span>
          </Form.Item>

          {/* <Form.Item label='功能權限設定' className='drawer-tree-section'>
            <Tree
              treeData={functionsMenu}
              showLine={true}
              checkable
              disabled
              checkedKeys={checkedKeys}
              onCheck={onCheck}></Tree>
          </Form.Item> */}
        </Form>
      </Drawer>
    </>
  );
};

export default UserGroupManegeDrawerForm;
