import React from 'react';
import { Drawer, Form, Space } from 'antd';
import { useParams } from 'react-router';

import { inputValidator } from '../../../../application/validator';
import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import CustomerSwitch from '../../../layouts/CustomerSwitch';

import '../../../../styles/components/DrawerForm.css';

const CategoryCreateSecondDrawerForm = ({
  isEdit,
  isOpen,
  handleClose,
  handleEdit,
  handleSave,
  selectId,
  form,
}) => {
  const { sName, sIdx } = useParams();
  const { requiredValidator, symbolValidator } = inputValidator();

  const handleClickToSave = value => {
    delete value['category1stName'];
    isEdit
      ? handleEdit({ ...value, category2ndId: selectId })
      : handleSave({ ...value, categoryId: sIdx });
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯第二層分類' : '新增第二層分類'}
        width={700}
        onClose={handleClose}
        visible={isOpen}
        footer={
          <Space>
            <CustomerButton onClick={handleClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              form='category2nd-form'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='category2nd-form'
          onFinish={handleClickToSave}
          layout='horizontal'
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 6 }}
          initialValues={{
            category1stName: sName,
            categoryName: '',
            isActive: false,
          }}>
          <Form.Item label='第一層分類名稱' name='category1stName'>
            <CustomerInput disabled={true} />
          </Form.Item>

          <Form.Item
            label='第二層分類名稱'
            name='categoryName'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput placeholder='請輸入第二層分類名稱' />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive' valuePropName='checked'>
              <CustomerSwitch />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isActive');
                return <span>{isActive ? '顯示' : '隱藏'}</span>;
              }}
            </Form.Item>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default CategoryCreateSecondDrawerForm;
