import React from 'react';
import { Layout } from 'antd';
import '../../styles/pages/Home.css';
import '../../styles/pages/SitePage.css';

const Welcome = () => {
  const { Content } = Layout;
  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>Welcome!!</h1>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default Welcome;
