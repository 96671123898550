export const HELLO_WORLD = 'HELLO_WORLD';

export const OPEN_LOGOUT_MODAL = 'OPEN_LOGOUT_MODAL';

export const SEND_SUCCESS_MESSAGE = 'SEND_SUCCESS_MESSAGE';
export const SEND_ERROR_MESSAGE = 'SEND_ERROR_MESSAGE';

export const SET_BREAD_CRUMB = 'SET_BREAD_CRUMB';

export const INPUT_VALIDATOR_ERROR_MARK = 'INPUT_VALIDATOR_ERROR_MARK';
export const INPUT_VALIDATOR_ERROR_EMPTY = 'INPUT_VALIDATOR_ERROR_EMPTY';
export const INPUT_VALIDATOR_ERROR_REQUIRED = 'INPUT_VALIDATOR_ERROR_REQUIRED';
export const INPUT_VALIDATOR_SUCCESS = 'INPUT_VALIDATOR_SUCCESS';
