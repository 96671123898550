import React, { useState, useEffect } from 'react';
import { Layout, Tabs, Form } from 'antd';

import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import MenuDataTable from '../../components/menu/function/MenuDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import MenuDrawerForm from '../../components/menu/function/MunuDrawer';
import FunctionDrawerForm from '../../components/menu/function/FunctionDrawer';
import FunctionDataTable from '../../components/menu/function/FunctionDataTable';

import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import { useWebMenu } from '../../../application/menu/useWebMenu';
import { useWebFunctions } from '../../../application/menu/useWebFunctions';

import '../../../styles/pages/SitePage.css';

function MenuFunction() {
  const dispatch = useDispatch();

  const { Content } = Layout;
  const { TabPane } = Tabs;
  const [menuForm, fnForm] = Form.useForm();

  const {
    webMenuList,
    getWebMenuList,
    createNewMenu,
    updateMenu,
    deleteMenu,
    getWebMenuSingleDetails,
  } = useWebMenu();
  const {
    webFunctionsList,
    getWebFunctionsList,
    getWebFunctionsSubList,
    createNewWebFunction,
    updateFunction,
    deleteFunction,
  } = useWebFunctions();

  const [isEdit, setIsEdit] = useState(false);
  const [menuEditData, setMenuEditData] = useState({});
  const [isMenuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const [isFunctionDrawerOpen, setFunctionDrawerOpen] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [tabsKey, setTabsKey] = useState('1');

  const [functionDetails, setFunctionDetails] = useState();
  const [functionEditData, setFunctionEditData] = useState({});

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '選單管理',
        },
        {
          path: '/menu/function',
          pageName: '選單功能管理',
        },
      ])
    );
  }, [dispatch]);

  const handleMenuDrawerOpen = () => {
    setMenuDrawerOpen(true);
  };

  const handleMenuDrawerClose = () => {
    setMenuDrawerOpen(false);
    menuForm.resetFields();
    setIsEdit(false);
  };

  const handleFunctionDrawerOpen = () => {
    setFunctionDrawerOpen(true);
  };

  const handleFunctionDrawerClose = () => {
    setFunctionDrawerOpen(false);
    setIsEdit(false);
  };

  const handleChangeTab = value => {
    setTabsKey(value);
  };

  const handleSetIsOnlyAddFunction = () => {
    menuForm.setFieldsValue({
      hierarchy: 1
    });
  };

  const handleSaveMenu = async data => {
    const result = await createNewMenu(data);
    if (!result) {
      sendErrorMessage('新增失敗');
      return;
    }
    handleMenuDrawerClose();
    getWebMenuList();
    sendSuccessMessage('新增成功');
  };

  const handleUpdateMenu = async data => {
    console.log(data);
    const body = {
      groupId: selectId,
      ...data,
    };
    const result = await updateMenu(body);
    if (!result) {
      sendErrorMessage('更新失敗');
      return;
    }
    handleMenuDrawerClose();
    getWebMenuList();
    sendSuccessMessage('更新成功');
    setIsEdit(false);
  };

  const handleEditMenuClick = async id => {
    const data = await getWebMenuSingleDetails(id);
    setSelectId(id);
    if (data) {
      setIsEdit(true);
      setMenuEditData(data);
      menuForm.setFieldsValue({
        title: data.title,
        isOnlyAddFunction: data.isOnlyAddFunction,
        needLoginDisplay: data.needLoginDisplay ? 0 : 1,
        hierarchy: data.hierarchy,
        remark: data.remark,
      });
    }
    handleMenuDrawerOpen();
  };

  const handleDeleteMenuClick = async id => {
    const result = await deleteMenu(id);
    if (!result) {
      sendErrorMessage('刪除失敗');
      return;
    }
    handleMenuDrawerClose();
    getWebMenuList();
    sendSuccessMessage('刪除成功');
  };

  const handleSaveFunction = async data => {
    const result = await createNewWebFunction(data);
    if (!result) {
      sendErrorMessage('新增失敗');
      return;
    }
    handleFunctionDrawerClose();
    getWebFunctionsList();
    sendSuccessMessage('新增成功');
  };

  const handleClickSelectFunctionDetail = async id => {
    const data = await getWebFunctionsSubList(id);
    setFunctionDetails(data);
  };

  const handleEditFunctionClick = async id => {
    const data = await getWebFunctionsSubList(id);
    setSelectId(id);
    if (data) {
      setFunctionEditData(data);
      setIsEdit(true);
    }
    handleFunctionDrawerOpen();
  };

  const handleEditFunction = async data => {
    const result = await updateFunction({
      functionId: selectId,
      ...data,
    });
    if (!result) {
      sendErrorMessage('更新失敗');
      return;
    }
    handleFunctionDrawerClose();
    getWebFunctionsList();
    sendSuccessMessage('更新成功');
  };

  const handleDeleteFunctionClick = async id => {
    const result = await deleteFunction(id);
    if (!result) {
      sendErrorMessage('刪除失敗');
      return;
    }
    handleFunctionDrawerClose();
    getWebFunctionsList();
    sendSuccessMessage('刪除成功');
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>選單功能管理</h1>
          </div>
          <div>
            <Tabs
              onChange={handleChangeTab}
              defaultActiveKey='1'
              tabBarExtraContent={
                tabsKey === '1' ? (
                  <CustomerButton
                    color='red'
                    onClick={handleMenuDrawerOpen}
                    className='sitebar-menu-button'>
                    新增選單
                  </CustomerButton>
                ) : (
                  <CustomerButton
                    color='red'
                    onClick={handleFunctionDrawerOpen}
                    className='sitebar-menu-button'>
                    新增功能
                  </CustomerButton>
                )
              }>
              <TabPane tab='選單列表' key='1'>
                <MenuDataTable
                  tableData={webMenuList}
                  handleEditClick={handleEditMenuClick}
                  handleDeleteClick={handleDeleteMenuClick}
                />
              </TabPane>
              <TabPane tab='功能列表' key='2'>
                <FunctionDataTable
                  tableData={webFunctionsList}
                  handleClickSelectFunctionDetail={
                    handleClickSelectFunctionDetail
                  }
                  functionDetails={functionDetails}
                  handleDeleteClick={handleDeleteFunctionClick}
                  handleEditClick={handleEditFunctionClick}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Content>
      <MenuDrawerForm
        isEdit={isEdit}
        editData={menuEditData}
        isOpen={isMenuDrawerOpen}
        handleClose={handleMenuDrawerClose}
        handleSave={handleSaveMenu}
        handleUpdate={handleUpdateMenu}
        handleSetIsOnlyAddFunction={handleSetIsOnlyAddFunction}
        form={menuForm}
      />
      <FunctionDrawerForm
        isEdit={isEdit}
        editData={functionEditData}
        isOpen={isFunctionDrawerOpen}
        handleClose={handleFunctionDrawerClose}
        handleSave={handleSaveFunction}
        handleEdit={handleEditFunction}
        form={fnForm}
      />
    </Layout>
  );
}

export default MenuFunction;
