import React, { useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import CustomerButton from '../../layouts/CustomerButton';
import { InfoCircleFilled } from '@ant-design/icons';
import EmptyDisplay from '../../layouts/EmptyDisplay';
import '../../../styles/components/CreateProductFileTable.css';
import UploadFileDrawer from './UploadFileDrawer';

function CreateProductFileTable({ fileList, handleUploadDrawerSwitch, isUploadDrawerOpen, onFileUpload, handleDeleteFile, isTradingPlatform }) {
  const { Content } = Layout;
  const [newFileList, setNewFileList] = useState(fileList); 

  useEffect(() => {
    setNewFileList(fileList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  // const handleRemoveFile = (displayName) => {
  //   handleDeleteFile(displayName);
  // };

  const columns = [
    {
      title: '檔案名稱',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '90%',
      ellipsis: 'true',
    },
    {
      title: '動作',
      key: 'delete',
      width: '10%',
      render(record) {
        return (
            <CustomerButton
              color='red'
              onClick={() => {
                handleDeleteFile(record.filename);
              }}>
              刪除
            </CustomerButton>
        );
      },
    },
  ];

  const locale = {
    emptyText: <EmptyDisplay />
  };

  return (
    <Layout>
      <Content>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>相關下載列表</h1>
            <div className='upload-area'>
              <p>
                <InfoCircleFilled />
                {
                  isTradingPlatform ?
                  '支援上傳的檔案格式：pdf、zip，檔案大小限制：10MB以內。' :
                  '支援上傳的檔案格式：pdf、zip，檔案大小限制：2MB以內。'
                }
              </p>
              <CustomerButton color='red' onClick={handleUploadDrawerSwitch}>
                新增檔案
              </CustomerButton>
            </div>
          </div>
          <Table
            locale={locale}
            columns={columns}
            dataSource={newFileList}
            rowKey='uid'
            pagination={false}
          />
        </div>
      </Content>
      <UploadFileDrawer
        isUploadDrawerOpen={isUploadDrawerOpen}
        handleUploadDrawerSwitch={handleUploadDrawerSwitch}
        onFileUpload={onFileUpload}
      />
    </Layout>
  );
}

export default CreateProductFileTable;