/* eslint-disable */
import React from 'react';
import SignIn from '../../presentation/pages/users/Signin.js';
import UI from '../../presentation/pages/ui.js';

const usersRoutes = [
  {
    path: '/users/signin',
    exact: true,
    view: () => <SignIn />
  },
  {
    path: '/users/ui',
    exact: true,
    view: () => <UI />
  }
];

export default usersRoutes;
