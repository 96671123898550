export const customers = {
  title: 'customers',
  type: 'object',
  required: ['userId'],
  properties: {
    userId: {
      type: 'string',
      pattern: '^[A-Z]{1}[1-2]{1}[0-9]{8}$'
    }
  }
};
