import { useState, } from 'react';
import { requestPost3rdCategory, requestGet2ndQuestionCategoriesWithId, requestPut3rdCategory, requestDelete3rdCategory } from '../../services/questions/thirdCategoryService';

export function useRequestThirdQuestionCategory() {
  const [thirdCategoryList, setThirdCategoryList] = useState('');

  // -----third-----

  const postThirdQuestionCategory = async(params) => {
    const res = await requestPost3rdCategory(params);
    if (res.status) {
      return true;
    }
  };

  const getSecondAndThirdQuestionCategories = async(id) => {
    const res = await requestGet2ndQuestionCategoriesWithId(id);
    const resData = res.data.data;
    let category3rd;
    if (res.status) {
      resData.forEach(first=>{
        first.category2nd.forEach(second=>{
          if(second.id === id) {
            category3rd = second.category3rd;
          }
        })
      })
      setThirdCategoryList(category3rd);
    } else {
      setThirdCategoryList('');
    }
  };

  const putRequestPutThirdCategory = async(params) => {
    const res = await requestPut3rdCategory(params);
    if (res.status) {
      return true;
    }
  };

  const delRequestDeleteThirdCategory = async(id) => {
    const res = await requestDelete3rdCategory(id);
    if (res.status) {
      return true;
    } else {
      return res.data.message;
    }
  };

  return {
    postThirdQuestionCategory,
    thirdCategoryList,
    getSecondAndThirdQuestionCategories,
    putRequestPutThirdCategory,
    delRequestDeleteThirdCategory
  };
}