import { getRequest, postRequest, putRequest, deleteRequest } from '../api';
import { errorHandle } from '../api/errorHandle';

/**
 * [requestGetProductCategories]
 * @return {object} [result]
 */
export async function requestGetProductCategories() {
  const url = '/JSON/product/productCategory.json';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (error) {
    result.data = errorHandle(error);
  }
  return result;
}

/**
 * [requestPostProductCategories]
 * @return {object} [result]
 */
export async function requestPostProductCategories(params) {
  const url = '/api/introduction/financialProductCategory';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (error) {
    result.data = errorHandle(error);
  }
  return result;
}

/**
 * [requestPutProductCategories]
 * @return {object} [result]
 */
export async function requestPutProductCategories(params) {
  const url = '/api/introduction/financialProductCategory';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeleteProductCategories]
 * @return {object} [result]
 */
export async function requestDeleteProductCategories(id) {
  const url = '/api/introduction/financialProductCategory';
  const params = {
    categoryId: id,
  };
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestVerifyProductCategoryCode]
 * @return {object} [result]
 */
export async function requestVerifyProductCategoryCode(code) {
  const url = `/api/introduction/verifyFinancialProductCategory/${ code }`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
