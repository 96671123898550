import React from 'react';
import { Layout } from 'antd';
import '../../styles/pages/404.css';
import '../../styles/pages/Home.css';

const FourOFour = () => {
  const { Content } = Layout;
  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='four-o-four-group'>
            <h3 className="four-o-four-404">404</h3>
            <h3 className="four-o-four-description">This page could not be found</h3>
          </div>
        </div>
      </Content>
    </Layout>
    
  );
};

export default FourOFour;
