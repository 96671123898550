import React from 'react';
import { Checkbox } from 'antd';

import '../../styles/layouts/CustomerCheckBox.css';

const CustomerCheckBox = ({ onChange, checked, children, ...props }) => {
  return <Checkbox checked={ checked } onChange={onChange} {...props}>{children}</Checkbox>;
};

export default CustomerCheckBox;
