import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

/**
 * [requestGetUsersGroupList]
 * @return {object} [result]
 */
export async function requestGetUsersGroupList() {
  const url = '/JSON/permission/userGroup.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestSingleUserGroup]
 * @param  {number} groupId [group ID]
 * @return {object}         [result]
 */
export async function requestSingleUserGroup(groupId) {
  const url = '/JSON/permission/userGroup.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestCreateNewUsersGroup]
 * @param  {string} options.groupName  [group name]
 * @param  {boolean} options.isActive  [status  is active or not]
 * @return {object}                    [result]
 */
export async function requestCreateNewUsersGroup({
  groupName,
  isActive,
  permissions
}) {
  const url = '/api/auth/userGroup';

  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, {
      groupName,
      isActive,
      permissions
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestUpdateUserGroup description]
 * @param  {number} options.groupId      [group ID]
 * @param  {string} options.groupName    [group name]
 * @param  {boolean} options.isActive    [status is active or not]
 * @param  {Array} options.users         [users ID array]
 * @param  {Array} options.permissions   [permissions ID array]
 * @return {object}                      [result]
 */
export async function requestUpdateUserGroup({
  groupId,
  groupName,
  isActive,
  users,
  permissions
}) {
  const url = '/api/auth/userGroup';
   const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, {
      groupId,
      groupName,
      isActive,
      users,
      permissions
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeleteUserGroup]
 * @param  {number} id            [ID]
 * @return {object}               [result]
 */
export async function requestDeleteUserGroup(id) {
  const url = '/api/auth/userGroup';
  const result = {
    status: false,
    data: null
  };
  const body = {
    groupId: id
  };
  try {
    if (!id) {
      throw Error('no id');
    }
    result.data = await deleteRequest(url, body);
    result.status = true;
  } catch (err) {
    if (err.toString() === 'Error: no id') {
      result.data = err.toString();
    } else {
      result.data = errorHandle(err);
    }
  }
  return result;
}
