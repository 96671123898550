import React from 'react';
import { Switch } from 'antd';
import '../../styles/layouts/CustomerSwitch.css';

const CustomerSwitch = ({ checked, onChange, ...props }) => {
  return (
    <div className='ant-switch-container'>
      <Switch
        {...props}
        checked={checked}
        onChange={onChange}
        style={{ marginRight: 16 }}
      />
    </div>
  );
};

export default CustomerSwitch;
