import React from 'react';
// import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import '../../styles/layouts/CustomerInput.css';

const CustomerInput = ({
  onChange,
  disabled,
  value,
  defaultValue,
  allowClear,
  textArea,
  rows,
  className,
  ...props
}) => {

  const { TextArea } = Input;

  if (textArea) {
    return (
      <TextArea
        rows={rows}
        onChange={onChange}
        className={`customer-input ${ className }`}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        {...props}
      />
    );
  }

  return (
      <Input
        allowClear={allowClear}
        onChange={onChange}
        className={`customer-input ${ className }`}
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        size='large'
        {...props}
      />
  );
};

export default CustomerInput;
