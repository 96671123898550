import cookies from 'js-cookie';
import store from '../../store';
import {
  setLogoutModalOpen
} from '../../store/actions/message.js';

export const errorHandle = (err) => {
  const errorCode = err.response.status;
  if (errorCode === 401) {
    store.dispatch(setLogoutModalOpen());
    cookies.remove('elktree-demo-key');
    return {
      message: err.response.data.message,
      status: err.response.status
    };
  } else if (errorCode === 403) {
    return {
      message: err.response.data.message,
      status: err.response.status
    };
  } else {
    return {
      message: err.response.data.message,
      status: err.response.status ? err.response.status : err.toString()
    };
  }
};
