/* eslint-disable */
import React from 'react';
import Home from '../../presentation/pages/Home.js';
import Welcome from '../../presentation/pages/Welcome.js';

const homeRoutes = [
  {
    path: '/',
    exact: true,
    view: () => <Home />
  },
  {
    path: '/welcome',
    exact: true,
    view: () => <Welcome />
  }
];

export default homeRoutes;
