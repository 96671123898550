import React from 'react';
import { DatePicker } from 'antd';
import '../../styles/layouts/CustomerTimePicker.css';

const CustomerTimePicker = ({ value, disabled, onChange, placeholder, ...props }) => {
  return (
    <DatePicker
      value={ value }
      onChange={ onChange }
      disabled={ disabled }
      placeholder= { placeholder }
      {...props}
    />
  );
};

export default CustomerTimePicker;
