import React from 'react';
import GitInfo from 'react-git-info/macro';
import { Redirect } from 'react-router-dom';
import { Form, Input } from 'antd';
import { sendSuccessMessage } from '../../layouts/Message';
import CustomerInput from '../../layouts/CustomerInput';
import {
  useUserLogin,
  useCheckUserToken,
} from '../../../application/users/useUserLogin.js';

import '../../../styles/pages/Signin.css';
import CustomerButton from '../../layouts/CustomerButton';

function UsersSignin() {
  const gitInfo = GitInfo();
  const isToken = useCheckUserToken();
  const { login } = useUserLogin();

  if (isToken) {
    return <Redirect to={'/welcome'} push={true} />;
  }

  const onFinish = async formData => {
    await login(formData);
    sendSuccessMessage('登入成功');
    window.location = '/cms/welcome';
  };

  return (
    <div className='signin'>
      <nav>
        <div>
          <img
            src='./images/signin/logo.png'
            alt=''
          />
          <p>{gitInfo.commit.shortHash}</p>
        </div>
      </nav>
      <div className='login-form'>
        <h1>麋鹿樹後台管理系統</h1>
        <h4>
          僅供DEMO使用，請輸入任意數字帳號密碼登入
        </h4>
        <Form
          name='basic'
          initialValues={{
            remember: true,
          }}
          className=''
          onFinish={onFinish}
          autoComplete='off'>
          <Form.Item
            className='input_item'
            name='staffNo'
            rules={[
              {
                required: true,
                message: '請輸入正確的員工編號。',
                pattern: new RegExp(/^[0-9]+$/),
              },
            ]}>
            <CustomerInput placeholder='請輸入員工編號' className='input' />
          </Form.Item>

          <Form.Item
            className='input_item'
            name='password'
            rules={[
              {
                required: true,
                message: '請輸入正確密碼。',
              },
            ]}>
            <Input.Password
              placeholder='請輸入密碼'
              className='input-password'
              size='large'
            />
          </Form.Item>

          <Form.Item>
            <CustomerButton color='red' htmlType='submit' className='login-btn'>
              登入
            </CustomerButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default UsersSignin;
