import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

/**
 * [requestGetPermissionFunctionList]
 * @return {object} [result]
 */
export async function requestGetPermissionFunctionList() {
  const url = '/JSON/permission/permissionFunctionList.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }

  return result;
}

/**
 * [requestCreateFunction]
 * @param  {string} newFunctionGroupName [new function group name]
 * @return {object}                      [result]
 */
export async function requestCreateFunction(newFunctionGroupName) {
  const url = '/api/auth/permissionGroup';
  const result = {
    status: false,
    data: null
  };
  const body = {
    groupName: newFunctionGroupName
  };
  try {
    if (!newFunctionGroupName) {
      throw Error('no newFunctionGroupName');
    }
    result.data = await postRequest(url, body);
    result.status = true;
  } catch (err) {
    if (err.toString() === 'Error: no newFunctionGroupName') {
      result.data = err.toString();
    } else {
      result.data = errorHandle(err);
    }
    
  }
  return result;
}

/**
 * [requestUpdateFunction]
 * @param  {number} id                   [group ID]
 * @param  {string} newFunctionGroupName [new function group name]
 * @return {object}                      [result]
 */
export async function requestUpdateFunction(id, newFunctionGroupName) {
  const url = '/api/auth/permissionGroup';
  const result = {
    status: false,
    data: null
  };
  const body = {
    groupId: id,
    groupName: newFunctionGroupName
  };
  try {
    if (!newFunctionGroupName || !id) {
      throw Error('no newFunctionGroupName or no id');
    }
    result.data = await putRequest(url, body);
    result.status = true;
  } catch (err) {
    if (err.toString() === 'Error: no newFunctionGroupName or no id') {
      result.data = err.toString();
    } else {
      result.data = errorHandle(err);
    }
  }
  return result;
}

/**
 * [requestDeleteFunction]
 * @param  {number} id [group ID]
 * @return {object}    [result]
 */
export async function requestDeleteFunction(id) {
  const url = '/api/auth/permissionGroup';
  const result = {
    status: false,
    data: null
  };
  const body = {
    groupId: id
  };
  try {
    if (!id) {
      throw Error('no id');
    }
    result.data = await deleteRequest(url, body);
    result.status = true;
  } catch (err) {
    if (err.toString() === 'Error: no id') {
      result.data = err.toString();
    } else {
      result.data = errorHandle(err);
    }
  }
  return result;
}

