import { useState, useEffect } from 'react';
// import Ajv from 'ajv';
// import { webFunctions } from '../../domain/menu/webFunctions';
import {
  requestGetWebFunctionsList,
  requestGetWebFunctionsSubList,
  requestCreateNewFunction,
  requestUpdateFunction,
  requestDeleteFunction
} from '../../services/menu/webFunctionsService';

export function useWebFunctions() {
  const [webFunctionsList, setWebFunctionsList] = useState([]);

  // const ajv = new Ajv();
  // const validate = ajv.compile(webFunctions);

  useEffect(() => {
    getWebFunctionsList();
  }, []);

  const getWebFunctionsList = async() => {
    const listData = await requestGetWebFunctionsList();
    if (listData.status) {
      setWebFunctionsList(listData.data);
    }
  };

  const getWebFunctionsSubList = async(id) => {
    const listData = await requestGetWebFunctionsSubList(id);
    if(listData.status) {
      return listData.data;
    }
  };

  const createNewWebFunction = async(params
  //   {
  //   functionTitle,
  //   functionIcon,
  //   isNeedTrust,
  //   remark,
  //   url,
  //   trustUrl,
  //   trustBody,
  //   openFunctionMethod,
  //   openWidth,
  //   openHeight,
  //   isOpenFullUrl,
  //   isOpenPermission,
  //   isNeedToLogin,
  //   isLoginShow,
  //   isActive,
  //   permissionGroups,
  //   permissionUsers
    
  // }
  ) => {
    // const trustObject = {};
    // trustBody.map((e) => {
    //   trustObject[e.key] = e.value;
    // });
    // const requestBody = {
    //   title: functionTitle,
    //   icon: functionIcon,
    //   isTrust: isNeedTrust,
    //   remark,
    //   url,
    //   trustUrl,
    //   trustBody: trustObject,
    //   isBlank: openFunctionMethod === 'isBlank',
    //   isOpen: openFunctionMethod === 'isOpen',
    //   openWidth: Number(openWidth),
    //   openHeight: Number(openHeight),
    //   isFullUrl: isOpenFullUrl,
    //   hasPermission: isOpenPermission,
    //   needLogin: isNeedToLogin,
    //   needLoginDisplay: isLoginShow,
    //   isActive,
    //   permissionGroups,
    //   permissionUsers
    // };
    // const valid = validate(requestBody);
    // if (!valid) {
    //   console.log(validate.errors);
    //   return false;
    // }
    const result = await requestCreateNewFunction(params);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  async function updateFunction(params
  //   {
  //   functionId,
  //   functionTitle,
  //   functionIcon,
  //   isNeedTrust,
  //   remark,
  //   url,
  //   trustUrl,
  //   trustBody,
  //   openFunctionMethod,
  //   openWidth,
  //   openHeight,
  //   isOpenFullUrl,
  //   isOpenPermission,
  //   isNeedToLogin,
  //   isLoginShow,
  //   isActive,
  //   permissionGroups,
  //   permissionUsers
  // }
  ) {
    // const trustObject = {};
    // trustBody.map((e) => {
    //   trustObject[e.key] = e.value;
    // });
    // const requestBody = {
    //   functionId,
    //   title: functionTitle,
    //   icon: functionIcon,
    //   isTrust: isNeedTrust,
    //   remark,
    //   url,
    //   trustUrl,
    //   trustBody: trustObject,
    //   isBlank: openFunctionMethod === 'isBlank',
    //   isOpen: openFunctionMethod === 'isOpen',
    //   openWidth: Number(openWidth),
    //   openHeight: Number(openHeight),
    //   isFullUrl: isOpenFullUrl,
    //   hasPermission: isOpenPermission,
    //   needLogin: isNeedToLogin,
    //   needLoginDisplay: isLoginShow,
    //   isActive,
    //   permissionGroups,
    //   permissionUsers
    // };
    // const valid = validate(requestBody);
    // if (!valid) {
    //   console.log(validate.errors);
    //   return false;
    // }
    const result = await requestUpdateFunction(params);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  }

  const deleteFunction = async(id) => {
    const result = await requestDeleteFunction(id);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    webFunctionsList,
    getWebFunctionsList,
    getWebFunctionsSubList,
    createNewWebFunction,
    updateFunction,
    deleteFunction
  };
}
