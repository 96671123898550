import { getRequest, postRequest, putRequest, deleteRequest } from '../api';
import { errorHandle } from '../api/errorHandle.js';

/**
 * [requestPostTradePlatform]
 * @return {object} [result]
 */
export async function requestPostTradePlatform(params) {
  const url = '/api/introduction/tradingApp';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPutTradePlatform]
 * @return {object} [result]
 */
export async function requestPutTradePlatform(params) {
  const url = '/api/introduction/tradingApp';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetTradePlatformDetail]
 * @return {object} [result]
 */
export async function requestGetTradePlatformDetail(id) {
  const url = `/api/introduction/tradingApp/${ id }`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetTradingAppVerify]
 * @return {object} [result]
 */
export async function requestGetTradingAppVerify(code) {
  const params = {
    appCode: code,
  };
  const url = `/api/introduction/verifyTradingApp/${ code }`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetCommonQuestionByKeyword]
 * @return {object} [result]
 */
export async function requestGetCommonQuestionByKeyword(keywords) {
  const params = {
    page: 1,
    pageSize: 1000,
    keywords,
  };
  // 前台的api
  const url = `${ process.env.REACT_APP_FRONT_API_BASE_URL }/commonQuestions`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url, params, true);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestGetTradePlatformCategory]
 * @return {object} [result]
 */
 export async function requestGetTradePlatformCategory(id) {
  const url = `/api/introduction/tradingAppCategory/${ id }`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeleteTradeTradingApp]
 * @return {object} [result]
 */
 export async function requestDeleteTradeTradingApp(id) {
  const params = {
    appId: id,
  };
  const url = '/api/introduction/tradingApp';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestFinancialProductList]
 * @return {object} [result]
 */
export async function requestFinancialProductList() {
  const params = {
    isShowProducts: 'true'
  };
  const url = '/api/introduction/financialProductCategories';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}