import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import CustomerButton from '../../layouts/CustomerButton';
import CategoryCreateThirdDrawerForm from '../../components/question/category/CategoryCreateThirdDrawer';
import ThirdCategoryTable from '../../components/question/category/CategoryThirdDataTable';
import { useRequestThirdQuestionCategory } from '../../../application/questions/useRequestThirdQuestionCategory';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import '../../../styles/pages/SitePage.css';

function ThirdCategory() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const { sIdx, sName, tIdx, tName } = useParams();
  const [form] = Form.useForm();
  
  const { getSecondAndThirdQuestionCategories, thirdCategoryList, postThirdQuestionCategory, putRequestPutThirdCategory, delRequestDeleteThirdCategory } = useRequestThirdQuestionCategory();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [selectId, setSelectId] = useState();
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {

    dispatch(setBreadcrumb([
      {
        path: '',
        pageName: '常見問題管理'
      },
      {
        path: '/question/category/first',
        pageName: '分類列表'
      },
      {
        path: `/question/category/second/${ sIdx }/${ sName }`,
        pageName: `${ sName }`
      },
      {
        path: `/question/category/second/${ sIdx }/${ sName }/${ tIdx }/${ tName }`,
        pageName: `${ tName }`
      }
    ]));

  }, [dispatch, sIdx, sName, tIdx, tName]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  const handleSave = async params => {
    const result = await postThirdQuestionCategory(params);
    if (!result) {
      sendErrorMessage('新增失敗');
    } else {
      sendSuccessMessage('新增成功');
      getSecondAndThirdQuestionCategories(tIdx);
      handleDrawerClose();
    }
  };

  const handleDeleteThird = async(id) => {
    const result = await delRequestDeleteThirdCategory(id);
    if (result !== true) {
      sendErrorMessage(result);
    } else {
      sendSuccessMessage('刪除成功');
      getSecondAndThirdQuestionCategories(tIdx);
    }
  };

  const handleEdit = async params => {
    const result = await putRequestPutThirdCategory(params);
    if (!result) {
      sendErrorMessage('編輯失敗');
    } else {
      sendSuccessMessage('編輯成功');
      getSecondAndThirdQuestionCategories(tIdx);
      handleDrawerClose();
    }
  };

  const handleEditClick = (id, categoryName, isActive) => {
    form.setFieldsValue({
      category1stName: sName,
      category2ndName: tName,
      categoryName: categoryName,
      isActive: isActive,
    });
    setSelectId(id);
    setIsEdit(true);
    handleDrawerOpen();
  };

  useEffect(() => {
    getSecondAndThirdQuestionCategories(tIdx);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>{tName}</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增第三層
            </CustomerButton>
          </div>
          <ThirdCategoryTable
            tableData={thirdCategoryList}
            grandCategory={sName}
            parentCategory={tName}
            handleDeleteClick={handleDeleteThird}
            handleEditClick={handleEditClick}
          />
        </div>
      </Content>
      <CategoryCreateThirdDrawerForm
        handleClose={handleDrawerClose}
        handleSave={handleSave}
        handleEdit={handleEdit}
        isOpen={isDrawerOpen}
        isEdit={isEdit}
        selectId={selectId}
        form={form}
      />
    </Layout>
  );
}

export default ThirdCategory;
