import { useState } from 'react';
import { requestGetQuestionCategoriesWithId, requestPost2ndCategory, requestPut2ndCategory, requestDelete2ndCategory } from '../../services/questions/secondCategoryService';

export function useRequestSecondQuestionCategory() {
  const [secondCategoryList, setSecondCategoryList] = useState('');

 // -----second-----

  const postSecondQuestionCategory = async(params) => {
    const res = await requestPost2ndCategory(params);
    if (res.status) {
      return true;
    }
  };

  const getFirstAndSecondQuestionCategories = async(id) => {
    const listData = await requestGetQuestionCategoriesWithId();
    if (listData.status) {
      const index = listData.data.data.findIndex(first => first.id === id);
      setSecondCategoryList(listData.data.data[index].category2nd);
      // setSecondCategoryList(listData.data.data.result.category2nd);
    }
    return listData;
  };

  const putRequestPutSecondCategory = async(params) => {
    console.log('pram', params);
    const res = await requestPut2ndCategory(params);
    if (res.status) {
      return true;
    }
  };

  const delRequestDeleteSecondCategory = async(id) => {
    const res = await requestDelete2ndCategory(id);
    if (res.status) {
      return true;
    } else {
      return res.data.message;
    }
  };


  return {
    getFirstAndSecondQuestionCategories,
    secondCategoryList,
    postSecondQuestionCategory,
    putRequestPutSecondCategory,
    delRequestDeleteSecondCategory
  };
}