import { requestGetAd, requestPostAd, requestPutAd, requestDeleteAd } from '../../services/advertisement/adService';

export function useRequestAd() {
  const getAd = async (adId, adSlotId) => {
    const res = await requestGetAd(adId);
    if (res.status) {
      const slotIndex = res.data.data.findIndex(i => i.id === Number(adSlotId));
      const slotData = res.data.data[slotIndex];
      if(slotData) {
        const adIndex = slotData.ads.findIndex(i => i.id === Number(adId));
        const adData = slotData.ads[adIndex];
        return adData;
      }
    }
  };

  const postAd = async params => {
    const res = await requestPostAd(params);
    if (res.status) {
      return true;
    }
  };

  const putAd = async params => {
    const res = await requestPutAd(params);
    if (res.status) {
      return true;
    }
  };

  const deleteAd = async id => {
    const res = await requestDeleteAd(id);
    if (res.status) {
      return true;
    }
  };

  return {
    getAd,
    postAd,
    putAd,
    deleteAd,
  };
}
