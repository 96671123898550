/* eslint-disable */
import React from 'react';
import CreateProduct from '../../presentation/pages/product/createProduct';
import ProductMainPage from '../../presentation/pages/product';
import ProductCategoryList from '../../presentation/pages/product/productCategoryList';

const productRoutes = [
  {
    path: '/product/:categoryId/:categoryName/create',
    exact: true,
    view: () => <CreateProduct />,
  },
  {
    path: '/product/:categoryId/:categoryName/:productId/edit',
    exact: true,
    view: () => <CreateProduct />,
  },
  {
    path: '/product',
    exact: true,
    view: () => <ProductMainPage />,
  },
  {
    path: '/product/:id/:name',
    exact: true,
    view: () => <ProductCategoryList />,
  },
];

export default productRoutes;
