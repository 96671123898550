// import { useState, useEffect } from 'react';
// import { requestUserLogin } from '../../services/user/userService.js';
import { defineUserLogin } from '../../domain/users';
import cookies from 'js-cookie';

export function useUserLogin() {

  const login = async(formData) => {
    const { staffNo } = defineUserLogin(formData);
    // const loginData = await requestUserLogin({
    //   staffNo: staffNo,
    //   password: password
    // });

    cookies.set('elktree-demo-key', staffNo, { expires: 1 });
    cookies.set('elktree-demo-staffNo', staffNo, { expires: 1 });
  };

  const logout = () => {
    let result;
    if (cookies.get('elktree-demo-key')) {
      cookies.remove('elktree-demo-key');
      cookies.remove('elktree-demo-staffNo');
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  return {
    login,
    logout
  };
}

export function useCheckUserToken() {
  return cookies.get('elktree-demo-key');
}

export function useCheckIsInSignInPage() {
  return window.location.pathname === '/cms/users/signin';
}

export function useUserName() {
  const staffNo = cookies.get('elktree-demo-staffNo');
  return {
    staffNo,
    userName: 'user01'
  }
}

