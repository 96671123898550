import { getRequest, postRequest, deleteRequest, putRequest } from '../api';
import { errorHandle } from '../api/errorHandle';

/**
 * [requestGetTradingAppCategories]
 * @return {object} [result]
 */
export async function requestGetTradingAppCategories() {
  const url = '/api/introduction/tradingAppCategories';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (error) {
    result.data = errorHandle(error);
  }
  return result;
}

/**
 * [requestPostTradingAppCategory]
 * @return {object} [result]
 */
 export async function requestPostTradingAppCategory(params) {
  const url = '/api/introduction/tradingAppCategory';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (error) {
    result.data = errorHandle(error);
  }
  return result;
}

/**
 * [requestVerifyTradingAppCategoryCode]
 * @return {object} [result]
 */
 export async function requestVerifyTradingAppCategoryCode(categoryCode) {
  const url = `/api/introduction/verifyTradingAppCategory/${ categoryCode }`;
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (error) {
    result.data = errorHandle(error);
  }
  return result;
}

/**
 * [requestDeleteTradingAppCategory]
 * @return {object} [result]
 */
 export async function requestDeleteTradingAppCategory(id) {
  const params = {
    categoryId: id,
  };
  const url = '/api/introduction/tradingAppCategory';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}


/**
 * [requestPutTradingAppCategory]
 * @return {object} [result]
 */
 export async function requestPutTradingAppCategory(params) {
  const url = '/api/introduction/tradingAppCategory';
  const result = {
    status: false,
    data: null,
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}