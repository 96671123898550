import React, { useState } from 'react';
import { Drawer, Form, Space } from 'antd';

import '../../../../styles/components/DrawerForm.css';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerSelect from '../../../layouts/CustomerSelect';

const options = [
  {
    id: '選單',
    title: '選單'
  },
  {
    id: '功能',
    title: '功能'
  }
];


const PlatformManageDrawerForm = ({ isOpen, isSingle, menuOptions, handleClose, handleSave }) => {
  const [type, setType] = useState('');
  const [isMenuSelectShow, setMenuSelectShow] = useState(false);
  // const [isFunctionSelectShow, setFunctionSelectShow] = useState(false);

  const [menuName, setMenuName] = useState('');

  const handleClickToSave = () => {
    handleSave({
      type: type === '選單' ? 'menu' : 'function',
      value: menuName
    });
    setType(null);
    setMenuName(null);
    handleClose();
  };

  const handleTypeChange = value => {
    if (value === '選單') {
      setType(value);
      // setFunctionSelectShow(false);
      setMenuSelectShow(true);
    }

    if (value === '功能') {
      setType(value);
      // setFunctionSelectShow(true);
      setMenuSelectShow(false);
    }
  };

  const handleMenuNameChange = value => {
    setMenuName(value);
  };

  return (
    <>
      <Drawer
        title='加入選單'
        width={700}
        onClose={handleClose}
        visible={isOpen}
        footer={
          <Space>
            <CustomerButton onClick={handleClose}>取消</CustomerButton>
            <CustomerButton
              onClick={handleClickToSave}
              type='primary'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          className='form-group'
          layout='horizontal'
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 6 }}>
          <Form.Item label='選擇屬性'>
            <CustomerSelect
              options={ 
                options.filter((e) => { 
                  return isSingle ? e.id !== '功能' : e;
                }) 
              }
              placeholder='請選擇'
              onChange={handleTypeChange}
              selectedValue={type}
            />
          </Form.Item>

          {
            isMenuSelectShow && (
              <Form.Item label='選單名稱'>
                <CustomerSelect
                  isSearch
                  options={ menuOptions.filter(e => e.hierarchy === 1) }
                  placeholder='請選擇'
                  onChange={handleMenuNameChange}
                  selectedValue={menuName}
                />
              </Form.Item>
            )
          }

          { /* isFunctionSelectShow && (
            <Form.Item label='功能名稱'>
              <CustomerSelect
                options={ menuOptions }
                placeholder='請選擇'
                // onChange={handleTypeChange}
              />
            </Form.Item>
          ) */ }
        </Form>
      </Drawer>
    </>
  );
};

export default PlatformManageDrawerForm;
