// import React, { useState, useEffect, useCallback } from 'react';
import React, { useState, useEffect } from 'react';
import { Layout, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import CustomerButton from '../../layouts/CustomerButton';
// import CategoryDataTable from '../../components/question/category/CategoryFirstDataTable';
// import CategoryCreateFirstDrawerForm from '../../components/question/category/CategoryCreateFirstDrawer';
import ListQuestionDataTable from '../../components/question/list/ListQuestionDataTable';
import { useRequestQuestion } from '../../../application/questions/useRequestQuestion';
import { useRequestAllCategory } from '../../../application/questions/useRequestAllCategory';
import { useQuestionFilterHandle } from '../../../application/questions/useQuestionFilterHandle';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import '../../../styles/pages/SitePage.css';
import '../../../styles/components/QuestionList.css';
import { Link } from 'react-router-dom';

function QuestionList() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const { TabPane } = Tabs;
  
  const { getCommonQuestionList, questionList, getCommonQuestionDetail, putQuestion, delQuestion } = useRequestQuestion();
  const { getQuestionCategories, getFirstAndSecondQuestionCategories, getSecondAndThirdQuestionCategories } = useRequestAllCategory();
  const { handleCategoryFilterTitle } = useQuestionFilterHandle();
  const [currentTabKey, setCurrentTabKey] = useState('all');
  const [questionDetail, setQuestionDetail] = useState({});
  const [firstFilterTitle, setFirstFilterTitle] = useState([]);
  const [firstFilterSelect, setFirstFilterSelect] = useState('');
  const [secondFilterTitle, setSecondFilterTitle] = useState([]);
  const [secondFilterSelect, setSecondFilterSelect] = useState('');
  const [thirdFilterTitle, setThirdFilterTitle] = useState([]);


  useEffect(() => {

    dispatch(setBreadcrumb([
      {
        path: '',
        pageName: '常見問題管理'
      },
      {
        path: '/question/list',
        pageName: '問題列表'
      }
    ]));

  }, [dispatch]);


  const handleFilterTitle = async(categoryLayer, id) => {
    let firstList, first, secondList, second, thirdList, third;
    // let list;
    switch(categoryLayer) {
      case 'first':
        firstList = await getQuestionCategories();
        first = handleCategoryFilterTitle(firstList);
        setFirstFilterTitle(first);
      break;
      case 'second':
        secondList = await getFirstAndSecondQuestionCategories(id);
        second = handleCategoryFilterTitle(secondList);
        setSecondFilterTitle(second);
      break;
      case 'third':
        thirdList = await getSecondAndThirdQuestionCategories(id);
        third = handleCategoryFilterTitle(thirdList);
        setThirdFilterTitle(third);
      break;
      default:
        return;
    }
  };

  useEffect(() => {
    handleFilterTitle('first');
  // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if(firstFilterSelect) {
      handleFilterTitle('second', firstFilterSelect);
    }
  // eslint-disable-next-line
  }, [firstFilterSelect]);

  useEffect(() => {
    if(secondFilterSelect) {
      handleFilterTitle('third', secondFilterSelect);
    }
  // eslint-disable-next-line
  }, [secondFilterSelect]);


  // const [isDrawerOpen, setDrawerOpen] = useState(false);

  // const handleDrawerOpen = () => {
  //   setDrawerOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setDrawerOpen(false);
  // };

  // eslint-disable-next-line
  const handleChangeTab = (tabKey) => {
    setCurrentTabKey(tabKey);
    if(tabKey === 'all') {
      getCommonQuestionList();
    } else {
      getCommonQuestionList(tabKey);
    }
  };

  const handleDelete = async(id) => {
    const result = await delQuestion(id);
    if (!result) {
      sendErrorMessage('刪除失敗');
    } else {
      sendSuccessMessage('刪除成功');
        if(currentTabKey === 'all') {
          getCommonQuestionList();
        } else {
          getCommonQuestionList(currentTabKey);
        }
    }
  };

  const handleFeatureToggle = async(params) => {
    const result = await putQuestion(params);
    if (!result) {
      sendErrorMessage('變更精選失敗');
      return;
    }
    sendSuccessMessage('變更精選成功');
    if(currentTabKey === 'all') {
      getCommonQuestionList();
    } else {
      getCommonQuestionList(currentTabKey);
    }
  };

  const handleCancelPublished = async(params) => {
    const result = await putQuestion(params);
    if (!result) {
      sendErrorMessage('取消發佈失敗');
      return;
    }
    sendSuccessMessage('取消發佈成功');
    if(currentTabKey === 'all') {
      getCommonQuestionList();
    } else {
      getCommonQuestionList(currentTabKey);
    }
  };

  const handleGetDetail = async(id) => {
    const result = await getCommonQuestionDetail(id);
    if (result) {
      setQuestionDetail(result);
      return;
    } else {
      sendErrorMessage('取得詳細資料失敗');
    }
  };

  const handleSelectFirstFilter = (categoryName) => {
    const found = firstFilterTitle.find(e => e.text === categoryName);
    setFirstFilterSelect(found.id);
  };

  const handleSelectSecondFilter = (categoryName) => {
    const found = secondFilterTitle.find(e => e.text === categoryName);
    setSecondFilterSelect(found.id);
  };


  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>問題列表</h1>
          </div>
          <div>
            <Tabs
              defaultActiveKey='1'
              onChange={handleChangeTab}
              tabBarExtraContent={
                <CustomerButton color='red' className='sitebar-menu-button'>
                  <Link to='list/add'>新增問題 </Link>
                </CustomerButton>
              }>
              <TabPane tab='所有問題' key='all'>
              { currentTabKey === 'all' ?
              <ListQuestionDataTable handleCancelPublished={handleCancelPublished} tableData={questionList} firstFilter={firstFilterTitle} secondFilter={secondFilterTitle} thirdFilter={thirdFilterTitle} handleDeleteClick={handleDelete} handleSelectFirstFilter={ handleSelectFirstFilter } handleSelectSecondFilter={ handleSelectSecondFilter } handleFeatureToggle={ handleFeatureToggle } handleGetDetail={handleGetDetail} questionDetail={questionDetail} currentKeys={currentTabKey}/>
                : '' } </TabPane>
              <TabPane tab='已發布' key='published'>
                { currentTabKey === 'published' ?
              <ListQuestionDataTable handleCancelPublished={handleCancelPublished} tableData={questionList} firstFilter={firstFilterTitle} secondFilter={secondFilterTitle} thirdFilter={thirdFilterTitle} handleDeleteClick={handleDelete} handleSelectFirstFilter={ handleSelectFirstFilter } handleSelectSecondFilter={ handleSelectSecondFilter } handleFeatureToggle={ handleFeatureToggle } handleGetDetail={handleGetDetail} questionDetail={questionDetail} currentKeys={currentTabKey}/>
                : '' } </TabPane>
              <TabPane tab='未發布' key='unpublished'>
                { currentTabKey === 'unpublished' ?
              <ListQuestionDataTable handleCancelPublished={handleCancelPublished} tableData={questionList} firstFilter={firstFilterTitle} secondFilter={secondFilterTitle} thirdFilter={thirdFilterTitle} handleDeleteClick={handleDelete} handleSelectFirstFilter={ handleSelectFirstFilter } handleSelectSecondFilter={ handleSelectSecondFilter } handleFeatureToggle={ handleFeatureToggle } handleGetDetail={handleGetDetail} questionDetail={questionDetail} currentKeys={currentTabKey}/>
                : '' } </TabPane>
              <TabPane tab='排程中' key='scheduling'>
                { currentTabKey === 'scheduling' ?
              <ListQuestionDataTable handleCancelPublished={handleCancelPublished} tableData={questionList} firstFilter={firstFilterTitle} secondFilter={secondFilterTitle} thirdFilter={thirdFilterTitle} handleDeleteClick={handleDelete} handleSelectFirstFilter={ handleSelectFirstFilter } handleSelectSecondFilter={ handleSelectSecondFilter } handleFeatureToggle={ handleFeatureToggle } handleGetDetail={handleGetDetail} questionDetail={questionDetail} currentKeys={currentTabKey}/>
                : '' } </TabPane>
              <TabPane tab='草稿' key='draft'>
                { currentTabKey === 'draft' ?
              <ListQuestionDataTable handleCancelPublished={handleCancelPublished} tableData={questionList} firstFilter={firstFilterTitle} secondFilter={secondFilterTitle} thirdFilter={thirdFilterTitle} handleDeleteClick={handleDelete} handleSelectFirstFilter={ handleSelectFirstFilter } handleSelectSecondFilter={ handleSelectSecondFilter } handleFeatureToggle={ handleFeatureToggle } handleGetDetail={handleGetDetail} questionDetail={questionDetail} currentKeys={currentTabKey}/>
                : '' } </TabPane>
              </Tabs>
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default QuestionList;
