import React from 'react';
import { Modal, Tabs, Table, Collapse } from 'antd';
import { PlusSquareFilled, CloseSquareFilled } from '@ant-design/icons';
import CustomerButton from '../../layouts/CustomerButton';
import parse from 'html-react-parser';
import '../../../styles/components/ProductPreviewModal.css';

const ProductPreviewModal = ({
  isOpen,
  handleClose,
  fileList,
  tabPaneList,
  questionList,
}) => {
  const { TabPane } = Tabs;
  const { Panel } = Collapse;

  const qaColumns = [
    {
      title: '項目',
      key: 'index',
      width: '10%',
      ellipsis: 'true',
      align: 'center',
      render(text, row, index) {
        return (
          <p style={{ color: '#DAA360', textAlign: 'center', margin: 0 }}>{index + 1}</p>
        );
      },
    },
    {
      title: '標題',
      dataIndex: 'title',
      key: 'title',
      width: '90%',
      ellipsis: 'true',
    },
  ];

  const fileColumns = [
    {
      title: '項目',
      dataIndex: 'index',
      key: 'index',
      width: '10%',
      ellipsis: 'true',
      render(text, row, index) {
        return (
          <p style={{ color: '#DAA360', textAlign: 'center' }}>{index + 1}</p>
        );
      },
    },
    {
      title: '檔案名稱',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '75%',
      ellipsis: 'true',
    },
    {
      title: '下載',
      dataIndex: 'url',
      key: 'url',
      width: '15%',
      render() {
        return <CustomerButton color='red'>下載</CustomerButton>;
      },
    },
  ];

  return (
    <Modal
      className='question-preview-modal'
      centered
      visible={isOpen}
      onCancel={handleClose}
      width={777}
      footer={null}
      destroyOnClose>
      <Tabs defaultActiveKey='qa'>
        {tabPaneList?.map(pane => (
          <TabPane tab={pane.title} closable={false} key={pane.key} className="preview-modal-newPan">
            {
              /* data.html && parse(data.html) */
              pane.content.map((item, idx) =>
                item.type === 'toggle' ? (
                  <div className='toggle-section' key={idx}>
                    <Collapse
                      expandIconPosition="right"
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <CloseSquareFilled style={{ fontSize: '150%' }} />
                        ) : (
                          <PlusSquareFilled style={{ fontSize: '150%' }} />
                        )
                      }
                    >
                      <Panel header={item.content.title} key='1'>
                        <p>{item.content.content}</p>
                      </Panel>
                    </Collapse>
                  </div>
                ) : (
                  <article className='preview-modal-article' key={idx}>{parse(item.content.content)}</article>
                )
              )
            }
          </TabPane>
        ))}
        <TabPane tab='常見問題' key='qa'>
          <Table
            columns={qaColumns}
            dataSource={questionList}
            rowKey='id'
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: true,
              defaultPageSize: 20,
              defaultCurrent: 1,
              // total: questionList.length,
              showTotal: (total, range) =>
                `${ range[0] }-${ range[1] }則問題（共${ total }則問題）`,
            }}
          />
        </TabPane>
        <TabPane tab='相關下載' key='download'>
          <Table
            columns={fileColumns}
            dataSource={fileList}
            rowKey='id'
            pagination={{
              position: ['bottomCenter'],
              showSizeChanger: true,
              defaultPageSize: 20,
              defaultCurrent: 1,
              total: fileList.length,
              showTotal: (total, range) =>
                `${ range[0] }-${ range[1] }則問題（共${ total }則問題）`,
            }}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default ProductPreviewModal;
