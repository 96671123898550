import React from 'react';
import { Modal } from 'antd';
import CustomerButton from '../layouts/CustomerButton';

import '../../styles/layouts/CancelModal.css';

const CancelModal = ({ isOpen, handleClose, handleChange, errMsg }) => {
  return (
    <Modal
      className='cancel-modal'
      centered
      visible={isOpen}
      title='刪除'
      onCancel={handleClose}
      width={320}
      destroyOnClose
      onOk={handleChange}
      footer={
        errMsg
          ? [
              <CustomerButton key='submit' color='blue' onClick={handleClose}>
                確認
              </CustomerButton>,
            ]
          : [
              <CustomerButton key='cancel' onClick={handleClose}>
                取消
              </CustomerButton>,
              <CustomerButton key='submit' color='blue' onClick={handleChange}>
                確認
              </CustomerButton>,
            ]
      }>
      <h4 className='cancel-modal-content'>
        {errMsg || '再一次確認，您確定要刪除資料？'}
      </h4>
    </Modal>
  );
};

export default CancelModal;
