import { useState, useEffect } from 'react';
import {
  requestPostTradePlatform,
  requestPutTradePlatform,
  requestGetTradePlatformDetail,
  requestGetTradingAppVerify,
  requestGetCommonQuestionByKeyword,
  requestGetTradePlatformCategory,
  requestDeleteTradeTradingApp,
  requestFinancialProductList
} from '../../services/tradePlatform/tradePlatformService';

import {
  requestGetTradingAppCategories,
  requestVerifyTradingAppCategoryCode,
  requestPostTradingAppCategory,
  requestDeleteTradingAppCategory,
  requestPutTradingAppCategory
} from '../../services/tradePlatform/tradePlatformCategories';

export function useRequestTradePlatform() {
  const postTradePlatform = async params => {
    const res = await requestPostTradePlatform(params);
    if (res.status) {
      return true;
    }
  };

  const putTradePlatform = async params => {
    const res = await requestPutTradePlatform(params);
    if (res.status) {
      return true;
    }
  };

  const getTradePlatformDetail = async id => {
    const res = await requestGetTradePlatformDetail(id);
    if (res.status) {
      return res.data.data.result;
    }
  };

  const getTradingAppVerify = async code => {
    const res = await requestGetTradingAppVerify(code);
    if (res.status) {
      return res;
    }
  };

  const getCommonQuestionByKeyword = async keywords => {
    const listData = await requestGetCommonQuestionByKeyword(keywords);
    if (listData.status) {
      return listData.data.data.result.dataList;
    }
  };

  const getTradeTradePlatformCategory = async id => {
    const res = await requestGetTradePlatformCategory(id);
    if (res.status) {
      return res.data.data.result.apps;
    }
  };

  const deleteTradingApp = async id => {
    const res = await requestDeleteTradeTradingApp(id);
    if (res.status) {
      return true;
    }
  };

  const getFinancialProductList = async() => {
    const res = await requestFinancialProductList();
    if (res.status) {
      return res.data.data.result;
    }
  };

  return {
    postTradePlatform,
    putTradePlatform,
    getTradePlatformDetail,
    getTradingAppVerify,
    getCommonQuestionByKeyword,
    getTradeTradePlatformCategory,
    deleteTradingApp,
    getFinancialProductList
  };
}

export function useRequestTradePlatformCategories() {
  const [platformList, setPlatformList] = useState([]);

  useEffect(() => {
    getTradePlatformCategories();
  }, []);

  const getTradePlatformCategories = async() => {
    const res = await requestGetTradingAppCategories();
    if (res.status) {
      setPlatformList(res.data.data.result);
    }
  };

  const getTradeTradePlatformCategory = async id => {
    const res = await requestGetTradePlatformCategory(id);
    if (res.status) {
      return res.data.data.result;
    }
  };

  const getVerifyPlatformCategoryCode = async params => {
    const res = await requestVerifyTradingAppCategoryCode(params);
    if (res) {
      return res.data;
    }
  };

  const postTradePlatformCategory = async params => {
    const res = await requestPostTradingAppCategory(params);
    if (res.status) {
      return true;
    }
  };

  const deleteTradePlatformCategory = async id => {
    const res = await requestDeleteTradingAppCategory(id);
    if (res.status) {
      return true;
    }
  };

  const putTradePlatformCategory = async params => {
    const res = await requestPutTradingAppCategory(params);
    if (res.status) {
      return true;
    }
  };

  return {
    platformList,
    getTradePlatformCategories,
    getTradeTradePlatformCategory,
    getVerifyPlatformCategoryCode,
    postTradePlatformCategory,
    deleteTradePlatformCategory,
    putTradePlatformCategory
  };
}
