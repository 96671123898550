export const functionPermission = {
  title: 'functionPermission',
  type: 'object',
  required: [],
  properties: {
    permissionName: {
      type: 'string',
      minLength: 1
    },
    permissionCode: {
      type: 'string',
      minLength: 1
    },
    permissionPath: {
      type: 'string',
      minLength: 1
    },
    isActive: {
      type: 'boolean'
    }
  }
};
