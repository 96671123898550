import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';


/**
 * [requestGet2ndQuestionCategoriesWithId]
 * @return {object} [result]
 */
export async function requestGet2ndQuestionCategoriesWithId(id) {
  const url = `/JSON/question/questionCategories.json`;
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPost3ndCategory]
 * @return {object} [result]
 */
export async function requestPost3rdCategory(params) {
  const url = '/api/question/category3rd';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}


/**
 * [requestPut3ndCategory]
 * @return {object} [result]
 */
export async function requestPut3rdCategory(params) {
  console.log('ppp', params);
  const url = '/api/question/category3rd';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDelete3ndCategory]
 * @return {object} [result]
 */
export async function requestDelete3rdCategory(id) {
  const params = {
    category3rdId: id
  };
  const url = '/api/question/category3rd';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}