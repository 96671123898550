import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import { errorHandle } from '../api/errorHandle.js';

export async function requestGetPlatforms() {
  const url = '/api/menu/platforms';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestGetPlatformDetail(platformCode) {
  const url = `/api/menu/platforms/${ platformCode }`;
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestCreatePlatform(params) {
  const url = '/api/menu/platform';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestUpdatePlatform(params) {
  const url = '/api/menu/platform';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestDeletePlatform(platformCode) {
  const params = {
    platformCode
  };
  const url = '/api/menu/platform';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
