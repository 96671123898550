import { useState, useEffect } from 'react';
import {
  requestGetUsersList,
  requestUserBasicData,
  requestUserDetails,
  // requestCreateNewAccount,
  // requestUpdateAccount,
  // requestDeleteAccount
} from '../../services/permission/usersManageService.js';

import { defineUserManagement } from '../../domain/permission/usersManage';

export function useUserManage() {
  const [usersList, setUserList] = useState([]);

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async() => {
    const listData = await requestGetUsersList();
    if (listData.status) {
      const usersData = [];
      listData.data.forEach((e) => {
        usersData.push(defineUserManagement(e));
      });
      setUserList(usersData);
    }
  };

  const getUserData = async(staffNo) => {
    const userData = await requestUserBasicData(staffNo);
    return {
      empID: userData?.data?.data?.result?.empID,
      name: userData?.data?.data?.result?.name
    };
  };

  const getUserDetails = async(userId) => {
    const userInformation = await requestUserDetails(userId);
    if (userInformation.data.data) {
      const data = userInformation.data.data;
      const userIndex = data.findIndex(i=>i.id === userId);
      const userData = data[userIndex];
      // return userInformation.data.data;
      return userData;
    } else {
      return false;
    }
  };

  const getUserDataForGroupEdit = async(staffNo) => {
    const { empID } = await getUserData(staffNo);
    const userDataSortArray = usersList.filter(e => e.staffNo === empID);
    return userDataSortArray.length > 0 ? userDataSortArray[0] : false;
  };

  // 新增
  // const creatNewUser = async(newData) => {
  //   const result = await requestCreateNewAccount(newData);
  //   if (result.status) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // 更新
  // const updateUser = async(updateData) => {
  //   const result = await requestUpdateAccount(updateData);
  //   if (result.status) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  // 刪除
  // const deleteUser = async(userId) => {
  //   const result = await requestDeleteAccount(userId);
  //   if (result.status) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  return {
    usersList,
    getUsersList,
    getUserData,
    getUserDetails,
    // creatNewUser,
    // updateUser,
    // deleteUser,
    getUserDataForGroupEdit
  };
}