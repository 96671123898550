/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Modal, Input, Form } from 'antd';
import CustomerButton from './CustomerButton';
import CustomerInput from './CustomerInput';
import { inputValidator } from '../../services/validotor';
import { sendErrorMessage } from './Message';

import '../../styles/layouts/FunctionModal.css';

const CreateIDCardModal = ({
  isOpen,
  isEdit,
  handleClose,
  handleChange,
  handleUpdate,
  existIdCardNumber,
}) => {
  const { requiredValidator, symbolValidator } = inputValidator();

  const [IdCardNumber, setIdCardNumber] = useState('');

  useEffect(() => {
    if (!isOpen) {
      setIdCardNumber('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (existIdCardNumber) {
      setIdCardNumber(existIdCardNumber);
    }
  }, [existIdCardNumber]);

  const inputIdCardNumber = e => {
    setIdCardNumber(e.target.value);
  };

  const handleClickToSave = value => {
    isEdit ? handleUpdate(value.idNumber) : handleChange(value.idNumber);
  };

  const handleFinishFailed = errorInfo => {
    if (errorInfo) {
      sendErrorMessage('請檢查輸入內容。');
    }
  };

  return (
    <Modal
      className='function-modal'
      centered
      visible={ isOpen }
      title='加入用戶'
      onCancel={ handleClose }
      width={320}
      destroyOnClose
      footer={[
        <CustomerButton key='cancel' onClick={ handleClose }>
          取消
        </CustomerButton>,
        <CustomerButton
          form='modal-form'
          key='submit'
          color='blue'
          htmlType='submit'>
          確定
        </CustomerButton>,
      ]}>
      <h4 className='function-modal-content modal-input-required'>身分證字號</h4>
      <Form
        id='modal-form'
        onFinish={handleClickToSave}
        onFinishFailed={handleFinishFailed}>
        <Form.Item
          name='idNumber'
          rules={[requiredValidator(), symbolValidator()]}>
          <CustomerInput
            maxLength={20}
            placeholder='請輸入身分證字號'
            value={ IdCardNumber }
          />
        </Form.Item>
      </Form>
      {/*
      <Input
        placeholder='請輸入身分證字號'
        className='function-modal-input'
        value={IdCardNumber}
        onChange={ inputIdCardNumber }
      />*/}
    </Modal>
  );
};

export default CreateIDCardModal;
