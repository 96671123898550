import React from 'react';
import { Provider } from 'react-redux';
import zhTW from 'antd/lib/locale/zh_TW';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './application/router';
import store from './store';
import { ConfigProvider, Layout } from 'antd';
import 'antd/dist/antd.css';
import './styles/App.css';

import SiderBar from './presentation/layouts/SiderBar';
import SiteBar from './presentation/layouts/SiteBar';
import Breadcrumb from './presentation/layouts/Breadcrumb';
import LogoutModal from './presentation/layouts/LogoutModal';

// import { MessageContextProvider } from './store/context/messageContext.js';

import {
  useCheckUserToken,
  useCheckIsInSignInPage,
} from './application/users/useUserLogin';

/* eslint-disable */
function App() {
  const isLogin = useCheckUserToken();
  const isInSignInPage = useCheckIsInSignInPage();

  if (!isLogin && !isInSignInPage) {
    window.location = '/cms/users/signin';
  }

  return (
    <ConfigProvider locale={zhTW}>
      <Provider store={store}>
        <Router basename='/cms'>
          <Layout>
            {isLogin ? <SiderBar /> : null}
            <section className={isLogin ? 'app-section' : 'app-section-logout'}>
              {isLogin ? <SiteBar /> : null}
              {isLogin ? <Breadcrumb /> : null}
              <Switch>
                {routes.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    children={<route.view />}
                  />
                ))}
              </Switch>
            </section>
            <LogoutModal />
          </Layout>
        </Router>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
