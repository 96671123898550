import { useState, useEffect } from 'react';
// import Ajv from 'ajv';
// import { webMenu } from '../../domain/menu/webMenu.js';
import { 
  requestGetWebMenuList,
  requestGetMenuDetailsWithId,
  requestCreateNewMenu,
  requestUpdateMenu,
  requestDeleteMenu,
} from '../../services/menu/webMenuService.js';

export function useWebMenu() {
  const [webMenuList, setWebMenuList] = useState([]);


  useEffect(() => {
    getWebMenuList();
  }, []);
  
  const getWebMenuList = async() => {
    const listData = await requestGetWebMenuList();
    if (listData.status) {
      
      /* const menuOptionsData = listData.data.map((e) => { 
        return {
          ...e,
          title: `${ e.title } (${ e.remark })`
        }; 
      }); */
      setWebMenuList(listData.data);
    }
  };

  const getWebMenuSingleDetails = async(groupId) => {
    const selectData = await requestGetMenuDetailsWithId(groupId);
    if (selectData.status) {
      return selectData.data;
    } else {
      return false;
    }
  };

  const createNewMenu = async(body) => {
    const requestBody = {
      ...body,
      needLoginDisplay: body.needLoginDisplay === 0,
    };
    // const valid = validate(requestBody);
    // if (!valid) {
    //   console.log(validate.errors);
    //   return false;
    // }
    const result = await requestCreateNewMenu(requestBody);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const updateMenu = async(body) => {
    const requestBody = {
      ...body,
      needLoginDisplay: body.needLoginDisplay === 0,
    };
    console.log('---body', requestBody);
    const result = await requestUpdateMenu(requestBody);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  const deleteMenu = async(id) => {
    const result = await requestDeleteMenu(id);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    webMenuList,
    getWebMenuList,
    createNewMenu,
    updateMenu,
    deleteMenu,
    getWebMenuSingleDetails,
  };
}
