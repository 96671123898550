import React, { useState, useEffect } from 'react';
import CustomerInput from './CustomerInput';

import '../../styles/layouts/Editor.css';

const EditorToggleBlock = ({ value, onEditToggle }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (value.title) {
      setTitle(value.title);
    }
    if (value.content) {
      setContent(value.content);
    }
  }, [value]);

  const onTitleChange = (e) => {
    setTitle(e.target.value);
    onEditList(e.target.value, content);
  };

  const onContentChange = (e) => {
    setContent(e.target.value);
    onEditList(title, e.target.value);
  };

  const onEditList = (toggleListTitle, toggleListContent) => {
    const result = {
      type: 'toggle',
      content: {
        title: toggleListTitle,
        content: toggleListContent,
      },
    };

    onEditToggle(result);

  };

  return (
    <div className='editor-container editor-toggle-container'>
      <span className='editor-header'>開合列表</span>
      {/* <div 開合列表</div> */}
      <div className='editor-body'>
        <CustomerInput
          placeholder='請輸入問題標題'
          onChange={ onTitleChange }
          value={ title }
          allowClear
          className="editor-title-input"
        />
        <CustomerInput
          placeholder='請輸入說明文字'
          onChange={ onContentChange }
          value={ content }
          textArea
          rows={4}
        />
      </div>
    </div>
  );
};

export default EditorToggleBlock;
