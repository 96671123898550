import homeRoutes from './home.js';
import usersRoutes from './users.js';
import advertisementRoutes from './advertisement.js';
import permissionRoutes from './permission.js';
import fourOFourRoutes from './404.js';
import menuRoutes from './menu.js';
import questionRoutes from './question.js';
import productRoutes from './product.js';
import tradePlatform from './trade-platform';

const routes = [
  ...questionRoutes,
  ...homeRoutes,
  ...usersRoutes,
  ...advertisementRoutes,
  ...permissionRoutes,
  ...menuRoutes,
  ...productRoutes,
  ...tradePlatform,
  // fourOFourRoutes must be last one
  ...fourOFourRoutes,
];

export default routes;
