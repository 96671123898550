// import React, { useState, useEffect } from 'react';
// import { PlusOutlined } from '@ant-design/icons';

// import PlatformFunctionGroupDrawer from './PlatformFunctionGroupDrawer';
// import DragList from './draggableSection/DragList.js';

// import '../../../../styles/layouts/PlatformManageSection.css';

// const PlatformManageSection = ({ tabName, isOnlyAddFunction, existItems, mainMenuId, menuOptions, functionOptions, handleChange }) => {
const PlatformManageSection = () => {

  // const [isDrawerOpen, setDrawerOpen] = useState(false);
  // const [isAppendFunctionIntoDragList, setIsAppendFunctionIntoDragList] = useState(false);
  // const [selectMenu, setSelectMenu] = useState('');
  // // const [lists, setLists] = useState([]);
  // const [doubleDisplayMode, setdoubleDisplayMode] = useState(false);
  // const [boardElements, setBoardElements] = useState([]);
  // const [isOnlyFunction, setIsOnlyFunction] = useState(false);
  // const [functionBoardElements, setFunctionBoardElements] = useState([
  //   {
  //     menu: '功能選單',
  //     functions: []
  //   }
  // ]);

  // useEffect(() => {
  //   if (isOnlyAddFunction) {
  //     setIsOnlyFunction(true);
  //   }
  // }, [isOnlyAddFunction]);

  // useEffect(() => {
  //   if (existItems && existItems.length > 0) {
  //     const elementsData = [];
  //     const functionsData = [];
  //     const doubleMenu = [{
  //       menu: '功能選單',
  //       menuId: 0,
  //       functions: []
  //     }];
  //     existItems.map((el) => {
        
  //       if (el.type === 'group') {
  //         // 2 + 3
  //         const functionsItem = [];
  //         if (el.items && el.items.length > 0) {
  //             el.items.map((func) => {
  //             const newItem = {
  //               id: `${ func.id }`,
  //               prefix: el.title,
  //               remark: el?.remark,
  //               content: func.title
  //             };
  //             functionsItem.push(newItem);
  //           });
  //         }
  //         const item = {
  //           menu: el.title, // name,
  //           menuId: el.id,
  //           remark: el?.remark,
  //           functions: functionsItem
  //         };
  //         elementsData.push(item);
  //         setBoardElements(elementsData);
  //       } else if (el.type !== 'group' && tabName !== '功能選單') {
  //         doubleMenu[0].functions.push({
  //           id: `${ el.id }`,
  //           prefix: '功能選單',
  //           content: el.title,
  //           remark: el?.remark,
  //         });
  //         setBoardElements(doubleMenu);
          
  //       } else {
  //         const functionItem = {
  //           id: `${ el.id }`,
  //           prefix: '功能選單',
  //           content: el.title,
  //           remark: el?.remark,
  //         };
  //         functionsData.push(functionItem);
  //       }
  //     });
  //     setFunctionBoardElements([
  //       {
  //         menu: '功能選單',
  //         functions: functionsData
  //       }
  //     ]);
  //   }
  // }, [existItems, tabName]);

  // const handleDrawerOpen = () => {
  //   setDrawerOpen(true);
  // };

  // const handleDrawerClose = () => {
  //   setIsOnlyFunction(false);
  //   setDrawerOpen(false);
  // };

  // const handleOpenNewOption = () => {
  //   setIsAppendFunctionIntoDragList(false);
  //   handleDrawerOpen();
  // };

  // const handleAddFunction = (menu) => {
  //   setSelectMenu(menu);
  //   setIsAppendFunctionIntoDragList(true);
  //   setIsOnlyFunction(true);
  //   handleDrawerOpen();
  // };

  // const handleDragEnd = (result) => {
  //   if (!result.destination) {
  //     return;
  //   }
  //   const newCopy = tabName !== '功能選單' ? 
  //     JSON.parse(JSON.stringify([...boardElements])) :
  //     JSON.parse(JSON.stringify([...functionBoardElements]));

  //   const sourceFunctionsList = newCopy.filter(e => e.menu === result.source.droppableId);

  //   const destinationFunctionsList = newCopy.filter(e => e.menu === result.destination.droppableId);

  //   // Remove
  //   const removeTargetIndex = result.source.index;
  //   const removerTargetElement = sourceFunctionsList[0].functions[removeTargetIndex];
  //   const sourceFunctionsArray = sourceFunctionsList[0].functions;
  //   sourceFunctionsArray.splice(removeTargetIndex ,1);

  //   // Append
  //   const appendTargetIndex = result.destination.index;
  //   const destinationFunctionsArray = destinationFunctionsList[0].functions;
  //   removerTargetElement.prefix = result.destination.droppableId;
  //   destinationFunctionsArray.splice(appendTargetIndex, 0, removerTargetElement);

  //   // reset
  //   newCopy[newCopy.findIndex(el => el.menu === result.source.droppableId)].functions = sourceFunctionsArray;
  //   newCopy[newCopy.findIndex(el => el.menu === result.destination.droppableId)].functions = destinationFunctionsArray;

  //   tabName !== '功能選單' ? setBoardElements(newCopy) : setFunctionBoardElements(newCopy);
    
  //   if (tabName === '功能選單') {
  //     // 單層
  //     const updatedData = [];
  //     newCopy[0].functions.map((e) => {
  //       updatedData.push({
  //         type: 'function',
  //         id: Number(e.id)
  //       });
  //     });
  //     console.log('is updated', updatedData);
  //     console.log('is updated');
  //     handleChange({
  //       menuData: updatedData,
  //       isSingle: true
  //     });
  //   } else {
  //     // 2 + 3
  //     let updateData = [];
  //     newCopy.map((e) => {
  //       const functionsItem = [];
  //       e.functions.map((func) => {
  //         const item = {
  //           type: 'function',
  //           id: Number(func.id)
  //         };
  //         functionsItem.push(item);
  //       });

  //       if (e.menuId > 0) {
  //         const subMenuItem = {
  //           type: 'group',
  //           id: e.menuId,
  //           items: functionsItem
  //         };
  //         updateData.push(subMenuItem);
  //       } else {
  //         updateData = [...functionsItem];
  //       }
  //       // 
  //     });
  //     console.log('no update');
  //     console.log(updateData);
  //     handleChange({
  //       menuData: {
  //         tabName: tabName,
  //         updateData: updateData
  //       },
  //       isSingle: false
  //     });
  //   }
    
  // };

  // const handleSave = (saveData) => {
  //   const { name, type } = saveData;

  //   const elementsArray = [...boardElements];
  //   if (type === '選單') {
  //     const menuInformation = menuOptions.filter(e => e.id === name)[0];
  //     elementsArray.push({
  //       menu: menuInformation.title, // name,
  //       menuId: menuInformation.id,
  //       functions: []
  //     });
  //   } else {
  //     const functionInformation = functionOptions.filter(e => e.id === name)[0];
  //     console.log(functionInformation);
  //     setdoubleDisplayMode(true);
  //     elementsArray.push({
  //       menu: '功能選單', // name,
  //       menuId: 0,
  //       functions: [
  //         {
  //           id: `${ functionInformation.id }`,
  //           prefix: '功能選單',
  //           content: functionInformation.title
  //         }
  //       ]
  //     });
  //   }
    
  //   setBoardElements(elementsArray);
  // };

  // const handleRemoveColumn = (column) => {
  //   const newCopy = JSON.parse(JSON.stringify([...boardElements]));
  //   // Remove column
  //   const newElements = newCopy.filter(e => e.menu !== column);
  //   setBoardElements(newElements);
  // };

  // /**
  //  * [handleAppendToDragList]
  //  * @param  {Object} saveData [description]
  //  */
  // const handleAppendToDragList = (saveData) => {
  //   console.log('this');
  //   const { name } = saveData;
  //   const functionInformation = functionOptions.filter(e => e.id === name)[0];
  //   const newCopy = tabName !== '功能選單' ? 
  //     JSON.parse(JSON.stringify([...boardElements])) :
  //     JSON.parse(JSON.stringify([...functionBoardElements]));
  //   // const randomId = Math.floor(Math.random() * 9999);

  //   const selectMenuIndex = newCopy.findIndex(e => e.menu === selectMenu);
    
  //   newCopy[selectMenuIndex].functions.push({
  //     id: `${ functionInformation.id }`,
  //     prefix: newCopy[selectMenuIndex].menu,
  //     content: functionInformation.title
  //   });
  //   tabName !== '功能選單' ? setBoardElements(newCopy) : setFunctionBoardElements(newCopy);
    
  //   let updatedData = [];
  //   if (tabName === '功能選單') {
  //     // single
  //     newCopy[0].functions.map((e) => {
  //       updatedData.push({
  //         type: 'function',
  //         id: Number(e.id)
  //       });
  //     });

  //     handleChange({
  //       isSingle: true,
  //       menuData: updatedData
  //     });
  //   } else {
  //     let double = true;
  //     console.log('newCopy ==>', newCopy);
  //     // console.log(mainMenuId);
  //     // 2 + 3
  //     newCopy.map((e) => {
  //       const functions = [];
  //       e.functions.map((functionItem) => {
  //         functions.push({
  //           type: 'function',
  //           id: Number(functionItem.id)
  //         });
  //       });
  //       if (e.menuId > 0) {
  //         const item = {
  //           typeName: e.menu,
  //           type: 'group',
  //           id: e.menuId,
  //           items: functions
  //         };
  //         updatedData.push(item);
  //         double = false;
  //       } else {
  //         updatedData = [...functions];
  //         double = true;
  //       }
  //       // updatedData.push(item);
  //     });
  //     console.log('updatedData -->', updatedData);
  //     handleChange({
  //       isSingle: false,
  //       menuData: {
  //         tabName: tabName,
  //         double,
  //         updateData: updatedData
  //       } 
  //     });
  //     //
  //   }
    
  // };

  // const handleRemoveItem = (item) => {
  //   const newCopy = tabName !== '功能選單' ? 
  //     JSON.parse(JSON.stringify([...boardElements])) :
  //     JSON.parse(JSON.stringify([...functionBoardElements]));
  //   console.log(item);
  //   // Remove item
  //   const sourceFunctionsList = newCopy.filter(e => e.menu === item.prefix);

  //   const removeTargetIndex = item.index;
  //   const sourceFunctionsArray = sourceFunctionsList[0].functions;
  //   sourceFunctionsArray.splice(removeTargetIndex ,1);
  //   // reset data
  //   newCopy[newCopy.findIndex(el => el.menu === item.prefix)].functions = sourceFunctionsArray;
  //   tabName !== '功能選單' ? setBoardElements(newCopy) : setFunctionBoardElements(newCopy);
  //   const updatedData = [];
  //   if (tabName === '功能選單') {
  //     // 單層
  //     newCopy[0].functions.map((e) => {
  //       updatedData.push({
  //         type: 'function',
  //         id: Number(e.id)
  //       });
  //     });
  //     console.log(updatedData);
  //     handleChange({
  //       isSingle: true,
  //       menuData: updatedData
  //     });
  //   } else {
  //     // 2 + 3
  //     newCopy.map((e) => {
  //       const functions = [];
  //       e.functions.map((functionItem) => {
  //         functions.push({
  //           type: 'function',
  //           id: Number(functionItem.id)
  //         });
  //       });
  //       const item = {
  //         typeName: e.menu,
  //         type: 'group',
  //         id: e.menuId,
  //         items: functions
  //       };
  //       updatedData.push(item);
  //     });

  //     handleChange({
  //       isSingle: false,
  //       menuData: {
  //         tabName: tabName,
  //         updateData: [
  //           {
  //             mainMenu: tabName,
  //             type: 'group',
  //             id: mainMenuId,
  //             items: updatedData
  //           }
  //         ]
  //       } 
  //     });
  //   }
    
  // };

  return (
    <>
      {/* <main className='platform-add-section'>
        <DragList
          elementsData={ tabName !== '功能選單' ? boardElements : functionBoardElements }
          handleDragEnd={ handleDragEnd }
          handleRemoveColumn={ handleRemoveColumn }
          handleAddItem={ handleAddFunction }
          handleRemoveItem={ handleRemoveItem }
        />
        {
          doubleDisplayMode ? null : tabName !== '功能選單' ?  (
            <div className='platform-add-func-button' onClick={ handleOpenNewOption }>
              <PlusOutlined style={{ fontSize: 13 }} />
              <span>{ isOnlyAddFunction ? '加入功能' : '加入選單' }</span>
            </div>
          ) : null
        }
      </main>
      <PlatformFunctionGroupDrawer
        isOpen={ isDrawerOpen }
        isOnlyFunction={ isOnlyFunction }
        isAppendFunctionIntoDragList={ isAppendFunctionIntoDragList }
        menuOptions={ menuOptions }
        functionOptions={ functionOptions }
        handleClose={ handleDrawerClose }
        handleSave={ handleSave }
        handleAppendToDragList={ handleAppendToDragList }
      /> */}
    </>
  );
};

export default PlatformManageSection;
