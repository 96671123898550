import React from 'react';
import { Select } from 'antd';

import '../../styles/layouts/CustomerSelect.css';

const { Option } = Select;

const CustomerSelect = ({ placeholder, options, onChange, selectedValue, ...props }) => {
  return (
    <Select
      placeholder={placeholder}
      onChange={onChange}
      value={selectedValue}
      {...props}
      // dropdownRender={menu => <div>{console.log(menu)}123</div>}
    >
      {options.map((option, idx) => (
        <Option key={idx} value={option.id}>
          {option.text}
        </Option>
      ))}
    </Select>
  );
};

export default CustomerSelect;
