import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

/**
 * [requestGetQuestionCategories]
 * @return {object} [result]
 */
export async function requestGetQuestionCategories() {
  const url = '/JSON/question/questionCategories.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPostCategory]
 * @return {object} [result]
 */
export async function requestPostCategory(params) {
  const url = '/api/question/category';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPutCategory]
 * @return {object} [result]
 */
export async function requestPutCategory(params) {
  const url = '/api/question/category';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDeleteCategory]
 * @return {object} [result]
 */
export async function requestDeleteCategory(id) {
  const params = {
    categoryId: id
  };
  const url = '/api/question/category';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}