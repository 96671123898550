import {
  SEND_SUCCESS_MESSAGE,
  SEND_ERROR_MESSAGE,
  OPEN_LOGOUT_MODAL
} from '../types';

const initialState = {
  key: 'errMsgKey',
  type: 'error',
  content: '更新失敗',
  duration: 5,
  maxCount: 1,
  style: {
    position: 'relative',
    top: 750,
  },
  isLogoutModalOpen: false
};

const Message = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEND_SUCCESS_MESSAGE:
      return {
        ...state,
        type: 'success',
        content: payload.message
      };
    case SEND_ERROR_MESSAGE:
      return {
        ...state,
        type: 'error',
        content: payload.message
      };
    case OPEN_LOGOUT_MODAL:
      return {
        ...state,
        isLogoutModalOpen: payload.isLogoutModalOpen
      };
    default:
      return state;
  }
};

export default Message;
