import { useState, useEffect } from 'react';
import { 
  requestGetPlatforms,
  requestCreatePlatform,
  requestUpdatePlatform,
  requestDeletePlatform
} from '../../services/menu/platformService';

export function useMenuPlatform() {
  const [platformList, setPlatformList] = useState([]);

  useEffect(() => {
    getPlatform();
  }, []);

  const getPlatform = async() => {
    const result = await requestGetPlatforms();
    if (result.status && result.data.data.result) {
      setPlatformList(result.data.data.result);
    } else {
        return result.data.message;
    }
  };
  
  const createPlatform = async(params) => {
      const result = await requestCreatePlatform(params);
      if (result.status) {
        return true;
      } else {
          return result.data.message;
      }
  };

  const updatePlatform = async(params) => {
      const result = await requestUpdatePlatform(params);
      if (result.status) {
        return true;
      } else {
        return result.data.message;
      }
    };

  const deletePlatform = async(platformCode) => {
    const result = await requestDeletePlatform(platformCode);
    if (result.status) {
      return true;
    } else {
      return result.data.message;
    }
  };
  return {
    platformList,
    getPlatform,
    createPlatform,
    updatePlatform,
    deletePlatform
  };
}
