import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

// ----second----

/**
 * [requestGetQuestionCategoriesWithId]
 * @return {object} [result]
 */

// export async function requestGetQuestionCategoriesWithId(id) {
export async function requestGetQuestionCategoriesWithId() {
  // const url = `/api/question/category/${ id }`;
  const url = '/JSON/question/questionCategories.json';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await getRequest(url);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPost2ndCategory]
 * @return {object} [result]
 */
export async function requestPost2ndCategory(params) {
  const url = '/api/question/category2nd';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestPut2ndCategory]
 * @return {object} [result]
 */
export async function requestPut2ndCategory(params) {
  const url = '/api/question/category2nd';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

/**
 * [requestDelete2ndCategory]
 * @return {object} [result]
 */
export async function requestDelete2ndCategory(id) {
  const params = {
    category2ndId: id
  };
  const url = '/api/question/category2nd';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, params);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}