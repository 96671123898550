import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { CheckOutlined, SyncOutlined } from '@ant-design/icons';

import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import AdSlotsDataTable from '../../components/advertisement/AdSlotsDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import AdSlotsDrawerForm from '../../components/advertisement/AdSlotsDrawerForm';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useRequestAdSlot } from '../../../application/advertisement/useRequestAdSlot';

import '../../../styles/pages/SitePage.css';

function AdvertisementMainPage() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [form] = Form.useForm();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enterBtnText, setEnterBtnText] = useState('檢查代碼');

  const {
    adSlots,
    getAdSlots,
    getVerifyAdSlot,
    postAdSlot,
    getAdSlot,
    putAdSlot,
    deleteAdSlot,
  } = useRequestAdSlot();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '廣告管理',
        },
        {
          path: '/advertisement',
          pageName: '廣告版位管理',
        },
      ])
    );
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  const handleSaveCreate = async params => {
    const result = await postAdSlot(params);
    if (!result) {
      sendErrorMessage('新增失敗');
      return;
    }
    sendSuccessMessage('新增成功');
    getAdSlots();
    handleDrawerClose();
  };

  const handleDelete = async id => {
    const result = await deleteAdSlot(id);
    if (result !== true) {
      sendErrorMessage(result);
    } else {
      sendSuccessMessage('刪除成功');
      getAdSlots();
    }
  };

  const handleEdit = async params => {
    const result = await putAdSlot(params);
    if (!result) {
      sendErrorMessage('編輯失敗');
      return;
    }
    sendSuccessMessage('編輯成功');
    getAdSlots();
    handleDrawerClose();
    setIsEdit(false);
  };

  const handleVerifyCode = async value => {
    setIsLoading(true);
    setEnterBtnText(<SyncOutlined spin />);
    const res = await getVerifyAdSlot(value);
    if (!res) {
      form.setFields([
        { name: 'slotCode', errors: ['版位代碼名稱不可重複。'] },
      ]);
      setEnterBtnText('檢查代碼');
    } else {
      setEnterBtnText(<CheckOutlined />);
      setEnterBtnText('重新確認');
    }
    setTimeout(() => {
      setEnterBtnText('檢查代碼');
    }, 5000);
    setIsLoading(false);
  };

  const handleEditClick = async id => {
    const res = await getAdSlot(id);
    // this two lines are for mockup
    const index = res.findIndex(i => i.id === id);
    const slotData = res[index];
    form.setFieldsValue({
      slotCode: slotData.slotCode,
      slotName: slotData.slotName,
      desktopWidth: slotData.desktopWidth,
      desktopHeight: slotData.desktopHeight,
      mobileWidth: slotData.mobileWidth,
      mobileHeight: slotData.mobileHeight,
      isActive: slotData.isActive,
    });
    setEditData(res);
    setIsEdit(true);
    handleDrawerOpen();
  };

  console.log('adSlots', adSlots);

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>廣告版位管理</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增版位
            </CustomerButton>
          </div>
          <AdSlotsDataTable
            tableData={adSlots}
            handleDeleteClick={handleDelete}
            handleEditClick={handleEditClick}
          />
        </div>
      </Content>
      <AdSlotsDrawerForm
        isEdit={isEdit}
        isOpen={isDrawerOpen}
        isLoading={isLoading}
        enterBtnText={enterBtnText}
        handleClose={handleDrawerClose}
        handleSave={handleSaveCreate}
        form={form}
        handleVerifyCode={handleVerifyCode}
        handleUpdate={handleEdit}
        editData={editData}
      />
    </Layout>
  );
}

export default AdvertisementMainPage;
