/* eslint-disable */
import React from 'react';
import FirstCategory from '../../presentation/pages/question/firstCategory';
import SecondCategory from '../../presentation/pages/question/secondCategory';
import ThirdCategory from '../../presentation/pages/question/thirdCategory';
import QuestionList from '../../presentation/pages/question/questionList';
import AddQuestionList from '../../presentation/pages/question/addQuestionList';

const questionRoutes = [
  {
    path: '/question/category/first',
    exact: true,
    view: () => <FirstCategory />,
  },
  {
    path: '/question/category/second/:sIdx/:sName',
    exact: true,
    view: () => <SecondCategory />,
  },
  {
    path: '/question/category/second/:sIdx/:sName/third/:tIdx/:tName',
    exact: true,
    view: () => <ThirdCategory />,
  },
  {
    path: '/question/list',
    exact: true,
    view: () => <QuestionList />,
  },
  {
    path: '/question/list/add',
    exact: true,
    view: () => <AddQuestionList />,
  },
  {
    path: '/question/list/edit/:id',
    exact: true,
    view: () => <AddQuestionList />,
  },
];

export default questionRoutes;
