import React from 'react';
import { Modal, Form } from 'antd';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import { inputValidator } from '../../../../services/validotor';
import { sendErrorMessage } from '../../../layouts/Message';

import '../../../../styles/layouts/FunctionModal.css';

const FunctionModal = ({
  isOpen,
  isEdit,
  handleClose,
  handleChange,
  handleUpdate,
  existGroupName,
}) => {
  const { requiredValidator, symbolValidator } = inputValidator();

  const handleClickToSave = value => {
    isEdit ? handleUpdate(value.groupName) : handleChange(value.groupName);
  };

  const handleFinishFailed = errorInfo => {
    if (errorInfo) {
      sendErrorMessage('請檢查輸入內容。');
    }
  };

  return (
    <Modal
      className='function-modal'
      centered
      visible={isOpen}
      title={isEdit ? '編輯功能群組' : '新增功能群組'}
      onCancel={handleClose}
      width={320}
      destroyOnClose
      footer={[
        <CustomerButton key='cancel' onClick={handleClose}>
          取消
        </CustomerButton>,
        <CustomerButton
          form='modal-form'
          key='submit'
          color='blue'
          htmlType='submit'>
          確定
        </CustomerButton>,
      ]}>
      <h4 className='function-modal-content modal-input-required'>群組名稱</h4>
      <Form
        id='modal-form'
        onFinish={handleClickToSave}
        onFinishFailed={handleFinishFailed}>
        <Form.Item
          name='groupName'
          rules={[requiredValidator(), symbolValidator()]}>
          <CustomerInput
            maxLength={20}
            placeholder='請輸入群組名稱'
            defaultValue={existGroupName || ''}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FunctionModal;
