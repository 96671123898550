/* eslint-disable */
import React from 'react';
import FourOFour from '../../presentation/pages/404';

const fourOFourRoutes = [
  {
    path: '*',
    view: () => <FourOFour />
  }
];

export default fourOFourRoutes;
