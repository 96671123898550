import React, { useState, useRef } from 'react';
import moment from 'moment';
// import { Link } from 'react-router-dom';
import { Table, Space } from 'antd';
import CustomerButton from '../../../layouts/CustomerButton';
import ColumnSearchProps from '../../../layouts/ColumnSearchForm';
import CancelModal from '../../../layouts/CancelModal';
import EmptyDisplay from '../../../layouts/EmptyDisplay';
import '../../../../styles/layouts/MenuDataTable.css';


const MenuDataTable = ({ tableData, handleEditClick, handleDeleteClick }) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);
  const [isCancelModalOpen, setCancelModal] = useState(false);
  const [selectDeleteId, setSelectDeleteId] = useState(null);
  const refSearchInput = useRef();

  const handleResetSearch = () => {
    window.location.reload();
  };

  const handleCancelModalOpen = id => {
    setSelectDeleteId(id);
    setCancelModal(true);
  };

  const handleCancelModalClose = () => {
    setCancelModal(false);
  };

  const handleDelete = () => {
    handleDeleteClick(selectDeleteId);
    handleCancelModalClose();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '4%',
      ellipsis: 'true',
    },
    {
      title: '選單名稱',
      dataIndex: 'title',
      key: 'title',
      width: '10%',
      ellipsis: 'true',
      ...ColumnSearchProps(
        'title',
        refSearchInput,
        searchText,
        setSearchText,
        searchedColumn,
        setSearchedColumn
      )
    },
    {
      title: '建立時間',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      }
    },
    {
      title: '更新時間',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '15%',
      ellipsis: 'true',
      render(data) {
        return moment(new Date(data)).format('YYYY/MM/DD HH:mm:ss');
      }
    },
    {
      title: '顯示階層',
      dataIndex: 'hierarchy',
      key: 'hierarchy',
      width: '8%',
      ellipsis: 'true',
    },
    {
      title: '註記',
      dataIndex: 'remark',
      key: 'remark',
      width: '8%',
      ellipsis: 'true',
    },
    {
      title: '動作',
      key: 'action',
      width: '15%',
      ellipsis: 'true',
      render(record) {
        return (
          <Space size='middle'>
            <CustomerButton
              color='red'
              onClick={() => {
                handleCancelModalOpen(record.id);
              }}>
              刪除
            </CustomerButton>
            <CustomerButton
              color='blue'
              onClick={() => {
                handleEditClick(record.id);
              }}>
              編輯
            </CustomerButton>
          </Space>
        );
      },
    },
  ];

  const locale = {
    emptyText: <EmptyDisplay handleReload={ handleResetSearch } isNeedReloadBtn={ tableData.length > 0 } />
  };

  return (
    <>
      <Table
        locale={ locale }
        columns={ columns }
        dataSource={ tableData }
        rowKey='id'
        className='table-container'
      />
      <CancelModal
        isOpen={ isCancelModalOpen }
        handleClose={ handleCancelModalClose }
        handleChange={ handleDelete }
      />
    </>
  );
};

export default MenuDataTable;
