import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

function EditorBlock({ value, onEdit }) {

  // const [initialValue, setInitialValue] = useState('');

  const editorRef = useRef(null);

  /* useEffect(() => {
    if (value) {
      setInitialValue(value);
    }
  }, [value]); */
  
  const log = (e) => {
    onEdit(e);
   /* if (editorRef.current) {
     console.log(editorRef.current.getContent());
   } */
  };

  /*
  
  toolbar: 'undo redo | formatselect | ' +
  'bold italic backcolor | alignleft aligncenter alignright alignjustify' +
  'alignright alignjustify | bullist numlist outdent indent | ' +
  'removeformat | image | code | help',

   */

  return (
   <main style={{ marginBottom: 10 }}>
     <Editor
       apiKey={`${ process.env.REACT_APP_TINY_API_KEY }`}
       onInit={(evt, editor) => editorRef.current = editor}
       onEditorChange={ log }
       value={ value ? value : '' }
       init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar: 'undo redo | h1 h2 link | ' +
        'bullist numlist | ' +
        'image |',
        /* enable title field in the Image dialog*/
        image_title: true,
        /* enable automatic uploads of images represented by blob or data URIs*/
        automatic_uploads: true,
        /*
          URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
          images_upload_url: 'postAcceptor.php',
          here we add custom filepicker only to Image dialog
        */
        file_picker_types: 'image',
        /* and here's our custom image picker */
        file_picker_callback: function(cb, value, meta) {
          const input = document.createElement('input');
          console.log(value);
          console.log(meta);
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
          */

          input.onchange = function() {
            const file = this.files[0];

            const reader = new FileReader();
            reader.onload = function() {
              /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
              const id = 'blobid' + (new Date()).getTime();
              const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
              const base64 = reader.result.split(',')[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);

              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
     />
     {/* <button onClick={ log }>Log editor content</button> */}
   </main>
  );
}

export default EditorBlock;
