import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import CategoryDataTable from '../../components/question/category/CategoryFirstDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import CategoryCreateFirstDrawerForm from '../../components/question/category/CategoryCreateFirstDrawer';
import { useRequestFirstQuestionCategory } from '../../../application/questions/useRequestFirstQuestionCategory';
// import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import '../../../styles/pages/SitePage.css';

function FirstCategory() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [form] = Form.useForm();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectId, setSelectId] = useState();

  const {
    categoryList,
    // getQuestionCategories,
    // postQuestionCategory,
    // delRequestDeleteCategory,
    // putRequestPutCategory,
  } = useRequestFirstQuestionCategory();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '常見問題管理',
        },
        {
          path: '/question/category/first',
          pageName: '分類列表',
        },
      ])
    );
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  // 新增
  // const handleSave = async params => {
  //   const result = await postQuestionCategory(params);
  //   if (!result) {
  //     sendErrorMessage('新增失敗');
  //     return;
  //   }
  //   sendSuccessMessage('新增成功');
  //   getQuestionCategories();
  //   handleDrawerClose();
  // };

  // 刪除
  // const handleDeleteFirst = async id => {
  //   const result = await delRequestDeleteCategory(id);
  //   console.log(result);
  //   if (result !== true) {
  //     sendErrorMessage(result);
  //   } else {
  //     sendSuccessMessage('刪除成功');
  //     getQuestionCategories();
  //   }
  // };

  // 編輯
  // const handleEdit = async params => {
  //   const result = await putRequestPutCategory(params);
  //   if (!result) {
  //     sendErrorMessage('新增失敗');
  //     return;
  //   }
  //   sendSuccessMessage('新增成功');
  //   getQuestionCategories();
  //   handleDrawerClose();
  // };

  const handleEditClick = (id, name, isActive) => {
    form.setFieldsValue({
      categoryName: name,
      isActive: isActive,
    });
    setSelectId(id);
    setIsEdit(true);
    handleDrawerOpen();
  };

  // const handleDragSort = async params => {
  //   console.log('handleDragSort-outside', params);
  //   const result = await putRequestPutCategory(params);
  //   if (!result) {
  //     sendErrorMessage('排序失敗');
  //     return;
  //   } else {
  //     sendSuccessMessage('排序成功');
  //     getQuestionCategories();
  //   }
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>常見問題分類</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增第一層
            </CustomerButton>
          </div>
          <CategoryDataTable
            tableData={categoryList}
            // handleDeleteClick={handleDeleteFirst}
            handleEditClick={handleEditClick}
            // handleDragSort={handleDragSort}
          />
        </div>
      </Content>
      <CategoryCreateFirstDrawerForm
        isOpen={isDrawerOpen}
        handleClose={handleDrawerClose}
        // handleSave={handleSave}
        // handleEdit={handleEdit}
        selectId={selectId}
        form={form}
        isEdit={isEdit}
      />
    </Layout>
  );
}

export default FirstCategory;
