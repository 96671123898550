/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Drawer, Form, Space, Upload } from 'antd';
import cookies from 'js-cookie';

import CustomerButton from '../../layouts/CustomerButton';
import CustomerInput from '../../layouts/CustomerInput';
import { sendErrorMessage } from '../../layouts/Message';
import '../../../styles/components/DrawerForm.css';
import { UploadOutlined, InfoCircleFilled } from '@ant-design/icons';
import { inputValidator } from '../../../services/validotor';
// import { productNameValidator } from '../../../domain/product/productInputValidator';

const UploadFileDrawer = ({
  isUploadDrawerOpen,
  handleUploadDrawerSwitch,
  onFileUpload,
}) => {
  const { requiredValidator, symbolValidator } = inputValidator();
  const [form] = Form.useForm();

  const [checkFileIsAvailable, setCheckFileIsAvailable] = useState(false);
  const [newFileList, setNewFileList] = useState([]);

  const props = {
    action: `${ process.env.REACT_APP_FILES_UPLOAD_URL }`,
    headers: {
      token: cookies.get('elktree-demo-key'),
    },
    beforeUpload(file) {
      const isPdfZip = file.type === 'application/zip' || file.type === 'application/pdf' || file.type === 'application/x-zip-compressed';
      // const ext = file.name.substring(file.name.length - 4);
      // let isPdfZip2;
      // if(ext === '.zip' || ext === '.pdf') {
      //   isPdfZip2 = true;
      // } else {
      //   isPdfZip2 = false;
      // }
      // console.log('isPdfZip:', isPdfZip);
      // console.log('isPdfZip2:', isPdfZip2);
      // console.log('file.type:', file.type);
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isPdfZip) {
        sendErrorMessage('檔案格式不符');
        setCheckFileIsAvailable(false);
        return false;
      }
      if (!isLt10M) {
        sendErrorMessage('檔案過大');
        setCheckFileIsAvailable(false);
        return false;
      } else {
        setCheckFileIsAvailable(true);
        return true;
      }
    },
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
      }
    },
    showUploadList: {
      showRemoveIcon: true,
    },
  };

  const handleFileUpload = fileList => {
    if (checkFileIsAvailable) {
      setNewFileList(fileList.fileList);
    }
  };

  const handleClickToSave = value => {
    const params = {
      displayName: value.filename,
      filename: value.upload.file.response.result.filename,
    };
    onFileUpload(params);
    setNewFileList([]);
    handleUploadDrawerSwitch();
  };

  return (
    <>
      <Drawer
        extra={
          <span style={{ fontSize: 14, color: '#3f5372' }}>
            <InfoCircleFilled style={{ marginRight: 4 }} />
            支援上傳的檔案格式：pdf、zip，檔案大小限制：10MB以內。
          </span>
        }
        title='新增檔案'
        width={700}
        onClose={handleUploadDrawerSwitch}
        visible={isUploadDrawerOpen}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleUploadDrawerSwitch}>
              取消
            </CustomerButton>
            <CustomerButton
              form='edit-upload-file-form'
              htmlType='submit'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          id="edit-upload-file-form"
          form={form}
          className='form-group'
          layout='horizontal'
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 5 }}
          onFinish={handleClickToSave}>
          <Form.Item
            label='檔案名稱'
            name='filename'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput placeholder="請輸入檔案名稱" />
          </Form.Item>
          <Form.Item
            label='上傳附件'
            name='upload'
            rules={[{ required: true, message: '請上傳檔案。' }]}>
            <Upload
              {...props}
              // className='create-product-upload'
              maxCount='1'
              fileList={newFileList}
              onChange={handleFileUpload}>
              <CustomerButton icon={<UploadOutlined />} color='blue'>
                選擇檔案
              </CustomerButton>
            </Upload>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default UploadFileDrawer;
