import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Layout } from 'antd';

import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import '../../../styles/pages/SitePage.css';

import UserGroupListDataTable from '../../components/permission/user/UserGroupListDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import CreateIDCardModal from '../../layouts/CreateIDCardModal';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useCustomersList } from '../../../application/menu/useCustomerGroup.js';

function UserGroupList() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const { name, id } = useParams();

  useEffect(() => {

    dispatch(setBreadcrumb([
      {
        path: '',
        pageName: '選單管理'
      },
      {
        path: '/menu/group',
        pageName: '用戶群組管理'
      },
      {
        path: `/menu/group/${ id }/${ name }`,
        pageName: `${ name }`
      }
    ]));

  }, [dispatch, id, name]);

  const {
    customersList,
    getCustomerList,
    appendNewCustomerIntoGroup,
    deleteCustomerFromGroup
  } = useCustomersList(Number(id));

  const [isCreateGroupModalOpen, setCreateGroupModal] = useState(false);

  const handleCreateGroupModalOpen = () => {
    setCreateGroupModal(true);
  };

  const handleCreateGroupModalClose = () => {
    setCreateGroupModal(false);
  };

  const handleCreateNewCustomer = async(userId) => {
    const result = await appendNewCustomerIntoGroup(userId);
    if (!result) {
      sendErrorMessage('新增失敗');
      return;
    }
    handleCreateGroupModalClose();
    getCustomerList(id);
    sendSuccessMessage('新增成功');
  };

  const handleDeleteClick = async(userId) => {
    const result = await deleteCustomerFromGroup(userId);
    if (!result) {
      sendErrorMessage('刪除失敗');
      return;
    }
    handleCreateGroupModalClose();
    getCustomerList(id);
    sendSuccessMessage('刪除成功');
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>{name} - 用戶名單</h1>
            <CustomerButton color='red' onClick={handleCreateGroupModalOpen}>
              加入用戶
            </CustomerButton>
          </div>
          <UserGroupListDataTable
            tableData={ customersList }
            handleDeleteClick={ handleDeleteClick }
          />
        </div>
      </Content>
      <CreateIDCardModal
        isOpen={ isCreateGroupModalOpen }
        handleClose={ handleCreateGroupModalClose }
        handleChange={ handleCreateNewCustomer }
        title='加入用戶'
      />
    </Layout>
  );
}

export default UserGroupList;
