import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space } from 'antd';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerSwitch from '../../../layouts/CustomerSwitch';
import CustomerInput from '../../../layouts/CustomerInput';
import { inputValidator } from '../../../../services/validotor';

import '../../../../styles/components/DrawerForm.css';

const FunctionQuestionDrawerForm = ({
  isOpen,
  isEdit,
  editData,
  handleClose,
  handleSave,
  handleUpdate,
}) => {
  const [isActive, setIsActive] = useState(false);

  const { requiredValidator, symbolValidator } = inputValidator();

  useEffect(() => {
    if (editData.isActive) {
      setIsActive(editData.isActive);
    }
  }, [editData]);

  const handleClickToSave = value => {
    isEdit ? handleUpdate(value) : handleSave(value);
  };

  const changeIsActive = checked => {
    setIsActive(checked);
  };

  const handleDrawerClose = () => {
    handleClose();
    setIsActive(false);
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯權限' : '新增權限'}
        width={700}
        onClose={handleDrawerClose}
        visible={isOpen}
        destroyOnClose
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <Space>
            <CustomerButton onClick={handleDrawerClose}>取消</CustomerButton>
            <CustomerButton
              form='function-question-drawer-from'
              htmlType='submit'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          id='function-question-drawer-from'
          name='form'
          layout='horizontal'
          onFinish={handleClickToSave}
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 4 }}
          initialValues={{
            permissionName: isEdit ? editData.permissionName : '',
            permissionCode: isEdit ? editData.permissionCode : '',
            permissionPath: isEdit ? editData.permissionPath : '',
            isActive: isEdit ? editData.isActive : isActive,
          }}>
          <Form.Item
            name='permissionName'
            label='權限名稱'
            rules={[requiredValidator('權限名稱'), symbolValidator()]}>
            <CustomerInput placeholder='請輸入權限名稱' maxLength={20}/>
          </Form.Item>

          <Form.Item
            name='permissionCode'
            label='權限代碼'
            rules={[requiredValidator('權限代碼'), symbolValidator()]}>
            <CustomerInput placeholder='請輸入權限代碼' />
          </Form.Item>


          {/*
          看不懂這功能要幹嘛 先拿掉了= = 
          <Form.Item
            name='permissionPath'
            label='新增路徑'
            rules={[requiredValidator('新增路徑')]}>
            <CustomerInput placeholder='請輸入路徑' />
          </Form.Item> */}

          <Form.Item label='狀態' valuePropName='checked'>
            <Form.Item name='isActive'>
              <CustomerSwitch onChange={changeIsActive} checked={isActive} />
            </Form.Item>
            <span>{isActive ? '有效' : '無效'}</span>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default FunctionQuestionDrawerForm;
