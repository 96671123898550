import {
  SET_BREAD_CRUMB
} from '../types';

export const setBreadcrumb = (routeList) => {
  return {
    type: SET_BREAD_CRUMB,
    payload: {
      routeList
    }
  };
};
