import { message } from 'antd';

export const sendSuccessMessage = (content) => {

  const config = {
    key: 'errMsgKey',
    type: 'success',
    content: content,
    duration: 5,
    maxCount: 1,
    style: {
      position: 'relative',
      top: 50,
    },
  };

  message.open(config);
};

export const sendErrorMessage = (content) => {

  const config = {
    key: 'errMsgKey',
    type: 'error',
    content: content,
    duration: 5,
    maxCount: 1,
    style: {
      position: 'relative',
      top: 50,
    },
  };

  message.open(config);
};
