import React, { useState, useEffect } from 'react';
import { Drawer, Form, Space, Select } from 'antd';

import '../../../../styles/components/DrawerForm.css';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import CustomerCheckBox from '../../../layouts/CustomerCheckBox';
import CustomerRadio from '../../../layouts/CustomerRadio';
import { inputValidator } from '../../../../application/validator';

const MenuDrawerForm = ({
  isOpen,
  isEdit,
  editData,
  handleClose,
  handleSave,
  handleUpdate,
  handleSetIsOnlyAddFunction,
  form,
}) => {
  const { Option } = Select;
  const { requiredValidator, symbolValidator, selectValidator } =
    inputValidator();
  const [isLoginShow, setLoginShow] = useState(0);
  const [isOnlyAddFunction, setOnlyAddFunction] = useState(false);

  useEffect(() => {
    if (editData.isOnlyAddFunction) {
      setOnlyAddFunction(editData.isOnlyAddFunction);
    }
  }, [editData]);

  const handleClickToSave = value => {
    console.log(value);
    const val = {
      ...value,
      isOnlyAddFunction: isOnlyAddFunction,
    };
    console.log(val.isOnlyAddFunction);
    isEdit ? handleUpdate(val) : handleSave(val);
  };

  const changeAddFunction = e => {
    setOnlyAddFunction(e.target.checked);
    if (e.target.checked) {
      handleSetIsOnlyAddFunction();
    }
  };

  const onRadioChange = e => {
    setLoginShow(e.target.value);
  };

  const handleFormClose = () => {
    handleClose();
    setOnlyAddFunction(false);
    setLoginShow(1);
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯選單' : '新增選單'}
        width={700}
        onClose={handleFormClose}
        visible={isOpen}
        footer={
          <Space>
            <CustomerButton onClick={handleFormClose}>取消</CustomerButton>
            <CustomerButton
              form='menu-function-drawer-form'
              color='blue'
              htmlType='submit'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='menu-function-drawer-form'
          layout='horizontal'
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 4 }}
          onFinish={handleClickToSave}
          initialValues={{
            title: '',
            isOnlyAddFunction: false,
            needLoginDisplay: 1,
            hierarchy: null,
            remark: '',
          }}>
          <Form.Item
            label='選單名稱'
            name='title'
            className='menu-form-with-checkbox'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput placeholder='請輸入選單名稱' maxLength={20} />
          </Form.Item>

          <Form.Item
            wrapperCol={{ offset: 4 }}
            name='isOnlyAddFunction'
            className='menu-form-with-checkbox'
            >
            <CustomerCheckBox
              disabled={isEdit}
              onChange={changeAddFunction}
              checked={isOnlyAddFunction}>
              此選單僅能加入功能
            </CustomerCheckBox>
          </Form.Item>

          <Form.Item label='登入後顯示' name='needLoginDisplay'>
            <CustomerRadio
              onChange={onRadioChange}
              value={isLoginShow}
              options={['是', '否']}
            />
          </Form.Item>

          <Form.Item
            label='顯示階層'
            name='hierarchy'
            rules={[selectValidator()]}>
            <Select
              // value={showLayer}
              // onChange={changeShowLayer}
              disabled={isEdit || isOnlyAddFunction}
              placeholder='請選擇'>
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
            </Select>
          </Form.Item>

          <Form.Item label='註記' name='remark'>
            <CustomerInput />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default MenuDrawerForm;
