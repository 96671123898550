import {
  SEND_SUCCESS_MESSAGE,
  SEND_ERROR_MESSAGE,
  OPEN_LOGOUT_MODAL
} from '../types';

export const sendSuccessMessage = (message) => {
  return {
    type: SEND_SUCCESS_MESSAGE,
    payload: { 
      message,
    }
  };
};

export const sendErrorMessage = (message) => {
  return {
    type: SEND_ERROR_MESSAGE,
    payload: { 
      message,
    }
  };
};

export const setLogoutModalOpen = () => {
  return {
    type: OPEN_LOGOUT_MODAL,
    payload: {
      isLogoutModalOpen: true
    }
  };
};
