import React, { useState, useEffect } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Layout, Select } from 'antd';
import { useParams, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import PlatformManageTabs from '../../components/menu/platform/PlatformManageTabs';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useUpdatePlatformMenu } from '../../../application/menu/useUpdatePlatformMenu';
import { useWebMenu } from '../../../application/menu/useWebMenu';
import { useWebFunctions } from '../../../application/menu/useWebFunctions';

import '../../../styles/pages/SitePage.css';

function PlatformManage() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const { Option } = Select;
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const { name, id } = useParams();
  const {
    updateFunction,
    desktopMainId,
    desktopPersonalId,
    mobileMainId,
    mobilePersonalId,
    getPlatformPreview,
    modeOptions,
    desktopTypeOptions,
    mobileTypeOptions
  } = useUpdatePlatformMenu(query.get('code'));
  const { webMenuList } = useWebMenu();
  const { webFunctionsList } = useWebFunctions();

  const [mode, setMode] = useState('');
  const [type, setType] = useState('');
  // const [isDisplayMainType, setIsDisplayMainType] = useState(true);
  // const [isDisplayPersonalType, setIsDisplayPersonalType] = useState(true);

  const [previewData, setPreviewData] = useState([]);

  useEffect(() => {
   
    if (modeOptions.length > 0) {
      if (modeOptions[0].value === 'desktop') {
        setMode('desktop');
        setType(desktopTypeOptions[0].value);
      } else {
        setMode('mobile');
        setType(mobileTypeOptions[0].value);
      }
        
    }
    //setIsDisplayMainType(desktopMainId ? true : false);
    // setIsDisplayPersonalType(desktopPersonalId ? true : false);
  // eslint-disable-next-line
  }, [mobileTypeOptions]);

  useEffect(() => {

    dispatch(setBreadcrumb([
      {
        path: '',
        pageName: '選單管理'
      },
      {
        path: '/menu/platform',
        pageName: '平台來源管理'
      },
      {
        path: `/menu/platform/${ id }/${ name }`,
        pageName: `${ name }`
      }
    ]));

  }, [dispatch, id, name]);

  useEffect(() => {
    if (mode === 'desktop') {
      setType(desktopTypeOptions[0].value);
       displayPlatformMenuPreview();
    } else if (mode === 'mobile') {
      setType(mobileTypeOptions[0].value);
       displayPlatformMenuPreview();
    }
  // eslint-disable-next-line
  }, [mode, desktopTypeOptions, mobileTypeOptions]);

  const displayPlatformMenuPreview = async() => {
    const menuPreview = await getPlatformPreview(mode, type);
    setPreviewData(menuPreview);
  };

  const handleChange = async({ isSingle, menuData }) => {
    console.log(isSingle);
    let menuId;
    if (mode === 'desktop' && type === 'main') {
      menuId = desktopMainId;
    } else if (mode === 'desktop' && type === 'personal') {
      menuId = desktopPersonalId;
    } else if (mode === 'mobile' && type === 'main') {
      menuId = mobileMainId;
    } else {
      menuId = mobilePersonalId;
    }
    const result = await updateFunction(menuId, menuData);
    if (!result) {
      sendErrorMessage('編輯失敗');
      return;
    }
    sendSuccessMessage('編輯成功');
    /* if (isSingle) {
      data = menuData;
      const result = await updateFunction(menuId, data);
      if (!result) {
        console.log('更新失敗');
        return;
      }
      console.log('更新成功');
    } else {
      data = menuData;
      const result = await updateFunction(menuId, data);
      if (!result) {
        console.log('更新失敗');
        return;
      }
      console.log('更新成功');
    } */
    
  };

  const handleChangeMode = async(value) => {
    /* if (value === 'desktop') {
      setIsDisplayMainType(desktopMainId ? true : false);
      setIsDisplayPersonalType(desktopPersonalId ? true : false);
    } else {
      setIsDisplayMainType(mobileMainId ? true : false);
      setIsDisplayPersonalType(mobilePersonalId ? true : false);
    }*/
    setMode(value);
    const menuPreview = await getPlatformPreview(value, type);
    setPreviewData(menuPreview);
  };

  const handleChangeType = async(value) => {
    setType(value);
    const menuPreview = await getPlatformPreview(mode, value);
    setPreviewData(menuPreview);
  };


  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title sitebar-content-title-platform'>
            <h1 style={{ borderRight: '1px solid #ccc', paddingRight: 22 }}>
              { name }
            </h1>
            <Select
              value={ mode }
              style={{ width: 120 }}
              bordered={ false }
              onChange={ handleChangeMode }
            >
              {/* desktopMainId || desktopPersonalId ? (<Option value="desktop">電腦版</Option>) : null }
              { mobilePersonalId || mobileMainId ? (<Option value="mobile">手機版</Option>) : null */}
              {
                modeOptions.map((option, idx) => (
                  <Option key={ idx } value={ option.value }>
                    { option.text }
                  </Option>
                ))
              }
            </Select>
            <Select 
              value={ type } 
              style={{ width: 120 }} 
              bordered={ false }
              onChange={ handleChangeType }
            >
              {/* isDisplayMainType ? (<Option value="main">主選單</Option>) : null }
              { isDisplayPersonalType ? (<Option value="personal">個人選單</Option>) : null */}
              {
                mode === 'desktop' ? 
                  desktopTypeOptions.map((typeOption, index) => (
                    <Option key={ index } value={ typeOption.value }>
                      { typeOption.text }
                    </Option>
                  )) :
                  mobileTypeOptions.map((typeOption, index) => (
                    <Option key={ index } value={ typeOption.value }>
                      { typeOption.text }
                    </Option>
                  ))
              }
            </Select>
            <div style={{ marginLeft: '50%' }}><InfoCircleFilled /> 選單功能最多三層結構</div>
          </div>
          <div>
            <PlatformManageTabs
              previewData={ previewData }
              menuOptions={ webMenuList }
              functionOptions={ webFunctionsList }
              handleChangeToSave={ handleChange }
            />
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default PlatformManage;
