import React from 'react';
import GitInfo from 'react-git-info/macro';
import moment from 'moment';
import { Layout, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  useUserLogin,
  useUserName
} from '../../application/users/useUserLogin.js';

import '../../styles/layouts/SiteBar.css';

const { Header } = Layout;

const LogoutMenu = () => {

  const { logout } = useUserLogin();

  const handleClickToLogout = () => {
    const isLogoutSuccessful = logout();
    if (isLogoutSuccessful) {
      window.location = '/cms/users/signin';
    }
  };

  return (
    <>
      <Menu>
        <Menu.Item key="0">
          <span style={{ cursor: 'pointer' }} onClick={ handleClickToLogout }>
            登出
          </span>
        </Menu.Item>
      </Menu>
    </>
  );
};

const SiteBar = () => {

  const gitInfo = GitInfo();

  const { staffNo, userName } = useUserName();

  return (
    <Layout className='sitebar-container'>
      <Header className='sitebar-header'>
        <p>後台管理系統 { `Version: ${ gitInfo.commit.shortHash }, Updated Date: ${ moment(new Date('2023-04-28 15:35:00')).format('YYYY/MM/DD HH:mm:ss') }` }</p>
        <div className='sitebar-header-user'>
          <div className='sitebar-user-avatar'>
            { staffNo }
          </div>
          <div className='sitebar-user-name'>
            { userName }
          </div>
          <Dropdown trigger={['click']} overlay={ LogoutMenu }>
            <div style={{ cursor: 'pointer' }}>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>

      </Header>
    </Layout>
  );
};

export default SiteBar;
