import React from 'react';
import { Input } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import '../../styles/layouts/CustomerSearchInput.css';

const CustomerSearchInput = ({
  placeholder,
  onSearch,
  buttonText,
  isLoading,
  ...props
}) => {
  const { Search } = Input;
  return (
    <div className='customer-search-input-container'>
      <Search
        disabled={isLoading ? true : false}
        className={
          isLoading ? 'customer-search-input-loading' : 'customer-search-input'
        }
        placeholder={placeholder}
        onSearch={onSearch}
        enterButton={isLoading ? <SyncOutlined spin /> : buttonText}
        size='large'
        {...props}
      />
    </div>
  );
};

export default CustomerSearchInput;
