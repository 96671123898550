import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useDispatch } from 'react-redux';
// import { CheckOutlined, SyncOutlined } from '@ant-design/icons';

import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import ProductDataTable from '../../components/product/ProductDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import ProductDrawerForm from '../../components/product/ProductDrawerForm';
// import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useRequestProduct } from '../../../application/product/useRequestProduct';
import useRequestPostFinancialProduct from '../../../application/product/useRequestProduct';

import '../../../styles/pages/SitePage.css';

function ProductMainPage() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [form] = Form.useForm();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [editData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading] = useState(false);
  const [enterBtnText] = useState('確認檢核');
  const {
    productList,
    // getProductCategories,
    // postProductCategories,
    // putProductCategories,
    // deleteProductCategories,
    // getVerifyProductCategoryCode,
  } = useRequestProduct();

  const { getFinancialProductCategoryDetail } =
    useRequestPostFinancialProduct();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '商品管理',
        },
        {
          path: '/product',
          pageName: '商品分類管理',
        },
      ])
    );
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  // 新增
  // const handleSaveCreate = async params => {
  //   const result = await postProductCategories(params);
  //   if (!result) {
  //     sendErrorMessage('新增失敗');
  //     return;
  //   }
  //   sendSuccessMessage('新增成功');
  //   getProductCategories();
  //   handleDrawerClose();
  // };

  // 刪除
  // const handleDelete = async id => {
  //   const result = await deleteProductCategories(id);
  //   if (result !== true) {
  //     sendErrorMessage(result);
  //   } else {
  //     sendSuccessMessage('刪除成功');
  //     getProductCategories();
  //   }
  // };

  // const handleEdit = async params => {
  //   const result = await putProductCategories(params);
  //   if (!result) {
  //     sendErrorMessage('編輯失敗');
  //     return;
  //   }
  //   sendSuccessMessage('編輯成功');
  //   getProductCategories();
  //   handleDrawerClose();
  //   setIsEdit(false);
  // };

  // 驗證分類代碼
  // const handleVerifyCode = async value => {
  //   setIsLoading(true);
  //   setEnterBtnText(<SyncOutlined spin />);
  //   const status = await getVerifyProductCategoryCode(value);
  //   const errorMsg = form.getFieldError('categoryCode');
  //   const checkCode = productList.filter(data => {
  //     return data.categoryCode === value;
  //   });
  //   if (checkCode.length > 0) {
  //     form.setFields([
  //       { name: 'categoryCode', errors: ['分類代碼名稱不可重複。'] },
  //     ]);
  //     setEnterBtnText('確認檢核');
  //   } else if (!errorMsg && !status) {
  //     form.setFields([{ name: 'categoryCode', errors: ['請檢查輸入內容。'] }]);
  //     setEnterBtnText('確認檢核');
  //   } else {
  //     setEnterBtnText(<CheckOutlined />);
  //     setEnterBtnText('重新確認');
  //   }
  //   setTimeout(() => {
  //     setEnterBtnText('確認檢核');
  //   }, 5000);
  //   setIsLoading(false);
  // };

  const handleEditClick = async id => {
    const res = await getFinancialProductCategoryDetail(id);
    form.setFieldsValue({
      categoryCode: res.categoryCode,
      categoryName: res.categoryName,
      isActive: res.isActive,
    });
    // setEditData(res);
    setIsEdit(true);
    handleDrawerOpen();
  };

  // const handleDragSort = async params => {
  //   const result = await putProductCategories(params);
  //   if (!result) {
  //     sendErrorMessage('排序失敗');
  //     return;
  //   } else {
  //     sendSuccessMessage('排序成功');
  //     getProductCategories();
  //   }
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>商品分類管理</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增分類
            </CustomerButton>
          </div>
          <ProductDataTable
            tableData={productList}
            // handleDeleteClick={handleDelete}
            handleEditClick={handleEditClick}
            // handleDragSort={handleDragSort}
          />
        </div>
      </Content>
      <ProductDrawerForm
        isEdit={isEdit}
        isOpen={isDrawerOpen}
        isLoading={isLoading}
        enterBtnText={enterBtnText}
        handleClose={handleDrawerClose}
        // handleSave={handleSaveCreate}
        form={form}
        // handleVerifyCode={handleVerifyCode}
        // handleUpdate={handleEdit}
        editData={editData}
      />
    </Layout>
  );
}

export default ProductMainPage;
