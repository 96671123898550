import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';

import PlatformDataTable from '../../components/menu/platform/PlatformDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import PlatformEditDrawerForm from '../../components/menu/platform/PlatformCreateDrawer';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import { useMenuPlatform } from '../../../application/menu/usePlatform';

import '../../../styles/pages/SitePage.css';

function Platform() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '選單管理',
        },
        {
          path: '/menu/platform',
          pageName: '平台來源管理',
        },
      ])
    );
    form.setFieldsValue({
      platformName: '',
      platformCode: '',
      isDesktopMainActive: true,
      isDesktopMainDisplay: 1,
      isDesktopPersonalActive: false,
      isDesktopPersonalDisplay: 1,
      isMobileMainActive: false,
      isMobileMainDisplay: 1,
      isMobilePersonalActive: false,
      isMobilePersonalDisplay: 1,
    });
  // eslint-disable-next-line
  }, [dispatch]);

  const {
    platformList,
    getPlatform,
    createPlatform,
    updatePlatform,
    deletePlatform,
  } = useMenuPlatform();

  const [isEdit, setIsEdit] = useState(false);
  const [selectEditData, setSelectEditData] = useState({});
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.setFieldsValue({
      platformName: '',
      platformCode: '',
      isDesktopMainActive: true,
      isDesktopMainDisplay: 1,
      isDesktopPersonalActive: false,
      isDesktopPersonalDisplay: 1,
      isMobileMainActive: false,
      isMobileMainDisplay: 1,
      isMobilePersonalActive: false,
      isMobilePersonalDisplay: 1,
    });
  };

  const handleDeleteClick = async id => {
    const result = await deletePlatform(id);
    if (!result) {
      sendErrorMessage('刪除失敗');
    } else {
      sendSuccessMessage('刪除成功');
      await getPlatform();
    }
  };

  const handleCreateNewPlatform = async params => {
    const result = await createPlatform(params);
    if (result === true) {
      sendSuccessMessage('新增成功');
      handleDrawerClose();
      getPlatform();
    } else {
      sendErrorMessage('新增失敗');
    }
  };

  const handleEditPlatform = async params => {
    const result = await updatePlatform(params);
    if (result === true) {
      sendSuccessMessage('編輯成功');
      handleDrawerClose();
      setIsEdit(false);
      getPlatform();
    } else {
      sendErrorMessage('編輯失敗');
    }
  };

  const handleEditClick = data => {
    setIsEdit(true);
    setSelectEditData(data);
    console.log(data);
    form.setFieldsValue({
      platformName: data.platformName,
      platformCode: data.platformCode,
      isDesktopMainActive: data.desktop.main.isActive,
      isDesktopMainDisplay: data.desktop.main.needLoginDisplay ? 0 : 1,
      isDesktopPersonalActive: data.desktop.personal.isActive,
      isDesktopPersonalDisplay: data.desktop.personal.needLoginDisplay ? 0 : 1,
      isMobileMainActive: data.mobile.main.isActive,
      isMobileMainDisplay: data.mobile.main.needLoginDisplay ? 0 : 1,
      isMobilePersonalActive: data.mobile.personal.isActive,
      isMobilePersonalDisplay: data.mobile.personal.needLoginDisplay ? 0 : 1,
    });
    handleDrawerOpen();
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>平台來源管理</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增平台
            </CustomerButton>
          </div>
          <PlatformDataTable
            tableData={platformList}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        </div>
      </Content>
      <PlatformEditDrawerForm
        form={form}
        isOpen={isDrawerOpen}
        isEdit={isEdit}
        editData={selectEditData}
        handleClose={handleDrawerClose}
        handleSave={handleCreateNewPlatform}
        handleEdit={handleEditPlatform}
      />
    </Layout>
  );
}

export default Platform;
