/**
 * [define User Management]
 * @param  {Number} options.id            [user id]
 * @param  {String} options.staffName     [staff number]
 * @param  {String} options.staffNo       [staff name]
 * @param  {Array}  options.group         [groups of staff user]
 * @param  {Boolean} options.isActive     [active status]
 * @param  {Date} options.lastLoginTime   [last login time YYYY/MM/DD hh:mm:ss]
 * @param  {[Date} options.createdAt      [created time YYYY/MM/DD hh:mm:ss]
 * @param  {Date} options.updatedAt       [updated time YYYY/MM/DD hh:mm:ss]
 * @return {User}                         [user data]
 */
export const defineUserManagement = ({
  id,
  staffName,
  staffNo,
  groups = [],
  isActive,
  lastLoginTime,
  createdAt,
  updatedAt
}) => {
  return {
    id,
    staffName,
    staffNo,
    groups,
    isActive,
    lastLoginTime,
    createdAt,
    updatedAt
  };
};

export const userAccountSchema = {
  title: 'userAccount',
  type: 'object',
  required: [],
  properties: {
    staffName: {
      type: 'string'
    },
    staffNo: {
      type: 'string'
    },
    group: {
      type: 'array',
      uniqueItems: true,
      items: {
        type: 'integer'
      }
    },
    isActive: {
      type: 'boolean'
    },
    lastLoginTime: {
      type: 'string'
    },
    createdAt: {
      type: 'string'
    },
    updatedAt: {
      type: 'string'
    }
  }
};

