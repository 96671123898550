/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Drawer, Form, Space } from 'antd';

import CustomerButton from '../../layouts/CustomerButton';
import CustomerInput from '../../layouts/CustomerInput';
import CustomerSwitch from '../../layouts/CustomerSwitch';
import CustomerSearchInput from '../../layouts/CustomerSearchInput';
import { inputValidator } from '../../../application/validator';

import '../../../styles/components/DrawerForm.css';

const PlatformDrawerForm = ({
  isOpen,
  handleClose,
  handleSave,
  handleEdit,
  editData,
  isEdit,
  isLoading,
  form,
  enterBtnText,
  handleSearch,
}) => {
  const {
    requiredValidator,
    symbolValidator,
    lowerCaseValidator,
    minLenValidator,
  } = inputValidator();

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (editData) {
      setIsActive(editData.isActive);
    }
  }, [editData]);

  const handleClickToSave = value => {
    isEdit ? handleEdit(value) : handleSave(value);
  };

  const changeSwitchStatus = checked => {
    setIsActive(checked);
  };

  const searchCode = (val) => {
    if (form.getFieldError('categoryCode').length <= 0) {
      handleSearch(val);
    }
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯分類' : '新增分類'}
        width={700}
        onClose={handleClose}
        visible={isOpen}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              form='platform-drawer'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          id='platform-drawer'
          form={form}
          layout='horizontal'
          onFinish={handleClickToSave}
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 5 }}
          initialValues={{
            categoryCode: '',
            categoryName: '',
            isActive: isActive,
          }}>
          <Form.Item
            label='分類代碼'
            name='categoryCode'
            rules={
              isEdit
                ? []
                : [
                    requiredValidator(),
                    lowerCaseValidator(),
                    minLenValidator(2),
                  ]
            }>
            {isEdit ? (
              <CustomerInput disabled={true} />
            ) : (
              <CustomerSearchInput
                maxLength={63}
                placeholder='請輸入分類英文名稱 (建立網址使用)'
                // onSearch={handleSearch}
                onSearch={searchCode}
                enterButton={enterBtnText}
                allowClear
                isLoading={isLoading}
              />
            )}
          </Form.Item>

          <Form.Item
            label='分類名稱'
            name='categoryName'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput placeholder='請輸入分類中文名稱' />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive'>
              <CustomerSwitch
                onChange={changeSwitchStatus}
                checked={isActive}
              />
            </Form.Item>
            {isActive === true ? <span>顯示</span> : <span>隱藏</span>}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default PlatformDrawerForm;
