import React from 'react';
import { Modal, Collapse } from 'antd';
import { FileOutlined, PlusSquareFilled, CloseSquareFilled } from '@ant-design/icons';
import CustomerButton from '../../../layouts/CustomerButton';
import '../../../../styles/layouts/QuestionPreviewModal.css';
import parse from 'html-react-parser';
import moment from 'moment';

const ListPreviewModal = ({ isOpen, handleClose, data }) => {

  const { Panel } = Collapse;


  return (
    <Modal
      className='question-preview-modal'
      centered
      visible={ isOpen }
      title={ data.title }
      onCancel={ handleClose }
      width={ 777 }
      destroyOnClose
      footer={false}
    >
      <div className='question-preview-category'>
        問題類別： <span>{ data.firstCategory }</span>
        <span>{ data.secondCategory }</span>
        <span>最後更新時間： { moment(new Date()).format('YYYY/MM/DD') }</span>
      </div>
      <hr />
      <div className='question-modal-body'>
        { /* data.html && parse(data.html) */
          data.toggleList.map((item, idx) => (
            item.type === 'toggle' ? 
            (
              <div className="toggle-section" key={ idx }>
                <Collapse
                  expandIconPosition="right"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <CloseSquareFilled style={{ fontSize: '150%' }} />
                    ) : (
                      <PlusSquareFilled style={{ fontSize: '150%' }} />
                    )
                  }
                >
                  <Panel header={ item.content.title } key="1">
                    <p>{ item.content.content }</p>
                  </Panel>
                </Collapse>
              </div>
            ) :
            (
              <article key={ idx }>
                { parse(item.content.content) }
              </article>
            )
          ))
        }
        {
        data?.fileList?.map((file, idx) => (
          <main className="preview-download-list" key={ idx }>
            <div>
              <FileOutlined /> { file.name }
            </div>
            <div>
              <CustomerButton color='red'>
                下載
              </CustomerButton>
            </div>
          </main>
        ))
      }
      </div>
      
      
    </Modal>
  );
};

export default ListPreviewModal;
