import React from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/signin/logo.png';
import Ad from '../../assets/images/siderbar/siderbar-ad.svg';

import {
  QuestionCircleOutlined,
  UserOutlined,
  RetweetOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';

import { useMenu } from '../../application/menu/useMenu';

import '../../styles/layouts/SiderBar.css';

const { SubMenu } = Menu;
const { Sider } = Layout;

const MeunIcon = ({groupName}) => {
  switch (groupName) {
    case '廣告管理':
      return (<img src={Ad} style={{width: 16, color: '#fff'}} alt="icon"/>);
    case '交易平台管理':
      return (<RetweetOutlined style={{ fontSize: 16, color: '#fff' }} />);
    case '商品管理':
      return (<DollarCircleOutlined style={{ fontSize: 16, color: '#fff' }} />);
    case '常見問題管理':
      return (<QuestionCircleOutlined style={{ fontSize: 16, color: '#fff' }} />);
    case '權限管理':
      return (<UserOutlined style={{ fontSize: 16, color: '#fff' }} />);
    case '選單管理':
      return (<SiderbarStackIcon style={{ fontSize: 16, color: '#fff' }} />);
    default:
      return (<SiderbarStackIcon style={{ fontSize: 16, color: '#fff' }} />);
  }
};

const NavBar = () => {
  const { menuList } = useMenu();

  return (
    <Sider width={200} className='sider-container'>
      <div className='siderbar-header'>
        <img
          src={logo}
          alt='elk-tree-logo'
        />
      </div>
      {/* 交易平台管理跟理財商品管理99%像故隱藏, 選單管理(拖曳介面)太複雜隱藏  */}
      <Menu
        className='sider-menu-group'
        mode='inline'
        theme='dark'
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        style={{ height: '100%', borderRight: 0 }}>
        {
          menuList.map((menuItem) => (
            (menuItem.permissions?.length > 1 && !menuItem.hidden) ?
            (
              <SubMenu
                key={ `${ menuItem.groupName }` }
                icon={ <MeunIcon groupName={ menuItem.groupName } /> }
                title={ menuItem.groupName }
              >
                {
                  menuItem?.permissions.map((permissionItem) => (
                    <Menu.Item key={ permissionItem.permissionCode }>
                      <Link to={ permissionItem.permissionPath }>
                        { permissionItem.permissionName }
                      </Link>
                    </Menu.Item>
                  ))
                }
              </SubMenu>
            ) : 
            (
              !menuItem.hidden ?
              <Menu.Item
                key={ `${ menuItem.groupName }` }
                icon={ <MeunIcon groupName={ menuItem.groupName } /> }
                title={ menuItem.groupName }
              >
                <Link to={ menuItem?.permissions[0]?.permissionPath }>
                  { menuItem?.permissions[0]?.permissionName }
                </Link>
              </Menu.Item>
            : '')
          ))
        }
        
      </Menu>
    </Sider>
  );
};

export default NavBar;

function SiderbarStackIcon() {
  return (
    <svg
      width='20'
      height='20'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <defs>
        <path id='a' d='M0 0h20v20H0z' />
        <path
          d='M1.125 7.032 0 7.917l6.25 4.917 6.25-4.917-1.132-.892-5.125 4.032-5.118-4.025zm0 3L0 10.915l6.25 4.917 6.25-4.917-1.132-.892-5.125 4.032-5.118-4.025zm10.236-4.223 1.139-.892L6.25 0 0 4.917l1.132.892L6.25 9.834l5.111-4.025z'
          id='c'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='b' fill='#fff'>
          <use xlinkHref='#a' />
        </mask>
        <g mask='url(#b)'>
          <g transform='translate(3.333 1.667)'>
            <mask id='d' fill='#fff'>
              <use xlinkHref='#c' />
            </mask>
            <use fill='#2E3A59' xlinkHref='#c' />
            <g mask='url(#d)'>
              <path d='M-1.786-.88h16.964v16.713H-1.786z' fill='#FFF' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
