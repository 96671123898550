import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import AdDataTable from '../../components/advertisement/AdDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import AdDrawerForm from '../../components/advertisement/AdDrawerForm';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useRequestAdSlot } from '../../../application/advertisement/useRequestAdSlot';
import { useRequestAd } from '../../../application/advertisement/useRequestAd';

import '../../../styles/pages/SitePage.css';
import moment from 'moment';

function AdSlotPage() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const [form] = Form.useForm();

  const {adSlotId, adSlotName} = useParams();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [adData, setAdData] = useState([]);

  const { getAdSlot } = useRequestAdSlot();

  const { getAd, postAd, putAd, deleteAd } = useRequestAd();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '廣告管理',
        },
        {
          path: '/advertisement',
          pageName: '廣告廣告版位管理',
        },
        {
          path: `/advertisement/${ adSlotId }/${ adSlotName }`,
          pageName: adSlotName,
        },
      ])
    );
  }, [dispatch, adSlotId, adSlotName]);

  useEffect(() => {
    const fetchSlotData = async() => {
      const res = await getAdSlot(adSlotId);
      // this two lines are for mockup
      const index = res.findIndex(i => i.id === Number(adSlotId));
      const slotData = res[index];
      setAdData(slotData.ads);
    };
    fetchSlotData();
  // eslint-disable-next-line
  }, [adSlotId]);

  // console.log(adData);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  const handleSaveCreate = async params => {
    const result = await postAd(params);
    if (!result) {
      sendErrorMessage('新增失敗');
      return;
    }
    sendSuccessMessage('新增成功');
    const res = await getAdSlot(adSlotId);
    setAdData(res.ads);
    handleDrawerClose();
  };

  const handleDelete = async id => {
    const result = await deleteAd(id);
    if (result !== true) {
      sendErrorMessage(result);
    } else {
      sendSuccessMessage('刪除成功');
      const res = await getAdSlot(adSlotId);
      setAdData(res.ads);
    }
  };

  const handleEdit = async params => {
    const result = await putAd(params);
    if (!result) {
      sendErrorMessage('編輯失敗');
      return;
    }
    sendSuccessMessage('編輯成功');
    handleDrawerClose();
    const res = await getAdSlot(adSlotId);
    setAdData(res.ads);
    setIsEdit(false);
  };

  const handleEditClick = async id => {
    const res = await getAd(id, adSlotId);
    form.setFieldsValue({
      adName: res.adName,
      desktopImagePath: [
        {
          uid: res.desktopImagePath,
          name: res.desktopImagePath,
          status: 'done',
          url: `${ process.env.REACT_APP_IMAGE_URL }/${ res.desktopImagePath }`,
        },
      ],
      mobileImagePath: [
        {
          uid: res.mobileImagePath,
          name: res.mobileImagePath,
          status: 'done',
          url: `${ process.env.REACT_APP_IMAGE_URL }/${ res.mobileImagePath }`,
        },
      ],
      timepicker: [
        moment(res.startTime),
        moment(res.endTime),
      ],
      url: res.url,
      isBlank: res.isBlank === true ? 0 : 1,
      isActive: res.isActive,
    });
    setEditData(res);
    setIsEdit(true);
    handleDrawerOpen();
  };

  // const handleDragSort = async params => {
  //   console.log('handleDragSort-outside', params);
  //   const result = await putAd(params);
  //   if (!result) {
  //     sendErrorMessage('排序失敗');
  //     return;
  //   } else {
  //     sendSuccessMessage('排序成功');
  //     const res = await getAdSlot(adSlotId);
  //     setAdData(res.ads);
  //   }
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content '>
          <div className='sitebar-content-title'>
            <h1>{adSlotName}</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              新增廣告圖片
            </CustomerButton>
          </div>
          {/* 廣告版位 - 版位n內的所有廣告 */}
          {/* handleDragSort要打排序API 故排序的話會失敗 先隱藏 */}
          <AdDataTable
            tableData={adData}
            handleDeleteClick={handleDelete}
            handleEditClick={handleEditClick}
            // handleDragSort={handleDragSort}
          />
        </div>
      </Content>
      <AdDrawerForm
        isEdit={isEdit}
        isOpen={isDrawerOpen}
        handleClose={handleDrawerClose}
        handleSave={handleSaveCreate}
        form={form}
        handleUpdate={handleEdit}
        editData={editData}
      />
    </Layout>
  );
}

export default AdSlotPage;
