import React from 'react';
import { Drawer, Form, Space } from 'antd';


import '../../../../styles/components/DrawerForm.css';

import CustomerButton from '../../../layouts/CustomerButton';
import CustomerInput from '../../../layouts/CustomerInput';
import CustomerSwitch from '../../../layouts/CustomerSwitch';
import { inputValidator } from '../../../../application/validator';

const CategoryCreateFirstDrawerForm = ({
  isEdit,
  isOpen,
  handleClose,
  handleSave,
  handleEdit,
  selectId,
  form,
}) => {
  
  const { requiredValidator, symbolValidator } = inputValidator();

  const handleClickToSave = value => {
    const editValue = {
      ...value,
      categoryId: selectId,
    };
    isEdit ? handleEdit(editValue) : handleSave(value);
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯第一層分類' : '新增第一層分類'}
        width={700}
        onClose={handleClose}
        visible={isOpen}
        footer={
          <Space>
            <CustomerButton onClick={handleClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              form='category1st-form'
              color='blue'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='category1st-form'
          onFinish={handleClickToSave}
          layout='horizontal'
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 6 }}
          initialValues={{
            categoryName: '',
            isActive: false,
          }}>
          <Form.Item
            label='第一層分類名稱'
            name='categoryName'
            required
            rules={[requiredValidator(), symbolValidator()]}
          >
            <CustomerInput placeholder='請輸入第一層分類名稱' />
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive' valuePropName='checked'>
              <CustomerSwitch />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isActive');
                return <span>{isActive ? '顯示' : '隱藏'}</span>;
              }}
            </Form.Item>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default CategoryCreateFirstDrawerForm;
