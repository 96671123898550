import { useState, useEffect } from 'react';
import {
  requestGetPlatformData,
  requestUpdatePlatformMenu,
  requestGetPlatformPreview
} from '../../services/menu/platformMenuService';

export function useUpdatePlatformMenu(platformCode) {

  const [desktopMainId, setDesktopMainId] = useState(null);
  const [desktopPersonalId, setDesktopPersonalId] = useState(null);
  const [mobileMainId, setMobileMainId] = useState(null);
  const [mobilePersonalId, setMobilePersonalId] = useState(null);
  const [modeOptions, setModeOptions] = useState([]);
  const [desktopTypeOptions, setDesktopTypeOptions] = useState([]);
  const [mobileTypeOptions, setMobileTypeOptions] = useState([]);

  useEffect(() => {
    getPlatformInformation();
    // eslint-disable-next-line
  }, []);
  
  const getPlatformInformation = async() => {
    const result = await requestGetPlatformData(platformCode);
    if (result.status) {
      setDesktopMainId(result.data.desktop.main.id && result.data.desktop.main.isActive ? result.data.desktop.main.id : null);
      setDesktopPersonalId(result.data.desktop.personal.id && result.data.desktop.personal.isActive ? result.data.desktop.personal.id : null);
      setMobileMainId(result.data.mobile.main.id && result.data.mobile.main.isActive ? result.data.mobile.main.id : null);
      setMobilePersonalId(result.data.mobile.personal.id && result.data.mobile.personal.isActive ? result.data.mobile.personal.id : null);
      
      const modeOptionsArray = [];
      if (result.data.desktop.main.isActive || result.data.desktop.personal.isActive) {
        modeOptionsArray.push({
          text: '電腦版',
          value: 'desktop'
        });
      }

      if (result.data.mobile.main.isActive || result.data.mobile.personal.isActive) {
        modeOptionsArray.push({
          text: '手機版',
          value: 'mobile'
        });
      }
      setModeOptions(modeOptionsArray);

      const desktopTypeArray = [];
      if (result.data.desktop.main.isActive) {
        desktopTypeArray.push({
          text: '主選單',
          value: 'main'
        });
      }
      if (result.data.desktop.personal.isActive) {
        desktopTypeArray.push({
          text: '個人選單',
          value: 'personal'
        });
      }
      console.log(desktopTypeArray);
      setDesktopTypeOptions(desktopTypeArray);

      const mobileTypeArray = [];
      if (result.data.mobile.main.isActive) {
        mobileTypeArray.push({
          text: '主選單',
          value: 'main'
        });
      }
      if (result.data.mobile.personal.isActive) {
        mobileTypeArray.push({
          text: '個人選單',
          value: 'personal'
        });
      }
      setMobileTypeOptions(mobileTypeArray);

    }
  };

  const getPlatformPreview = async(mode, type) => {
    const result = await requestGetPlatformPreview({ platformCode, mode, type });
    if (result.status) {
      return result.data;
    } else {
      return [];
    }
  };
  
  const updateFunction = async(menuId, updateFunctions) => {
    const items = [...updateFunctions];
    const result = await requestUpdatePlatformMenu(menuId, items);
    if (result.status) {
      return true;
    } else {
      return false;
    }
  };

  return {
    getPlatformInformation,
    desktopMainId,
    desktopPersonalId,
    mobileMainId,
    mobilePersonalId,
    updateFunction,
    getPlatformPreview,
    modeOptions,
    desktopTypeOptions,
    mobileTypeOptions
  };
}
