import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from '../api';
import { errorHandle } from '../api/errorHandle.js';

export async function requestGetWebMenuList() {
  const url = '/api/menu/groups';
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestGetMenuDetailsWithId(groupId) {
  const url = `/api/menu/group/${ groupId }`;
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data.result;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestCreateNewMenu({ title, remark, needLoginDisplay, isOnlyAddFunction, hierarchy }) {
  const url = '/api/menu/group';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await postRequest(url, {
      title,
      remark,
      needLoginDisplay,
      isOnlyAddFunction,
      hierarchy
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestUpdateMenu(body) {
  const url = '/api/menu/group';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await putRequest(url, body);
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}

export async function requestDeleteMenu(id) {
  const url = '/api/menu/group';
  const result = {
    status: false,
    data: null
  };
  try {
    result.data = await deleteRequest(url, {
      groupId: id,
    });
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}