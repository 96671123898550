import React, { useState, useEffect } from 'react';
import { Tabs, Tooltip } from 'antd';

import DraggableTabs from '../../../layouts/DraggableTabs';
import PlatformManageDrawerForm from './PlatformManageDrawer';
import PlatformManageSection from './PlatformManageSection';

import { MenuOutlined } from '@ant-design/icons';
import '../../../../styles/components/Tabs.css';

const { TabPane } = Tabs;

function PlatformManageTabs({ previewData, menuOptions, functionOptions, handleChangeToSave }) {
  
  const [panes, setPanes] = useState([]);
  const [newTabIndex, setNewTabIndex] = useState(panes.length);
  const [activeKey, setActiveKey] = useState('1');

  const [menuSavingData, setMenuSavingData] = useState([]);

  const [isManageDrawerFromOpen, setManageDrawerFromOpen] = useState(false);

  useEffect(() => {
    if (previewData.length > 0) {
      const panesData = [];
      const singleFunctionsItems = [];
      const menu = [];
      previewData.forEach((e, index) => {

        if (e.type === 'group') {
          // 2 + 3
          const paneItem = {
            mainMenuId: e.id,
            title: e.title,
            remark: e?.remark,
            key: (index + 1).toString(),
            items: e.items
          };
          panesData.push(paneItem);
          
          menu.push({
            type: 'group',
            id: e.id,
            mainMenu: e.title,
            remark: e?.remark,
            items: e.items
          });
          setMenuSavingData(menu);
        } else {
          // 1
          singleFunctionsItems.push(e);
        }
        
      });
      panesData.length > 0 ? setPanes(panesData) : setPanes([{
        mainMenuId: 0,
        title: '功能選單',
        key: '1',
        remark: '功能選單',
        items: singleFunctionsItems
      }]);
    } else {
      setPanes([]);
    }
  }, [previewData]);

  /* const onTest = () => {
    console.log('panes: ', panes);
    console.log('menuSavingData: ', menuSavingData);
  }; */

  const onChange = (activeKey) => {
    setActiveKey(activeKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === 'remove') {
      remove(targetKey);
    }

    if (action === 'add') {
      // add(targetKey);
      setManageDrawerFromOpen(true);
    }
  };

  const handleDrawerFormClose = () => {
    setManageDrawerFromOpen(false);
  };

  const add = (content) => {
    console.log(content);
    const menuInformation = menuOptions.filter(e => e.id === content.value)[0];
    console.log(menuInformation);
    const newTabIndex2 = newTabIndex + 1;
    const key = panes.length + 1;
    panes.push({
      mainMenuId: content.type === 'function' ? 0 : menuInformation.id,
      title: content.type === 'function' ? '功能選單' : menuInformation.title,
      key: key.toString(),
      remark: menuInformation?.remark,
      isOnlyAddFunction: menuInformation?.isOnlyAddFunction
    });
    if (content.type !== 'function') {
      menuSavingData.push({
        type: 'group',
        id: menuInformation.id,
        mainMenu: menuInformation.title,
        items: []
      });
      setMenuSavingData(menuSavingData);
    }
    
    setPanes(panes);
    setActiveKey(key.toString());
    setNewTabIndex(newTabIndex2);
  };

  const remove = (targetKey) => {
    let lastIndex;
    panes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });

    const newPanes = panes.filter(pane => pane.key !== targetKey);
    if (panes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        setActiveKey(panes[lastIndex].key);
      } else {
        setActiveKey(panes[0].key);
      }
    }
    setPanes(newPanes);
  };

  const handleChange = ({ isSingle, menuData }) => {
    console.log(isSingle);
    let data;
    if (isSingle) {
      console.log('tabs Data: ', menuData);
      console.log('menuSavingData: ', menuSavingData);
      const newSavingData = JSON.parse(JSON.stringify([...menuSavingData]));
      menuData.forEach((e) => {
        newSavingData.push(e);
      });
      data = menuData;
      handleChangeToSave({
        isSingle: false,
        menuData: newSavingData
      });
      console.log(data);
    } else {
      console.log('tabs Data: ', menuData);
      console.log('menuSavingData: ', menuSavingData);
      const newSavingData = JSON.parse(JSON.stringify([...menuSavingData]));
      newSavingData[newSavingData.findIndex(el => el.mainMenu === menuData.tabName)].items = menuData.updateData;
      
      console.log('newSavingData: ', newSavingData);
      /* if (menuData.length > 1) {
        // newSavingData[newSavingData.findIndex(el => el.id === menuData[0].id)] = menuData[0];
        console.log('newSavingData: ', newSavingData);
      } else {
        // newSavingData[newSavingData.findIndex(el => el.id === menuData[0].id)] = menuData[0];
        console.log('newSavingData: ', newSavingData);
        // setMenuSavingData(newSavingData);
      } */
      setMenuSavingData(newSavingData);
      handleChangeToSave({
        isSingle: false,
        menuData: newSavingData
      });
    }
    
  };


  return (
    <>
      {/* <button onClick={ onTest }>Test</button> */}
      <DraggableTabs
        style={{ justifySelf: 'flex-start' }}
        type={ panes.length <= 0 ? 'editable-card' : panes[0].title !== '功能選單' ? 'editable-card' : 'editable-card' } 
        onChange={ onChange }
        activeKey={ activeKey }
        onEdit={ onEdit }
      >
        {
          panes.map((pane) => (
            <TabPane
              tab={
                <span>
                  <MenuOutlined style={{ color: '#a9b6cb' }} />
                  <Tooltip placement="bottom" title={ pane.remark }>
                    { pane.title }
                  </Tooltip>
                </span>
              }
              key={ pane.key }
              closable={ pane.closable }
            >
              <PlatformManageSection
                existItems={ pane.items }
                tabName={ pane.title }
                isOnlyAddFunction={ pane.isOnlyAddFunction }
                mainMenuId={ pane.mainMenuId }
                functionOptions={ functionOptions }
                menuOptions={ menuOptions }
                handleChange={ handleChange }
              />
            </TabPane>
          ))
        }
      </DraggableTabs>
      <PlatformManageDrawerForm
        isSingle={ panes.findIndex(e => e.title === '功能選單') >= 0 }
        isOpen={ isManageDrawerFromOpen }
        menuOptions={ menuOptions }
        handleClose={ handleDrawerFormClose }
        handleSave={ add }
      />
    </>
  );
}

export default PlatformManageTabs;
