import React from 'react';
import { Drawer, Form, Space } from 'antd';

import CustomerButton from '../../layouts/CustomerButton';
import CustomerInput from '../../layouts/CustomerInput';
import CustomerSwitch from '../../layouts/CustomerSwitch';
import CustomerSearchInput from '../../layouts/CustomerSearchInput';

import '../../../styles/components/DrawerForm.css';

import { inputValidator } from '../../../application/validator';

const AdSlotsDrawerForm = ({
  editData,
  isOpen,
  isEdit,
  isLoading,
  handleClose,
  handleSave,
  handleUpdate,
  handleVerifyCode,
  enterBtnText,
  form,
}) => {
  const {
    requiredValidator,
    lowerCaseValidator,
    minLenValidator,
    symbolValidator,
  } = inputValidator();

  const handleClickToSave = value => {
    delete value['mobileSize'];
    delete value['desktopSize'];
    const updateResult = {
      ...value,
      slotId: editData.id,
    };
    isEdit ? handleUpdate(updateResult) : handleSave(value);
  };

  return (
    <>
      <Drawer
        title={isEdit ? '編輯版位' : '新增版位'}
        width={700}
        onClose={handleClose}
        visible={isOpen}
        destroyOnClose
        footer={
          <Space>
            <CustomerButton onClick={handleClose}>取消</CustomerButton>
            <CustomerButton
              htmlType='submit'
              color='blue'
              form='product-drawer'>
              確定
            </CustomerButton>
          </Space>
        }>
        <Form
          form={form}
          id='product-drawer'
          className='form-group'
          layout='horizontal'
          wrapperCol={{ span: 20 }}
          labelCol={{ span: 6 }}
          onFinish={handleClickToSave}
          initialValues={{
            slotCode: '',
            slotName: '',
            desktopWidth: 0,
            desktopHeight: 0,
            mobileWidth: 0,
            mobileHeight: 0,
            isActive: false,
          }}>
          <Form.Item
            name='slotCode'
            label='版位代碼'
            rules={[
              requiredValidator(),
              lowerCaseValidator(),
              minLenValidator(2),
            ]}>
            {isEdit ? (
              <CustomerInput disabled={true} />
            ) : (
              <CustomerSearchInput
                placeholder='請輸入版位英文名稱'
                buttonText={enterBtnText}
                onSearch={handleVerifyCode}
                isLoading={isLoading}
              />
            )}
          </Form.Item>

          <Form.Item
            name='slotName'
            label='版位名稱'
            rules={[requiredValidator(), symbolValidator()]}>
            <CustomerInput placeholder='請輸入分類中文名稱' />
          </Form.Item>

          <Form.Item
            name='desktopSize'
            label='電腦版建議尺寸'
            required
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  const width = getFieldValue('desktopWidth');
                  const height = getFieldValue('desktopHeight');
                  if (!width && !height) {
                    // eslint-disable-next-line
                    return Promise.reject('請輸入寬度與高度。');
                  } else {
                    // eslint-disable-next-line
                    return Promise.resolve();
                  }
                },
              }),
            ]}>
            <Form.Item
              name='desktopWidth'
              label='寬度'
              style={{ marginRight: 12 }}>
              <CustomerInput placeholder='單位為像素' type='number' />
            </Form.Item>
            <Form.Item name='desktopHeight' label='高度' type='number'>
              <CustomerInput placeholder='單位為像素' />
            </Form.Item>
          </Form.Item>

          <Form.Item
            name='mobileSize'
            label='手機版建議尺寸'
            required
            rules={[
              ({ getFieldValue }) => ({
                validator() {
                  const width = getFieldValue('mobileWidth');
                  const height = getFieldValue('mobileHeight');
                  if (!width && !height) {
                    // eslint-disable-next-line
                    return Promise.reject('請輸入寬度與高度。');
                  } else {
                    // eslint-disable-next-line
                    return Promise.resolve();
                  }
                },
              }),
            ]}>
            <Form.Item
              name='mobileWidth'
              label='寬度'
              style={{ marginRight: 12 }}>
              <CustomerInput placeholder='單位為像素' type='number' />
            </Form.Item>
            <Form.Item name='mobileHeight' label='高度'>
              <CustomerInput placeholder='單位為像素' type='number' />
            </Form.Item>
          </Form.Item>

          <Form.Item label='狀態'>
            <Form.Item name='isActive' valuePropName='checked'>
              <CustomerSwitch />
            </Form.Item>
            <Form.Item shouldUpdate>
              {() => {
                const isActive = form.getFieldValue('isActive');
                return <span>{isActive ? '顯示' : '隱藏'}</span>;
              }}
            </Form.Item>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AdSlotsDrawerForm;
