import { useState, useEffect } from 'react';
import {
  requestGetProductCategories,
  requestPostProductCategories,
  requestPutProductCategories,
  requestDeleteProductCategories,
  requestVerifyProductCategoryCode,
} from '../../services/product/productCategories';
import {
  requestPostFinancialProduct,
  requestPutFinancialProduct,
  requestDeleteFinancialProduct,
  requestGetFinancialProductDetail,
  requestFinancialProductCategory,
  requestGetFinancialProductVerify,
  requestGetCommonQuestionByKeyword
} from '../../services/product/productService';

export function useRequestProduct() {
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    getProductCategories();
  }, []);

  const getProductCategories = async() => {
    const listData = await requestGetProductCategories();
    if (listData.status) {
      setProductList(listData.data.data);
    }
  };

  const postProductCategories = async params => {
    const res = await requestPostProductCategories(params);
    if (res.status) {
      return true;
    }
  };

  const putProductCategories = async params => {
    const res = await requestPutProductCategories(params);
    if (res.status) {
      return true;
    }
  };

  const deleteProductCategories = async id => {
    const res = await requestDeleteProductCategories(id);
    if (res.status) {
      return true;
    }
  };

  const getVerifyProductCategoryCode = async params => {
    const res = await requestVerifyProductCategoryCode(params);
    console.log(res);
    if (res.status) {
      return res.data.data.success;
    }
  };

  return {
    productList,
    getProductCategories,
    postProductCategories,
    putProductCategories,
    deleteProductCategories,
    getVerifyProductCategoryCode,
  };
}

export default function useRequestPostFinancialProduct() {
  const postFinancialProduct = async params => {
    const res = await requestPostFinancialProduct(params);
    if (res.status) {
      return true;
    }
  };

  const putFinancialProduct = async params => {
    const res = await requestPutFinancialProduct(params);
    if (res.status) {
      return true;
    }
  };

  const deleteFinancialProduct = async params => {
    const res = await requestDeleteFinancialProduct(params);
    if (res.status) {
      return true;
    }
  };

  const getFinancialProductDetail = async id => {
    const res = await requestGetFinancialProductDetail(id);
    if (res.status) {
      const data = res.data.data;
      const allProduct = [];
      data.forEach(i => {
        allProduct.push(...i.products);
      });
      const productIndex = allProduct.findIndex(i=> i.id === id);
      const targetProductDetail = allProduct[productIndex];
      return targetProductDetail;
      // return res.data.data;
    }
  };

  const getFinancialProductCodeVerify = async id => {
    const res = await requestGetFinancialProductVerify(id);
    if (res.status) {
      return res;
    }
  };

  const getFinancialProductCategory = async id => {
    const res = await requestFinancialProductCategory(id);
    if (res.status) {
      const resData = res.data.data;
      const cateIndex = resData.findIndex(cate => cate.id === id);
      const products = resData[cateIndex].products;
      return products;
      // return res.data.data;
    }
  };

  const getFinancialProductCategoryDetail = async id => {
    const res = await requestFinancialProductCategory(id);
    if (res.status) {
      const resData = res.data.data;
      const cateIndex = resData.findIndex(cate => cate.id === id);
      const cateProductData = resData[cateIndex];
      console.log('cateProductData', cateProductData);
      return cateProductData;
      // return listData.data.data;
    }
  };


  const getCommonQuestionByKeyword = async keywords => {
    const listData = await requestGetCommonQuestionByKeyword(keywords);
    if (listData.status) {
      return listData.data.data.result.dataList;
    }
  };

  return {
    postFinancialProduct,
    putFinancialProduct,
    deleteFinancialProduct,
    getFinancialProductDetail,
    getFinancialProductCategory,
    getFinancialProductCategoryDetail,
    getFinancialProductCodeVerify,
    getCommonQuestionByKeyword
  };
}
