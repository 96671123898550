import React, { useState, useEffect } from 'react';
import cookies from 'js-cookie';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Layout, Form, Space, Upload, Select } from 'antd';
import { CheckOutlined, PlusOutlined,  SyncOutlined } from '@ant-design/icons';

import CustomerButton from '../../layouts/CustomerButton';
import CustomerInput from '../../layouts/CustomerInput';
import CustomerSwitch from '../../layouts/CustomerSwitch';
import ProductPreviewModal from '../../components/product/ProductPreviewModal';
import MultipleEditor from '../../components/product/MultipleEditor';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import { useRequestTradePlatform } from '../../../application/tradePlatform/useRequestTradePlatform';
import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import CustomerSearchInput from '../../layouts/CustomerSearchInput';
import { inputValidator } from '../../../application/validator';

function CreateTradePlatform() {

  const [form] = Form.useForm();
  const {requiredValidator, symbolValidator} = inputValidator();

  const [isEdit, setIsEdit] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [enterBtnText, setEnterBtnText] = useState('網址驗證');
  const [image, setImage] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [platformKeyword, setPlatformKeyword] = useState([]);
  const [commonQKeywords, setCommonQKeywords] = useState([]);
  // const [questionKeyword, setQuestionKeyword] = useState('');
  const [questionList, setQuestionList] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('qa');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddTabOpen, setIsAddTabOpen] = useState(false);
  const [newTabIndex, setNewTabIndex] = useState(0);
  const [isEditTab, setIsEditTab] = useState(false);
  const [editTabKey, setEditTabKey] = useState('');
  const [editTabName, setEditTabName] = useState('');
  const [deleteTabKey, setDeleteTabKey] = useState('');
  const [tabPaneList, setTabPaneList] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);

  const { Content } = Layout;
  const dispatch = useDispatch();
  const { categoryId, name, tradePlatformId } = useParams();
  const {
    getTradePlatformDetail,
    getTradingAppVerify,
    putTradePlatform,
    postTradePlatform,
    getCommonQuestionByKeyword,
    getFinancialProductList,
  } = useRequestTradePlatform();

  useEffect(() => {
    const path = window.location.pathname;
    if(path.includes('edit')){
      setIsEdit(true);
      getExistData();
      dispatch(
        setBreadcrumb([
          {
            path: '/trade-platform',
            pageName: '交易平台管理',
          },
          {
            path: '/trade-platform',
            pageName: '分類管理',
          },
          {
            path: '',
            pageName: '編輯平台',
          },
        ])
      );
    } else {
      fetchProductList();
      setIsEdit(false);
      dispatch(
        setBreadcrumb([
          {
            path: '/trade-platform',
            pageName: '交易平台管理',
          },
          {
            path: '/trade-platform',
            pageName: '分類管理',
          },
          {
            path: '',
            pageName: '新增平台',
          },
        ])
      );
    }
  // eslint-disable-next-line
  }, [dispatch]);

  const handlePreviewModalSwitch = () => {
    setPreviewModalOpen(isPreviewModalOpen ? false : true);
  };

  const handleValidateProductName = async(value) => {
      const errMsg = form.getFieldError('product-url');
      if (errMsg.length !== 0) {
        return sendErrorMessage('請輸入正確產品名稱。');
      }
      if (value && errMsg.length === 0) {
        setIsSearchLoading(true);
        setEnterBtnText(<SyncOutlined spin/>);
        const verifyRes = await getTradingAppVerify(value);
        if(!verifyRes) {
          form.setFields([{name: 'product-url', errors:['請輸入其他產品名稱。']}]);
          setEnterBtnText('驗證網址');
        } else {
          form.setFields([{name: 'product-url', errors: false}]);
          setEnterBtnText(<CheckOutlined />);
        }
        setTimeout(() => {
          setEnterBtnText('驗證網址');
        }, 5000);
        setIsSearchLoading(false);
      }
  };

  const onChangeAvailableProduct = value => {
    setAvailableProducts(value);
    // const arr = [];
    // options.forEach(i => {
    //   i.options.filter(itm => {
    //     return arr.push(itm);
    //   });
    // });
    // const v = arr.filter(ele => value.includes(ele.id));
    // const sArr = [];
    // v.map(s => sArr.push(s.label));
    // fetchQuestion(String(sArr));
  };
  
  const handlePlatformKeywordChange = value => {
    setPlatformKeyword(value);
  };

  const handleCommonQKeywordChange = value => {
    setCommonQKeywords(value);
    if (value.length === 0) {
      setQuestionList([]);
    } else {
      fetchQuestion(value.toString());
    }
  };

  const fetchQuestion = async(keywords) => {
    const res = await getCommonQuestionByKeyword(keywords);
    if(res) {
      const newArray = [];
      res.forEach(ele => {
        newArray.push({
          title: ele.title,
          categoryName: ele.category.categoryName,
          category2nd: ele.category2nd.categoryName,
          category3rd: ele.category3rd.categoryName
        });
      });
      setQuestionList(newArray);
    }
  };

  console.log(attachmentList);

  const fetchProductList = async() => {
    const res = await getFinancialProductList();
    console.log(res);
    if(res) {
      const newArray = [];
      res.forEach(i => {
        const optionArray = [];
        i.products.forEach(j =>{
            optionArray.push({label: j.productName, value: j.id.toString(), id: j.id.toString() });
          }
        );
        newArray.push({ label: i.categoryName, options: optionArray });
      });
      // console.log(newArray);
      setOptions(newArray);
    }
  };

  const handleAddTabSwitch = () => {
    setIsEditTab(false);
    setIsAddTabOpen(isAddTabOpen ? false : true);
  };

  const handleEditTabSwitch = (tabKey, tabName) => {
    setIsEditTab(true);
    setEditTabKey(tabKey);
    setEditTabName(tabName);
    setIsAddTabOpen(isAddTabOpen ? false : true);
  };

  const handleTabEdit = (newArray) => {
    setTabPaneList(newArray);
    handleEditTabSwitch();
  };

  const handleTabChange = activeKey => {
    setActiveTabKey(activeKey);
  };

  const handleDeleteModalSwitch = (deleteTabKey) => {
    setIsDeleteModalOpen(isDeleteModalOpen ? false : true);
    setDeleteTabKey(deleteTabKey);
  };

  const handleUploadDrawerSwitch = () => {
    setIsUploadDrawerOpen(isUploadDrawerOpen ? false : true);
  };

  const onAddTab = tabName => {
    setIsEditTab(false);
    setNewTabIndex(newTabIndex + 1);
    const activeKey = `newTab${ newTabIndex }`;
    const newTab = ({ title: tabName, content: [], key: activeKey });
    setTabPaneList([...tabPaneList, newTab]);
    setActiveTabKey(activeKey);
    handleAddTabSwitch();
  };
  
  const onRemoveTab = (newPanes, newActiveKey) => {
    setTabPaneList(newPanes);
    setActiveTabKey(newActiveKey);
    setIsDeleteModalOpen(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    setTabPaneList((prev) => {
      const temp = prev;
      const currentTabIndex = temp.findIndex(ele => ele.key.toString() === activeTabKey);
      const tabEditorArray = temp[currentTabIndex].content;
      const destinationItem = tabEditorArray[result.destination.index];
      tabEditorArray[result.destination.index] = tabEditorArray[result.source.index];
      tabEditorArray[result.source.index] = destinationItem;

      return temp;
    });
  };

  const handleAppendNewEditorIntoList = (newArray) => {
    setTabPaneList(newArray);
  };

  const handleAppendNewToggleList = (newArray) => {
    setTabPaneList(newArray);
  };

  const onEditEditor = (newArray) => {
    setTabPaneList(newArray);
  };

  const removeEditItem = (newArray) => {
    setTabPaneList(newArray);
  };

  const onEditToggle = (newArray) => {
    setTabPaneList(newArray);
  };

  const handleFileUpload = fileData => {
    setAttachmentList(old => [
      ...old,
      { displayName: fileData.displayName, filename: fileData.filename },
    ]);
  };

  const handleSave = async value => {
    const tabList = [];
    const tabArray = [...tabPaneList];
    tabArray.forEach(ele => {
      tabList.push({
        tabName: ele.title,
        content: JSON.stringify(ele.content),
      });
    });

    const attachments = [];
    if (attachmentList.length) {
      const fileArray = [...attachmentList];
      fileArray.forEach(ele => {
        attachments.push({ displayName: ele.name, filename: ele.name });
      });
    }

    let params = {
      ...value,
      appCode: value.appCode,
      categoryId: Number(categoryId),
      attachments: attachmentList,
      tabs: tabList,
      imagePath: image.length === 0 ? '' : image[0].name,
    };
    if(value.keywords && value.keywords.length > 0) {
      params = {
        ...params,
        keywords: String(value.keywords)
      };
    } else {
      params = {
        ...params,
        keywords: 'undefined'
      };
    }
    if(value.commonQuestionKeywords && value.commonQuestionKeywords.length > 0) {
      params = {
        ...params,
        commonQuestionKeywords: String(value.commonQuestionKeywords)
      };
    } else {
      params = {
        ...params,
        commonQuestionKeywords: 'undefined'
      };
    }

    delete params['product-url'];
    delete params['categoryName'];

    let result;
    if (isEdit) {
      params = { ...params, appId: tradePlatformId };
      result = await putTradePlatform(params);
      if (!result) {
        sendErrorMessage('編輯平台失敗');
        return;
      }
      sendSuccessMessage('編輯平台成功');
      window.location = `/cms/trade-platform/${ categoryId }/${ name }`;
    } else {
      result = await postTradePlatform(params);
      if (!result) {
        sendErrorMessage('平台儲存失敗');
        return;
      }
      sendSuccessMessage('平台已儲存');
      window.location = `/cms/trade-platform/${ categoryId }/${ name }`;
    }
  };

  const handleDeleteFile = async fileName => {
    const attachmentListLength = attachmentList.length;
    for (let i = 0; i < attachmentList.length; i++) {
      if (attachmentList[i].filename === fileName) {
        attachmentList.splice(i, 1);
        break;
      }
    }

    const attachments = [];
    if (attachmentList.length > 0) {
      const fileArray = [...attachmentList];
      fileArray.forEach(ele => {
        attachments.push({ displayName: ele.displayName, filename: ele.filename });
      });
    }

    if(isEdit) {
      const params = { appId: tradePlatformId, attachments: attachments };
      const result = await putTradePlatform(params);
      if (!result) {
        sendErrorMessage('刪除檔案失敗');
        return;
      } else {
        sendSuccessMessage('刪除檔案成功');
        if (attachmentListLength <= 1) {
          setAttachmentList([]);
        } else {
          setAttachmentList(attachmentList);
        }
        getExistData();
        return;
      }
    } else {
      setAttachmentList(attachmentList);
      sendSuccessMessage('刪除檔案成功');
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>上傳圖片</div>
    </div>
  );

  const uploadProps = {
    action: `${ process.env.REACT_APP_FILES_UPLOAD_URL }`,
    headers: {
      token: cookies.get('elktree-demo-key')
    }
  };

  const imageFileList = img => {
    return [{
      uid: img,
      name: img,
      status: 'done',
      url: `${ process.env.REACT_APP_IMAGE_URL }/${ img }`,
    }];
  };

  const getExistData = async() => {
    const existData = await getTradePlatformDetail(tradePlatformId);
    console.log(existData);
    if(existData) {
      form.setFieldsValue({
        appCode: existData.appCode,
        appName: existData.appName,
        description: existData.description,
        imagePath: imageFileList(existData.imagePath),
        isActive: existData.isActive,
        button1Name: existData.button1Name,
        button1Url: existData.button1Url,
        button2Name: existData.button2Name || '',
        button2Url: existData.button2Url || '',
        products: existData.products.map(i => { return i.productId.toString(); }),
      });
      if (existData.keywords) {
        if(existData.keywords === 'undefined' || existData.keywords.length === 0){
          form.setFieldsValue({
            keywords: []
          });
        } else {
          form.setFieldsValue({
            keywords: existData.keywords.split(',')
          });
        }
      }
      console.log('existData.commonQuestionKeywords', existData.commonQuestionKeywords);
      if (existData.commonQuestionKeywords) {
        if(existData.commonQuestionKeywords === 'undefined' || existData.commonQuestionKeywords.length === 0){
          form.setFieldsValue({
            commonQuestionKeywords: []
          });
        } else {
          form.setFieldsValue({
            commonQuestionKeywords: existData.commonQuestionKeywords.split(',')
          });
        }
      }

      if (existData.tabs.length) {
        // 還原成tabPaneList的格式
        const newArray = [];
        existData.tabs.forEach((ele, index) => {
          newArray.push({title: ele.tabName, content: JSON.parse(ele.content), key: index });
        });
        setTabPaneList(newArray);
      }

      if (existData?.attachments?.length > 0) {
        const data = [];
        existData.attachments.forEach((e, idx) => {
          data.push({
            uid: idx.toString(),
            displayName: e.displayName,
            filename: e.filename,
            status: 'done',
            url: `${ process.env.REACT_APP_FILES_URL }/${ e.filename }`
          });
        });
        setAttachmentList(data);
      }

      fetchQuestion(existData.commonQuestionKeywords);
      fetchProductList();

      setCommonQKeywords(existData.commonQuestionKeywords.split(','));
      setImage(imageFileList(existData.imagePath));
    }
  };

  // getFileList by form
  const normFile = (e) => {
    console.log('Upload event:', e);
  
    if (e.file.status === 'done') {
      setImage(imageFileList(e.file.response.result.filename));
    } else {
      setImage([]);
    }

    if (Array.isArray(e)) {
      return e;
    }
  
    return e && e.fileList;
  };

  // Prevent Enter key down after key in finish
  const preventKeyDown = (e) => {
    if((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
    }
  };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title add-question-title'>
            <h1>{isEdit ? '編輯平台' : '新增平台'}</h1>
            <Space>
              <CustomerButton onClick={handlePreviewModalSwitch}>
                預覽
              </CustomerButton>
              <CustomerButton
                color='blue'
                htmlType='submit'
                form='trade-platform-form'>
                儲存
              </CustomerButton>
            </Space>
          </div>
          <Form
            id='trade-platform-form'
            form={form}
            onFinish={handleSave}
            layout='horizontal'
            onKeyDown={preventKeyDown}
            wrapperCol={{ span: 14 }}
            labelCol={{ span: 3 }}
            initialValues={{
              categoryName: name,
              isActive: false,
              appCode: '',
            }}>
            <Form.Item label='平台分類' name='categoryName'>
              <CustomerInput disabled />
            </Form.Item>
            <Form.Item label='正式網址' name='product-url' required>
              <CustomerInput
                value=''
                disabled
              />
              <Form.Item
                name='appCode'
                dependencies={['product-url']}
                rules={[
                  ({ setFields }) => ({
                    validator(_, value) {
                      const regex = /^[A-z0-9_-]*$/;
                      if (value.length === 0) {
                        return setFields([
                          { name: 'product-url', errors: ['名稱不可為空白。'] },
                        ]);
                      } else if (!regex.test(value)) {
                        return setFields([
                          {
                            name: 'product-url',
                            errors: [
                              '名稱限半形英文、數字 、減號 (-)、下底線 (_)。',
                            ],
                          },
                        ]);
                      } else if (value.length < 2) {
                        return setFields([
                          {
                            name: 'product-url',
                            errors: ['長度最少2個字元。'],
                          },
                        ]);
                      } else {
                        setFields([{ name: 'product-url', errors: false }]);
                        // eslint-disable-next-line
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}>
                <CustomerSearchInput
                  maxLength={63}
                  style={{ minWidth:'280px' }}
                  placeholder={isEdit ? '' : '請輸入英文商品名稱'}
                  onSearch={handleValidateProductName}
                  loading={isSearchLoading}
                  enterButton={enterBtnText}
                />
              </Form.Item>
            </Form.Item>

            <Form.Item
              label='平台名稱'
              name='appName'
              rules={[requiredValidator(), symbolValidator()]}>
              <CustomerInput placeholder='請輸入平台名稱' maxLength={13} />
            </Form.Item>

            <Form.Item
              label='簡短描述'
              name='description'
              rules={[requiredValidator()]}>
              <CustomerInput
                placeholder='請以50字內簡短描述'
                textArea
                rows={3}
                maxLength={50}
              />
            </Form.Item>

            <Form.Item
              name='imagePath'
              label='上傳平台圖片'
              className='image-form-item'
              getValueFromEvent={normFile}
              valuePropName='fileList'
              rules={[requiredValidator('請上傳圖片。')]}>
              <Upload
                {...uploadProps}
                listType='picture-card'
                // fileList={image}
                maxCount={1}>
                {image.length > 0 ? null : uploadButton}
              </Upload>
            </Form.Item>

            <Form.Item label='狀態'>
              <Form.Item name='isActive' valuePropName='checked'>
                <CustomerSwitch />
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => {
                  const isActive = form.getFieldValue('isActive');
                  return isActive ? <span>顯示</span> : <span>隱藏</span>;
                }}
              </Form.Item>
            </Form.Item>

            <Form.Item
              label='按鈕1名稱'
              name='button1Name'
              rules={[requiredValidator()]}>
              <CustomerInput placeholder='請輸入按鈕名稱' maxLength={12} />
            </Form.Item>

            <Form.Item
              label='按鈕1連結'
              required
              name='button1Url'
              rules={[
                requiredValidator(),
                {
                  type: 'url',
                  message: '請輸入正確網址。',
                },
              ]}>
              <CustomerInput placeholder='請輸入連結網址' />
            </Form.Item>

            <Form.Item
              label='按鈕2名稱'
              name='button2Name'
              dependencies={['button2Url']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const btn2Url = getFieldValue('button2Url');
                    if (btn2Url && !value) {
                      // eslint-disable-next-line
                      return Promise.reject('名稱不可為空。');
                    }
                    // eslint-disable-next-line
                    return Promise.resolve();
                  },
                }),
              ]}>
              <CustomerInput placeholder='請輸入按鈕名稱' />
            </Form.Item>

            <Form.Item
              label='按鈕2連結'
              name='button2Url'
              dependencies={['button2Name']}
              rules={[
                {
                  type: 'url',
                  message: '請輸入正確網址。',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const btn2name = getFieldValue('button2Name');
                    if (btn2name && !value) {
                      // eslint-disable-next-line
                      return Promise.reject('網址不可為空。');
                    }
                    // eslint-disable-next-line
                    return Promise.resolve();
                  },
                }),
              ]}>
              <CustomerInput placeholder='請輸入連結網址' />
            </Form.Item>

            <Form.Item
              label='可交易商品'
              name='products'
              rules={[
                {
                  required: true,
                  message: '請選擇可交易商品。',
                },
              ]}>
              <Select
                placeholder='請選擇可交易商品(多選)'
                options={options}
                value={availableProducts}
                mode='multiple'
                onChange={onChangeAvailableProduct}></Select>
            </Form.Item>

            <Form.Item label='平台關鍵字' name='keywords'>
              <Select
                placeholder='輸入選項後鍵入Enter，新增一個新的關鍵字'
                value={platformKeyword}
                onChange={handlePlatformKeywordChange}
                mode='tags'>
                {platformKeyword}
              </Select>
            </Form.Item>

            <Form.Item label='常見問題關鍵字' name='commonQuestionKeywords'>
              <Select
                placeholder='輸入選項後鍵入Enter，新增一個新的關鍵字'
                value={platformKeyword}
                onChange={handleCommonQKeywordChange}
                mode='tags'>
                {commonQKeywords}
              </Select>
            </Form.Item>

            <Form.Item label='設定分頁'>
              <CustomerButton
                onClick={handleAddTabSwitch}
                icon={<PlusOutlined />}>
                新增分頁
              </CustomerButton>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
              <MultipleEditor
                tabPaneList={tabPaneList}
                activeTabKey={activeTabKey}
                handleTabChange={handleTabChange}
                handleAppendNewEditorIntoList={handleAppendNewEditorIntoList}
                handleAppendNewToggleList={handleAppendNewToggleList}
                handleRemoveEditItem={removeEditItem}
                handleOnEditEditor={onEditEditor}
                handleOnEditToggle={onEditToggle}
                questionList={questionList}
                attachmentList={attachmentList}
                handleFileUpload={handleFileUpload}
                isAddTabOpen={isAddTabOpen}
                isEditTab={isEditTab}
                editTabKey={editTabKey}
                editTabName={editTabName}
                handleAddTabSwitch={handleAddTabSwitch}
                isDeleteModalOpen={isDeleteModalOpen}
                onAddTab={onAddTab}
                handleEditTabSwitch={handleEditTabSwitch}
                handleDeleteModalSwitch={handleDeleteModalSwitch}
                handleDragEnd={handleDragEnd}
                handleTabEdit={handleTabEdit}
                deleteTabKey={deleteTabKey}
                onRemoveTab={onRemoveTab}
                isUploadDrawerOpen={isUploadDrawerOpen}
                handleUploadDrawerSwitch={handleUploadDrawerSwitch}
                handleDeleteFile={handleDeleteFile}
                isTradingPlatform={true}
              />
            </Form.Item>
          </Form>
        </div>
      </Content>
      <ProductPreviewModal
        isOpen={isPreviewModalOpen}
        handleClose={handlePreviewModalSwitch}
        fileList={attachmentList}
        tabPaneList={tabPaneList}
        questionList={questionList}
      />
    </Layout>
  );
}

export default CreateTradePlatform;
