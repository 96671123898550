import { getRequest } from '../api';
import {
  errorHandle
} from '../api/errorHandle.js';

export async function requestMenuSettings() {
  const url = '/JSON/menuList.json';
  const result = {
    status: false,
    data: null
  };
  try {
    const res = await getRequest(url);
    result.data = res.data;
    result.status = true;
  } catch (err) {
    result.data = errorHandle(err);
  }
  return result;
}
