import React, { useState, useEffect } from 'react';
import { Layout, Form } from 'antd';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import { SyncOutlined } from '@ant-design/icons';
import '../../../styles/pages/SitePage.css';

import UserGroupManageDataTable from '../../components/permission/usergroup/UserGroupManageDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import UserGroupManegeDrawerForm from '../../components/permission/usergroup/UserGroupManageDrawer';
// import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';
import { useSingleGroup } from '../../../application/permission/useUsersGroup.js';
import { useUserManage } from '../../../application/permission/useUserManage.js';

function UserGroupManager() {
  const dispatch = useDispatch();
  const { Content } = Layout;
  const { name, id } = useParams();

  const {
    groupUsersList,
    groupPermissions,
    groupName,
    // getGroupUsersList,
    // addUserIntoGroup,
    // deleteUserFromGroup,
  } = useSingleGroup(id);

  const { getUserData, usersList } = useUserManage();
  const [isOpen, setOpen] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isSearchSuccess, setIsSearchSuccess] = useState(false);
  const [searchUserData, setSearchUserData] = useState({
    id: 0,
    staffName: '',
    isActive: false,
    groups: [],
  });
  const [enterBtnText, setEnterBtnText] = useState('確認檢索');
  const [form] = Form.useForm();

  // const [searchUserName, setSearchUserName] = useState('');
  // const [searchUserEmpId, setSearchUserEmpId] = useState('');

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '權限管理',
        },
        {
          path: '/permission/user_group',
          pageName: '使用者群組管理',
        },
        {
          path: `/permission/user_group/${ id }/${ groupName }`,
          pageName: `${ groupName }`,
        },
      ])
    );
  }, [dispatch, id, groupName]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSearchUserData({
      id: 0,
      staffName: '',
      isActive: false,
      groups: [],
    });
    form.resetFields();
  };

  const handleSearchUser = async value => {
    if (value) {
      setIsSearchLoading(true);
      setEnterBtnText(<SyncOutlined spin />);
      const { name, empID } = await getUserData(value);
      if (!name) {
        setSearchUserData({
          id: 0,
          staffName: '',
          isActive: false,
          groups: [],
        });
        form.setFields([{ name: 'staffNo', errors: ['查無此員工編號。'] }]);
        form.setFieldsValue({ staffName: '' });
        setEnterBtnText('確認檢核');
      } else if (groupUsersList.filter(data => data.staffNo === empID).length > 0) {
        form.setFields([{ name: 'staffNo', errors: ['員工編號不可重複。'] }]);
        setEnterBtnText('確認檢核');
      } else {
        console.log(usersList.filter(e => e.staffNo === empID)[0]);
        setSearchUserData({
          id: usersList.filter(e => e.staffNo === empID)[0].id,
          staffName: name,
          isActive: usersList.filter(e => e.staffNo === empID)[0].isActive,
          groups: usersList.filter(e => e.staffNo === empID)[0].group
        });
        setIsSearchSuccess(true);
        setEnterBtnText('重新確認');
        form.setFieldsValue({ staffName: name });
      }
      setTimeout(() => {
        setEnterBtnText('確認檢核');
      }, 5000);
      setIsSearchLoading(false);
    }
  };

  // 加入使用者
  // const handleSave = async data => {
  //   if (!data.userId) {
  //     return;
  //   }
  //   const result = await addUserIntoGroup(Number(id), data.userId);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }

  //   handleDrawerClose();
  //   getGroupUsersList(id);
  //   sendSuccessMessage('更新成功');
  // };

  // 移除使用者
  // const handleDeleteClick = async userId => {
  //   const result = await deleteUserFromGroup(id, userId);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }
  //   getGroupUsersList(id);
  //   sendSuccessMessage('更新成功');
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>{name}</h1>
            <CustomerButton color='red' onClick={handleDrawerOpen}>
              加入使用者
            </CustomerButton>
          </div>
          <UserGroupManageDataTable
            tableData={groupUsersList}
            // handleDeleteClick={handleDeleteClick}
          />
        </div>
      </Content>
      <UserGroupManegeDrawerForm
        isOpen={isOpen}
        userGroupPermission={groupPermissions}
        groupName={groupName}
        userData={searchUserData}
        handleClose={handleDrawerClose}
        isSearchLoading={isSearchLoading}
        isSearchSuccess={isSearchSuccess}
        handleSearchUser={handleSearchUser}
        // handleSave={handleSave}
        enterBtnText={enterBtnText}
        form={form}
      />
    </Layout>
  );
}

export default UserGroupManager;
