import React, { useState, useEffect } from 'react';
import { Layout, Table } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import EmptyDisplay from '../../layouts/EmptyDisplay';
import '../../../styles/components/CreateProductQATable.css';
import noData from '../../../assets/images/common/data-null.svg';


function CreateProductQuestionTable({list}) {
  const { Content } = Layout;
  const [dataList, setDataList] = useState(list);

  useEffect(() => {
    setDataList(list);
  }, [list]);

  const columns = [
    {
      title: '標題',
      dataIndex: 'title',
      key: 'title',
      width: '55%',
      ellipsis: 'true',
    },
    {
      title: '第一層分類',
      dataIndex: 'categoryName',
      key: 'categoryName',
      width: '12%',
      ellipsis: 'true',
    },
    {
      title: '第二層分類',
      dataIndex: 'category2nd',
      key: 'category2nd',
      width: '12%',
      ellipsis: 'true',
    },
    {
      title: '第三層分類',
      dataIndex: 'category3rd',
      key: 'category3rd',
      width: '11%',
      ellipsis: 'true',
    }
  ];

  const locale = {
    emptyText: <EmptyDisplay />
  };

  return(
    <Layout>
      <Content>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>常見問題列表</h1>
            <p style={{ fontSize: '14px', color: '#3f5372'}}><InfoCircleFilled />資料來源：常見問題關鍵字</p>
          </div>
          { dataList ?
            <Table
                locale={locale}
                columns={columns}
                dataSource={dataList}
                rowKey='title'
                pagination={false}
            /> :
            <div className='no-result'>
              <img src={noData} alt="暫無資料" />
              暫無資料
            </div>
            }
        </div>
    </Content>
  </Layout>
  );
}

export default CreateProductQuestionTable;