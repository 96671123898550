export function useQuestionFilterHandle() {
  const handleCategoryFilterTitle = (originalCategoryArray) => {
    const newArray = [];
    originalCategoryArray?.forEach(ele => {
      const newObj = { id: ele.id, text: ele.categoryName, value: ele.categoryName };
      newArray.push(newObj);
    });
    return newArray;
  };

  return {
    handleCategoryFilterTitle
  };
}