import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../store/actions/breadcrumb';
import '../../../styles/pages/SitePage.css';

import DataTable from '../../components/permission/function/PermissionDataTable';
import CustomerButton from '../../layouts/CustomerButton';
import FunctionModal from '../../components/permission/function/FunctionModal';
// import { sendSuccessMessage, sendErrorMessage } from '../../layouts/Message';

import { usePermissionFunction } from '../../../application/permission/useFuctions.js';

/* import {
  sendSuccessMessage,
  sendErrorMessage
} from '../../../store/actions/message'; */

// import { MessageContextStore } from '../../../store/context/messageContext.js';

function FunctionPermissionGroup() {
  const dispatch = useDispatch();
  // const messageConfig = useSelector(state => state.message);

  const { Content } = Layout;

  // const { sendMessage } = useContext(MessageContextStore);

  const [isModalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editFunctionGroupName, setEditFunctionGroupName] = useState('');
  // const [editFunctionGroupId, setEditFunctionGroupId] = useState(null);
  // const [groupNameErrorMessage, setGroupNameErrorMessage] = useState('');

  const {
    permissionFunctionsList,
    // getFunctionsList,
    // createFunction,
    // updateFunction,
    // deleteFunction,
  } = usePermissionFunction();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          path: '',
          pageName: '權限管理',
        },
        {
          path: '/permission/function',
          pageName: '功能權限群組管理',
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isModalOpen) {
      setEditFunctionGroupName('');
      // setEditFunctionGroupId(null);
      setIsEdit(false);
    }
  }, [isModalOpen]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  //  新增
  // const handleModalChange = async newGroupName => {
  //   const result = await createFunction(newGroupName);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     setGroupNameErrorMessage('');
  //     return;
  //   }
  //   handleModalClose();
  //   getFunctionsList();
  //   sendSuccessMessage('更新成功');
  //   setGroupNameErrorMessage('');
  // };

  //  編輯
  // const handleUpdate = async newGroupName => {
  //   const result = await updateFunction({
  //     id: editFunctionGroupId,
  //     newFunctionGroupName: newGroupName,
  //   });
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }
  //   handleModalClose();
  //   getFunctionsList();
  //   sendSuccessMessage('更新成功');
  // };

  const handleEditClick = (id, newGroupName) => {
    setIsEdit(true);
    setEditFunctionGroupName(newGroupName);
    // setEditFunctionGroupId(id);
    handleModalOpen();
  };

  // 刪除
  // const handleDeleteClick = async id => {
  //   const result = await deleteFunction(id);
  //   if (!result) {
  //     sendErrorMessage('更新失敗');
  //     return;
  //   }
  //   getFunctionsList();
  //   sendSuccessMessage('更新成功');
  // };

  return (
    <Layout>
      <Content className='sitebar-content-section'>
        <div className='sitebar-content'>
          <div className='sitebar-content-title'>
            <h1>功能權限群組管理</h1>
            <CustomerButton color='red' onClick={handleModalOpen}>
              新增功能群組
            </CustomerButton>
          </div>
          <DataTable
            functionsData={permissionFunctionsList}
            handleEditClick={handleEditClick}
            // handleDeleteClick={handleDeleteClick}
          />
        </div>
      </Content>
      <FunctionModal
        isOpen={isModalOpen}
        isEdit={isEdit}
        handleClose={handleModalClose}
        // handleChange={handleModalChange}
        // handleUpdate={handleUpdate}
        existGroupName={editFunctionGroupName}
        // groupNameErrorMessage={groupNameErrorMessage}
      />
    </Layout>
  );
}

export default FunctionPermissionGroup;
